import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { initialize as initializeLaunchDarkly, LDClient, LDContext, LDFlagValue } from "launchdarkly-js-client-sdk";

import { consoleWarnInDevelopmentModeOnly } from "../utils/environmentUtils";

/** The default value used for feature flags that can't be evaluated for whatever reason. */
const DEFAULT_IS_ENABLED_VALUE = false;

/**
 * The singleton instance of the Launch Darkly client. This must be asynchonously initialized on
 * app startup.
 */
let launchDarklyClient: LDClient | undefined;

/**
 * Whether the Launch Darkly client has been fully initialized yet. Must be true before feature
 * flags can be accessed.
 */
let isInitialized = false;

let featureFlagOverridesSingleton: Record<string, boolean> | null = null;

/**
 * A service that we can use to conditionally enable and disable features for different users. This
 * service should be initialized once on app startup. Once the service is initialized, you can use
 * it as follows to decide if a given feature is enabled for the current user:
 *
 * @example
 * import { FeatureFlagService } from "relative/path/to/FeatureFlagService.ts";
 * // ...
 * if (FeatureFlagService.isEnabled(FeatureFlagKey.MY_FEATURE)) {
 *   return <MyFeature />;
 * } else {
 *   return <OldFeature />;
 * }
 */
// (clewis): This service is intended to provide more restrictive and therefore clearer rails around
// how to use Launch Darkly. It also provides better type safety than Launch Darkly's React SDK. We
// may want to simply export the SDK's singleton instance via a new getInstance() method in the
// future, but I'd like to avoid that if possible to keep Launch Darkly API details encapsulated in
// this file.
//
// See: https://docs.launchdarkly.com/sdk/concepts/getting-started#implement-sdks-in-a-singleton-pattern

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class FeatureFlagService {
  /**
   * Stores the provided feature-flag overrides for later access internally by the
   * `FeatureFlagService`. This is kind of a hack, but it works.
   */
  public static registerFeatureFlagOverrides(featureFlagOverrides: Record<string, boolean>) {
    if (featureFlagOverridesSingleton == null) {
      featureFlagOverridesSingleton = featureFlagOverrides;
    }
  }

  /**
   * Turns a FeatureFlagServiceUser into an {@link LDContext}.
   *
   * @returns A context for use with LaunchDarkly's client SDK.
   */
  public static featureFlagServiceUserToLaunchDarklyContext(tenantName: string): LDContext {
    // (dosipiuk): Always send user as anonymous
    return {
      kind: "user",
      anonymous: true,
      tenantName: tenantName ?? "",
    };
  }

  public static async identifyNewUser(tenantName: string) {
    if (launchDarklyClient == null) {
      return;
    }
    try {
      await launchDarklyClient.identify(FeatureFlagService.featureFlagServiceUserToLaunchDarklyContext(tenantName));
    } catch (error) {
      console.error("Launch Darkly failed to switch to current user: ", error);
    }
  }

  /**
   * Initializes the feature flag service so that conditional feature flags can be properly
   * resolved to a `true` or `false` value via `isEnabled`. This must be `await`'ed.
   */
  public static async initialize(clientId: string, tenantName: string) {
    try {
      const launchDarklyClientInternal = initializeLaunchDarkly(
        clientId,
        FeatureFlagService.featureFlagServiceUserToLaunchDarklyContext(tenantName),
        // (dosipiuk): disable analytics
        { diagnosticOptOut: true, sendEvents: false },
      );
      await launchDarklyClientInternal.waitForInitialization();
      isInitialized = true;

      // (clewis): Assign only after the client is fully initialized.
      launchDarklyClient = launchDarklyClientInternal;
    } catch (error) {
      console.error("Launch Darkly initialization failed:", error);
    }

    return isInitialized;
  }

  /**
   * Initializes the feature flag service with a default user, this is solely for accessing the
   * feature flag before login.
   */
  public static async initializeWithDefaultUserInNonAuthenticatedContext(clientId: string) {
    return FeatureFlagService.initialize(clientId, "");
  }

  /** Returns true if this service has finished initializing, or false otherwise. */
  public static isInitialized() {
    return isInitialized;
  }

  /**
   * Returns `true` if the specified feature flag is currently enabled for the current user, or
   * `false` otherwise. Also returns `false` if the `FeatureFlagService` was never `initialize`'d or
   * did not initialize successfully.
   *
   * @param featureFlagKey feature flag key defined in Launch Darkly
   * @param featureFlagValue feature flag value based on Launch Darkly's flag variation value.
   *                         optional for boolean values
   * @returns {boolean} whether or not the viewer has the feature flag enabled
   */
  public static isEnabled(featureFlagKey: FeatureFlagKey, featureFlagValue?: string | number): boolean {
    if (launchDarklyClient == null || !isInitialized) {
      consoleWarnInDevelopmentModeOnly(
        `Feature flag "${featureFlagKey}" was requested before FeatureFlagService was fully initialized.`,
        `Returning the default value of ${DEFAULT_IS_ENABLED_VALUE}.`,
        "This is a developer error.",
      );
      return DEFAULT_IS_ENABLED_VALUE;
    }

    // If in a development environment, then check for manual flag sets.
    if (featureFlagOverridesSingleton != null) {
      if (featureFlagKey in featureFlagOverridesSingleton) {
        return featureFlagOverridesSingleton[featureFlagKey];
      }
    }

    const result = launchDarklyClient.variation(featureFlagKey, DEFAULT_IS_ENABLED_VALUE);

    if (typeof result === "boolean") {
      return result;
    }

    if (featureFlagValue == null) {
      consoleWarnInDevelopmentModeOnly(
        `Feature flag ${featureFlagKey} was requested without a provided value to compare.`,
        "For example, `isEnabled(featureFlagKey, featureFlagValue)`.",
        `Defaulting to ${DEFAULT_IS_ENABLED_VALUE}.`,
      );
      return DEFAULT_IS_ENABLED_VALUE;
    }

    return result === featureFlagValue;
  }

  /** For UI Integration tests only. */
  public static getAll(): Record<FeatureFlagKey, LDFlagValue> {
    if (launchDarklyClient == null || !isInitialized) {
      // Throw an error since this should only be invoked in UI Integration Tests:
      throw new Error(
        "FeatureService.getAll was called before FeatureFlagService was fully initialized. " +
          "Returning an empty object. This is a developer error.",
      );
    }
    const allFlags = launchDarklyClient.allFlags() as Record<FeatureFlagKey, LDFlagValue>;
    // If in a development environment, then check for manual flag sets.
    if (featureFlagOverridesSingleton != null) {
      for (const override in featureFlagOverridesSingleton) {
        allFlags[override as FeatureFlagKey] = featureFlagOverridesSingleton[override];
      }
    }
    return allFlags;
  }
}
