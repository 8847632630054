// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type TenantFields = { __typename: 'Tenant', id: number, name: string, displayName?: string | null, permissionAllowNonAdminUserToCreateUsers: boolean, permissionAllowNonAdminUsersToEditAssignees: boolean, permissionAllowNonAdminUserToInviteNonDomain: boolean, globallyUniqueMetricsId: string, scimUserId?: number | null, uuidShort: string };

export const TenantFields = gql`
    fragment TenantFields on Tenant {
  __typename
  id
  name
  displayName
  permissionAllowNonAdminUserToCreateUsers
  permissionAllowNonAdminUsersToEditAssignees
  permissionAllowNonAdminUserToInviteNonDomain
  globallyUniqueMetricsId
  scimUserId
  uuidShort
}
    `;