import { EMPTY_STRING, sortIgnoreCaseWithExactMatchFirst } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { WorkflowTemplateForSelectFieldFields, WorkflowTemplateType } from "@regrello/graphql-api";
import { RegrelloIcon, RegrelloLinkV2 } from "@regrello/ui-core";
import {
  AddOptionToRegrello,
  CompanyTemplates,
  CreateNewBlueprint,
  DraftBlueprints,
  MyTemplates,
  PreviewBlueprint,
  PublishedBlueprints,
} from "@regrello/ui-strings";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useState } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloFormFieldSelectPropsV2, RegrelloFormFieldSelectV2 } from "./RegrelloFormFieldSelectV2";
import { FeatureFlagService } from "../../../services/FeatureFlagService";
import { getRouteToWorkflowTemplate } from "../../app/routes/routeCreatorUtils";
import { CreateWorkflowTemplateDialog } from "../../views/modals/workflowTemplateDialog/CreateWorkflowTemplateDialog";

export interface RegrelloFormFieldBlueprintSelectProps
  extends RegrelloFormFieldBaseProps<WorkflowTemplateForSelectFieldFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<WorkflowTemplateForSelectFieldFields>,
      | "autoFocus"
      | "getOptionDisabled"
      | "inputRef"
      | "onChange"
      | "onClearClick"
      | "onClose"
      | "placeholder"
      | "size"
      | "value"
    > {
  /** The blueprints to display in the select dropdown. */
  blueprints: WorkflowTemplateForSelectFieldFields[];

  /**
   * Callback invoked to render tooltip content for a given blueprint option. No tooltip will be
   * rendered if this function is undefined or returns undefined.
   */
  getOptionTooltip?: (option: WorkflowTemplateForSelectFieldFields) => string | undefined;

  /**
   * Whether the current user is allowed to create blueprints.
   * @default false
   */
  isCreatingBlueprintsAllowed?: boolean;

  /** Callback invoked when the create blueprint dialog is submitted. */
  onCreateBlueprintSubmit?: (newBlueprint: WorkflowTemplateForSelectFieldFields) => void;
}

/**
 * This component renders a select input field where the user can select from the provided
 * blueprints. It is a wrapper around RegrelloFormFieldSelect. It takes in data loaded from the
 * WorkflowTemplates query.
 */
export const RegrelloFormFieldBlueprintSelect = React.memo<RegrelloFormFieldBlueprintSelectProps>(
  function RegrelloFormFieldBlueprintSelectFn({
    blueprints,
    getOptionTooltip,
    isCreatingBlueprintsAllowed = false,
    onChange,
    onCreateBlueprintSubmit,
    value: selectedBlueprint,
    ...selectProps
  }: RegrelloFormFieldBlueprintSelectProps) {
    // State variables that enable the UX nicety of preloading the create blueprint dialog with the
    // currently inputted search query.
    const [defaultNameForCreateBlueprintDialog, setDefaultNameForCreateBlueprintDialog] = useState<string>("");
    const [isCreateBlueprintDialogOpen, setIsCreateBlueprintDialogOpen] = useState<boolean>(false);
    const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);

    // The sorted blueprint options to display in the select dropdown.
    const [loadedOptions, setLoadedOptions] = useState<WorkflowTemplateForSelectFieldFields[]>(sortOptions(blueprints));

    // In order to incorporate changes made to blueprints of type `MINE` now that we allow selecting
    // those, the query that populates the `blueprints` data is wrapped in `useAutoPollingQuery`.
    // Thus, when the blueprints update reload the options and update the currently selected
    // blueprint if it changed.
    useEffect(() => {
      const updatedSelectedBlueprint = blueprints.find((blueprint) => blueprint.id === selectedBlueprint?.id);
      setLoadedOptions(sortOptions(blueprints));
      if (updatedSelectedBlueprint != null && !isEqual(updatedSelectedBlueprint, selectedBlueprint)) {
        onChange(updatedSelectedBlueprint, "select-option");
      }
    }, [blueprints, onChange, selectedBlueprint]);

    const renderOption = useCallback((option: WorkflowTemplateForSelectFieldFields) => {
      return option.name;
    }, []);

    const handleClose = useCallback(() => {
      setIsCreateBlueprintDialogOpen(false);
    }, []);

    const handleCreateBlueprintSubmit = useCallback(
      (newBlueprint: WorkflowTemplateForSelectFieldFields) => {
        // Immediately add the new blueprint option to the loaded options instead of waiting for the
        // next autopoll.
        setLoadedOptions(sortOptions([...loadedOptions, newBlueprint]));

        // Set the new blueprint option as the currently selected option.
        onChange(newBlueprint, "create-option");
        onCreateBlueprintSubmit?.(newBlueprint);
      },
      [loadedOptions, onChange, onCreateBlueprintSubmit],
    );

    return (
      <div className="flex items-start gap-2 w-full justify-between">
        <RegrelloFormFieldSelectV2
          className="w-full"
          extraEndOptions={[
            isCreatingBlueprintsAllowed ? (
              <RegrelloSelectV2AddOption
                key="option-add"
                allowCreateOptions={true}
                iconName="add"
                message={(inputValue) =>
                  inputValue !== EMPTY_STRING
                    ? AddOptionToRegrello({ name: <strong>{inputValue}</strong> })
                    : CreateNewBlueprint
                }
                onSelect={(inputValue) => {
                  setIsCreateBlueprintDialogOpen(true);
                  setDefaultNameForCreateBlueprintDialog(inputValue);
                }}
              />
            ) : null,
          ]}
          getOptionLabel={getOptionLabel}
          getOptionTooltip={getOptionTooltip}
          getOptionValue={getOptionValue}
          groupBy={isPermissionsV2Enabled ? groupByV2 : groupBy}
          onChange={onChange}
          options={loadedOptions}
          renderOption={renderOption}
          value={selectedBlueprint}
          {...selectProps}
        />
        {selectedBlueprint != null && (
          <RegrelloLinkV2
            // Enable pointer events so that the blueprint link is still active within preview mode
            // since this link is an extension of preview capabilities.
            className="flex items-center h-9 min-w-35 pointer-events-auto truncate"
            rel="noopener noreferrer"
            target="_blank"
            to={getRouteToWorkflowTemplate(selectedBlueprint.id)}
          >
            <span className="font-semibold">{PreviewBlueprint} </span>
            <RegrelloIcon iconName="launch" size="x-small" />
          </RegrelloLinkV2>
        )}
        <CreateWorkflowTemplateDialog
          defaultName={defaultNameForCreateBlueprintDialog}
          isOpen={isCreateBlueprintDialogOpen}
          onClose={handleClose}
          onSubmit={handleCreateBlueprintSubmit}
        />
      </div>
    );
  },
);

function getOptionLabel(option: WorkflowTemplateForSelectFieldFields): string {
  return option.name;
}

function getOptionValue(option: WorkflowTemplateForSelectFieldFields): string {
  return option.name + String(option.id);
}

function groupBy(option: WorkflowTemplateForSelectFieldFields): string {
  return option.type === WorkflowTemplateType.MINE ? MyTemplates : CompanyTemplates;
}
function groupByV2(option: WorkflowTemplateForSelectFieldFields): string {
  return option.type === WorkflowTemplateType.MINE ? DraftBlueprints : PublishedBlueprints;
}

function sortOptions(options: WorkflowTemplateForSelectFieldFields[]): WorkflowTemplateForSelectFieldFields[] {
  // Group templates by type, then sort alphabetically, prioritizing exact matches.
  const sortedCompanyTemplates = sortIgnoreCaseWithExactMatchFirst(
    options.filter((option) => option.type === WorkflowTemplateType.COMPANY),
    (option) => option.name,
    EMPTY_STRING,
  );
  const sortedMyTemplates = sortIgnoreCaseWithExactMatchFirst(
    options.filter((option) => option.type === WorkflowTemplateType.MINE),
    (option) => option.name,
    EMPTY_STRING,
  );
  return [...sortedCompanyTemplates, ...sortedMyTemplates];
}
