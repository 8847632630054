import { clsx, WithClassName } from "@regrello/core-utils";
import React from "react";
import { useDragLayer } from "react-dnd";

export interface RegrelloDragPreviewPositionerProps<TItem extends { id: React.Key }, TItemType extends string>
  extends WithClassName {
  /** The custom drag preview content to display. */
  children: React.ReactNode;

  /** The current item in the list, for which to enable a drag preview when that item is dragged. */
  item: TItem;

  /** The item type of the current item in the list. */
  itemType: TItemType;

  /**
   * The cursor's offset relative to the drag-handle's top-left corner, at the moment the drag
   * began. Recommended value is to use `RegrelloDragHandle` to retrieve this value.
   */
  initialOffsetWithinDragHandle: {
    x: number;
    y: number;
  };

  /** The z-index to use for the drag preview. */
  zIndex: number;
}

function RegrelloDragPreviewPositionerInternal<TItem extends { id: React.Key }, TItemType extends string>({
  children,
  className,
  item,
  itemType,
  initialOffsetWithinDragHandle,
  zIndex,
}: RegrelloDragPreviewPositionerProps<TItem, TItemType>) {
  // (clewis): Invoking useDragLayer externally, e.g. from our `useListReorderDnd` hook, degarded
  // perf significantly. Putting it in heere keeps things snappy - probably because its changing
  // return value re-renders only this component as opposed to the entire parent component.
  const dragState = useDragLayer((monitor) => ({
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as TItem | null,
    itemType: monitor.getItemType(),
  }));

  if (
    !dragState.isDragging ||
    dragState.currentOffset == null ||
    dragState.itemType !== itemType ||
    dragState.item?.id !== item.id
  ) {
    return null;
  }

  // (clewis): Align the drag preview's top-left corner to the cursor.
  const x = dragState.currentOffset.x + initialOffsetWithinDragHandle.x;
  const y = dragState.currentOffset.y + initialOffsetWithinDragHandle.y;

  return (
    <div
      // (clewis): Position will be set via an inline 'transform' style attribute.
      className={clsx("fixed", "top-0", "left-0", "pointer-events-none", "cursor-grabbing", className)}
      style={{ transform: `translate(${x - 5}px, ${y - 5}px)`, cursor: "grabbing", zIndex }}
    >
      {children}
    </div>
  );
}

/**
 * Positions an arbitrary drag-preview component appropriately while a drag is in progress. Intended
 * to be rendered within each draggable element (e.g., within each element in a drag-reorderable
 * list, as opposed to at the list root). Pass your custom drag-preview component as `children`.
 */
export const RegrelloDragPreviewPositioner = React.memo(
  RegrelloDragPreviewPositionerInternal,
) as typeof RegrelloDragPreviewPositionerInternal;
