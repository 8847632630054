import { EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCase } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldUnit, useFieldUnitsQueryLazyQuery } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloChip } from "@regrello/ui-core";
import {
  AddOption,
  AllowMultiple,
  CreateFieldDialogFieldTypeHelperText,
  CurrencySymbol,
  FieldName,
  FieldType,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";

import { ValidationRules } from "../../../../../../../constants/globalConstants";
import { RegrelloCustomFieldPluginIcon } from "../../../../../../molecules/customFields/components/RegrelloCustomFieldPluginIcon";
import { CustomFieldPluginRegistrar } from "../../../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { CustomFieldPlugin } from "../../../../../../molecules/customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldSelectOption } from "../../../../../../molecules/formFields/_internal/selectOptions/RegrelloFormFieldSelectOption";
import {
  RegrelloControlledFormFieldSelect,
  RegrelloControlledFormFieldSwitch,
} from "../../../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloDraggableRowItem } from "../../../spectrumFields/_internal/RegrelloDraggableRowItem";

const LABEL_WIDTH = 130;

export interface ConfigureFieldFormFormFields {
  name: string;
  plugin: CustomFieldPlugin<unknown> | null;
  allowedValues: Array<{ value: string }>;
  fieldUnit?: FieldUnit;
  allowMultiple?: boolean;
}

export interface ConfigureFieldFormProps {
  /**
   * An allow list for filtering which field plugins this dialog should allow the user to create.
   */
  allowedFieldPlugins?: Array<CustomFieldPlugin<unknown>>;

  /**
   * If defined, the multiplicity switch, if the field type supports it, will be disabled with this
   * text rendered below.
   */
  allowMultipleSwitchDisabledHelperText?: string;

  /** Default value for the multiplicity switch, if the field type supports it. */
  defaultAllowMultiple?: boolean;

  form: UseFormReturn<ConfigureFieldFormFormFields>;

  /** The `mode` property determines whether the field **type** will be editable. */
  mode: "create" | "edit";
}

/**
 * The form shown in the 'Create Field' or 'Update Field' dialogs, which are backed by
 * `CustomFieldPlugin`.
 */
export const ConfigureFieldForm = React.memo<ConfigureFieldFormProps>(function CreateFieldFormFn({
  allowedFieldPlugins = [],
  allowMultipleSwitchDisabledHelperText,
  defaultAllowMultiple,
  form,
  mode,
}) {
  const formUnregister = form.unregister;

  const selectedPlugin = useWatch({ control: form.control, name: "plugin" });
  const isSelectedPluginNeedsFieldUnit = selectedPlugin?.isNeedsFieldUnit?.();
  const isSelectedPluginNeedsAllowedValues = selectedPlugin?.hasAllowedValues?.();
  const isSelectedPluginSupportsMultipleOrSingleValue =
    selectedPlugin?.isMultiValued?.() && selectedPlugin?.shouldDisplayMultiValuedToggle?.() === true;

  const [getFieldUnitsAsync, fieldUnitsResult] = useFieldUnitsQueryLazyQuery();
  const fieldUnits = fieldUnitsResult?.data?.fieldUnits ?? EMPTY_ARRAY;

  useEffect(() => {
    if (isSelectedPluginNeedsFieldUnit) {
      void getFieldUnitsAsync();
    }
  }, [isSelectedPluginNeedsFieldUnit, getFieldUnitsAsync]);

  const {
    fields: allowedValueRows,
    append: appendAllowedValue,
    remove: removeAllowedValue,
    move: moveAllowedValue,
  } = useFieldArray({
    control: form.control,
    name: "allowedValues",
  });

  const sortedPluginOptions = useMemo(() => {
    // (clewis): Assumes that all plugins are finished registering before this component is ever
    // rendered, which should always be the case since plugins are registered synchronously on
    // initial app load as of this writing.
    const customFieldPlugins =
      allowedFieldPlugins.length > 0 ? allowedFieldPlugins : CustomFieldPluginRegistrar.getAllPlugins();
    const creationAllowedFieldPlugins = customFieldPlugins.filter((plugin) => plugin.isCreateAndEditAllowed);
    return sortIgnoreCase(creationAllowedFieldPlugins, (plugin) => plugin.getFieldDisplayName());
  }, [allowedFieldPlugins]);

  // (zstanik): If there's only 1 plugin option to select from, as a UX nicety preload it into the
  // select field.
  useEffect(() => {
    if (sortedPluginOptions.length === 1) {
      form.setValue("plugin", sortedPluginOptions[0]);
    }
  }, [form, sortedPluginOptions]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!event.metaKey && event.key === "Enter" && isSelectedPluginNeedsAllowedValues) {
        appendAllowedValue({ value: "" });
      }
    },
    [appendAllowedValue, isSelectedPluginNeedsAllowedValues],
  );

  // (hchen): Add a input field at the first time a select type is chosen. This is not only a UX
  // nicety but also necessary because there must be at least one option for the select types.
  useEffect(() => {
    if (isSelectedPluginNeedsAllowedValues && allowedValueRows.length === 0) {
      appendAllowedValue({ value: "" });
    }
  }, [allowedValueRows.length, appendAllowedValue, isSelectedPluginNeedsAllowedValues]);

  const renderFieldUnitOption = useCallback((fieldUnit: FieldUnit) => {
    return (
      <RegrelloFormFieldSelectOption mainSnippets={[{ highlight: false, text: getFieldUnitDisplayName(fieldUnit) }]} />
    );
  }, []);

  const renderPluginOption = useCallback((customFieldPlugin?: CustomFieldPlugin<unknown> | null) => {
    if (customFieldPlugin == null) {
      return null;
    }

    return (
      <RegrelloFormFieldSelectOption
        mainSnippets={[{ highlight: false, text: customFieldPlugin.getFieldDisplayName() }]}
        startAdornment={
          <div className="mr-1.5">
            <RegrelloCustomFieldPluginIcon plugin={customFieldPlugin} />
          </div>
        }
      />
    );
  }, []);

  const handlePluginChange = useCallback(
    (_name: string, nextPlugin: CustomFieldPlugin<unknown> | null) => {
      if (selectedPlugin?.hasAllowedValues?.() && !nextPlugin?.hasAllowedValues?.()) {
        formUnregister("allowedValues");
      }
      if (selectedPlugin?.isNeedsFieldUnit?.() && !nextPlugin?.isNeedsFieldUnit?.()) {
        formUnregister("fieldUnit");
      }
    },
    [formUnregister, selectedPlugin],
  );

  return (
    <div onKeyDown={onKeyDown}>
      {/* Field name */}
      <RegrelloControlledFormFieldText
        autoFocus={true}
        controllerProps={{
          control: form.control,
          name: "name",
          rules: ValidationRules.REQUIRED,
        }}
        dataTestId={
          mode === "create" ? DataTestIds.CREATE_FIELD_DIALOG_FIELD_NAME : DataTestIds.EDIT_FIELD_DIALOG_FIELD_NAME
        }
        disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
        isRequiredAsteriskShown={true}
        label={FieldName}
        labelWidth={LABEL_WIDTH}
      />

      {/* Field type */}
      <RegrelloControlledFormFieldSelect
        controllerProps={{
          control: form.control,
          name: "plugin" as const,
          rules: ValidationRules.REQUIRED,
        }}
        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_TYPE}
        disabled={mode === "edit"}
        getOptionLabel={getCustomFieldPluginOptionLabel}
        helperText={selectedPlugin?.getHelperText?.() ?? CreateFieldDialogFieldTypeHelperText}
        isRequiredAsteriskShown={true}
        label={FieldType}
        labelWidth={LABEL_WIDTH}
        onValueChange={handlePluginChange}
        options={sortedPluginOptions}
        renderOption={renderPluginOption}
        renderSelectedValue={renderPluginOption}
      />

      {/* (If needed) Allowed values */}
      {isSelectedPluginNeedsAllowedValues && (
        <>
          {allowedValueRows.map((row, index) => {
            return (
              // (hchen): Tuned to align with the label width, I omit the EMPTY_STRING label below
              // so that the drag handle can be placed correctly
              <div key={row.id} className="pl-28.5">
                <RegrelloDraggableRowItem
                  index={index}
                  isDragEnabled={true}
                  moveRow={moveAllowedValue}
                  preview={<RegrelloChip>{row.value}</RegrelloChip>}
                  row={row}
                >
                  <div className="flex items-start">
                    <RegrelloControlledFormFieldText
                      autoFocus={true}
                      className="grow"
                      controllerProps={{
                        control: form.control,
                        name: `allowedValues.${index}.value`,
                        rules: {
                          ...ValidationRules.REQUIRED,
                          ...ValidationRules.UNIQUE_IN({
                            otherValues: allowedValueRows
                              .filter((_, uniqueInIndex) => uniqueInIndex !== index)
                              .map(({ value }) => value),
                          }),
                        },
                      }}
                      dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ALLOWED_VALUE}
                    />

                    {/* Delete */}
                    <RegrelloButton
                      className="ml-1 hover:text-danger-icon"
                      dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DELETE_OPTION_BUTTON}
                      disabled={index === 0 && allowedValueRows.length === 1}
                      iconOnly={true}
                      onClick={() => removeAllowedValue(index)}
                      size="medium"
                      startIcon="delete"
                      variant="ghost"
                    />
                  </div>
                </RegrelloDraggableRowItem>
              </div>
            );
          })}

          {/* Add */}
          <div className="my-1.5">
            <RegrelloButton
              className="ml-34.5" // (hchen): Tuned to align with the label width
              dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ADD_OPTION_BUTTON}
              disabled={!selectedPlugin?.isCreateAndEditAllowed}
              onClick={() => appendAllowedValue({ value: EMPTY_STRING })}
              startIcon="add-circle"
              variant="outline"
            >
              {AddOption}
            </RegrelloButton>
          </div>
        </>
      )}

      {/* (If needed) Field unit */}
      {isSelectedPluginNeedsFieldUnit && (
        <RegrelloControlledFormFieldSelect
          controllerProps={{
            control: form.control,
            name: "fieldUnit",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_UNIT}
          getOptionLabel={(fieldUnit) => getFieldUnitDisplayName(fieldUnit)}
          isRequiredAsteriskShown={true}
          label={CurrencySymbol}
          labelWidth={LABEL_WIDTH}
          options={fieldUnits}
          renderOption={renderFieldUnitOption}
        />
      )}

      {/* (If needed) Multiplicity toggle */}
      {isSelectedPluginSupportsMultipleOrSingleValue && (
        <RegrelloControlledFormFieldSwitch
          className="ml-2"
          controllerProps={{
            control: form.control,
            name: "allowMultiple",
          }}
          dataTestId={DataTestIds.CREATE_FIELD_DIALOG_EDIT_MULTIPLICITY}
          defaultChecked={defaultAllowMultiple ?? true}
          disabled={mode === "edit" || allowMultipleSwitchDisabledHelperText != null}
          isRequiredAsteriskShown={false}
          label="" // (krashanoff): Hack to enforce the label width occupies some space.
          labelWidth={LABEL_WIDTH}
          secondaryLabel={AllowMultiple}
        />
      )}
    </div>
  );
});

function getCustomFieldPluginOptionLabel(customFieldPlugin: CustomFieldPlugin<unknown>): string {
  return customFieldPlugin.getFieldDisplayName();
}

function getFieldUnitDisplayName(fieldUnit: FieldUnit) {
  return `${fieldUnit.symbol} ${fieldUnit.name}`;
}
