import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloIcon, RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";
import {
  CheckYourEmail,
  CheckYourEmailForInviteInstructionsListItem1,
  CheckYourEmailForInviteInstructionsListItem2,
  CheckYourEmailForInviteInstructionsListItem3,
  CheckYourEmailForInviteInstructionsListItem4,
  CheckYourEmailForInviteInstructionsListItem5,
  ContactSupportAt,
  CopyrightTitleCased,
  InviteResent,
  PasswordResetEmailSent,
  PrivacyPolicyTitleCased,
  ReceivedAnInviteSentInstructionBulletOne,
  ReceivedAnInviteSentInstructionBulletTwo,
  ReturnToLogin,
  ReturnToSupplierLogin,
  VerificationRequiredSentInstruction,
  VerificationRequiredSentInstructionBulletOne,
  VerificationRequiredSentInstructionBulletTwo,
  VerificationSentInstruction,
  WhatToDoNext,
} from "@regrello/ui-strings";
import uniqueId from "lodash/uniqueId";
import React, { useMemo } from "react";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";

export interface UnauthenticatedEmailSentPageProps {
  /**
   * Whether the email is sent for invitatation or password reset. Different instructions are
   * rendered based on the selection.
   */
  mode: "lost_invite" | "password_reset" | "verification_sent";
}

export const UnauthenticatedEmailSentPage = React.memo(function UnauthenticatedEmailSentPageFn({
  mode,
}: UnauthenticatedEmailSentPageProps) {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;

  const signInButtonHref = `${RoutePaths.LOGIN}/${
    isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined
  }`;

  const CheckYourEmailForInviteInstructionsList = useMemo(
    () =>
      [
        CheckYourEmailForInviteInstructionsListItem1,
        CheckYourEmailForInviteInstructionsListItem2,
        CheckYourEmailForInviteInstructionsListItem3,
        CheckYourEmailForInviteInstructionsListItem4,
        CheckYourEmailForInviteInstructionsListItem5,
      ].map((itemContents) => <li key={uniqueId("check-email-for-invite-instructions-")}>{itemContents()}</li>),
    [],
  );

  return (
    <div className="flex justify-center w-full h-full" data-testid={DataTestIds.UNAUTHENTICATED_EMAIL_SENT_PAGE_ROOT}>
      <div className="flex flex-col px-12 pb-4 w-150">
        <div className="flex flex-col justify-center flex-auto">
          <div className="mb-10">
            <RegrelloIcon iconName="mail" intent="neutral" size="x-large" variant="muted" />
          </div>
          <RegrelloTypography className="mb-10" variant="h1">
            {mode === "lost_invite" && isSupplierMode
              ? CheckYourEmail
              : mode === "lost_invite" || mode === "verification_sent"
                ? InviteResent
                : PasswordResetEmailSent}
          </RegrelloTypography>

          <RegrelloTypography className="mb-4" variant="body" weight="semi-bold">
            {mode === "lost_invite" && isSupplierMode
              ? WhatToDoNext
              : mode === "lost_invite"
                ? ReceivedAnInviteSentInstructionBulletOne
                : mode === "verification_sent"
                  ? VerificationSentInstruction
                  : VerificationRequiredSentInstruction}
          </RegrelloTypography>
          {mode === "lost_invite" && isSupplierMode ? (
            <RegrelloTypography>
              <ul className="pl-4 mb-10">{CheckYourEmailForInviteInstructionsList}</ul>
            </RegrelloTypography>
          ) : mode === "lost_invite" ? null : mode === "verification_sent" ? (
            <RegrelloTypography>
              <ul className="pl-4 mb-10">
                <li className="pb-1">{ReceivedAnInviteSentInstructionBulletOne}</li>
                <li>{ReceivedAnInviteSentInstructionBulletTwo()}</li>
              </ul>
            </RegrelloTypography>
          ) : (
            <RegrelloTypography>
              <ul className="pl-4 mb-10">
                <li className="pb-1">{VerificationRequiredSentInstructionBulletOne()}</li>
                <li>{VerificationRequiredSentInstructionBulletTwo()}</li>
              </ul>
            </RegrelloTypography>
          )}

          <RegrelloLinkV2 className="mb-10" to={signInButtonHref}>
            {mode === "lost_invite" && isSupplierMode ? ReturnToSupplierLogin : ReturnToLogin}
          </RegrelloLinkV2>

          <RegrelloTypography>
            {ContactSupportAt}

            <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
              {REGRELLO_SUPPORT_EMAIL}
            </RegrelloLinkV2>
          </RegrelloTypography>
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          {CopyrightTitleCased}
        </RegrelloTypography>
      </div>
    </div>
  );
});
