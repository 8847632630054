/**
 * A collection of unique "data-testid" attribute values that we use in UI integration tests.
 *
 * Per the [Testing Library docs](https://testing-library.com/docs/queries/bytestid), accessing
 * elements this way is the worst way to do things because it doesn't represent anything about how
 * our users see (and hear) the app, but it's still way better than querying based on brittle
 * assumptions about the expected DOM structure. In the future, we should abandon the `getByTestId`
 * API in favor of `getByRole`.
 */
export const DataTestIds = {
  ACTION_ITEM_ADD_CCS_BUTTON: "action-item-add-ccs-button",
  ACTION_ITEM_ASSIGNED_TABLE_FILTER: "action-item-assigned-table-filter",
  ACTION_ITEM_NAME: "action-item-name",
  ACTION_ITEM_IN_PROGRESS_ICON: "action-item-in-progress-icon",
  ACTION_ITEM_CARD_ASSIGNEES: "action-item-card-assignees",
  ACTION_ITEM_CARD_DELETE_BUTTON: "action-item-card-delete-button",
  ACTION_ITEM_CARD_DUPLICATE_BUTTON: "action-item-card-duplicate-button",
  ACTION_ITEM_CARD_EDIT_BUTTON: "action-item-card-edit-button",
  ACTION_ITEM_CARD_NAME: "action-item-card-name",
  ACTION_ITEM_HEADER_STAR_BUTTON: "action-item-header-star-button",
  ACTION_ITEM_HEADER_MORE_BUTTON: "action-item-header-more-button",
  ADD_APPROVALS_BUTTON: "add-approvals-button",
  ADD_CUSTOM_FIELD_BUTTON: "add-custom-field-button",
  ACTION_ITEM_START_BUTTON: "action-item-card-start-button",
  ACTION_ITEM_TEMPLATE_NAME: "action-item-template-name",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_ADD_FORM_DATA_BUTTON: "add-automated-action-item-dialog-add-form-data-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_ADD_HEADER_BUTTON: "add-automated-action-item-dialog-add-header-button",
  ADD_SCIM_CUSTOM_ATTRIBUTE_BUTTON: "add-scim-custom-attribute-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_ADD_JSON_DATA_BUTTON: "add-automated-action-item-dialog-add-json-data-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_ADD_QUERY_BUTTON: "add-automated-action-item-dialog-add-query-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_FORM_DATA_DELETE_BUTTON:
    "add-automated-action-item-dialog-field-form-data-delete-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_JSON_DATA_DELETE_BUTTON:
    "add-automated-action-item-dialog-field-json-data-delete-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_HEADER_DELETE_BUTTON:
    "add-automated-action-item-dialog-field-header-delete-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_HEADER_KEY: "add-automated-action-item-dialog-field-header-key",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_HEADER_VALUE_CUSTOM_FIELD:
    "add-automated-action-item-dialog-field-header-value-custom-field",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_HEADER_VALUE_TEXT: "add-automated-action-item-dialog-field-header-value-text",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_HEADER_VALUE_TYPE: "add-automated-action-item-dialog-field-header-value-type",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_QUERY_KEY: "add-automated-action-item-dialog-field-query-key",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_QUERY_VALUE_TYPE: "add-automated-action-item-dialog-field-query-value-type",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_QUERY_VALUE_CUSTOM_FIELD:
    "add-automated-action-item-dialog-field-query-value-custom-field",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_QUERY_VALUE_TEXT: "add-automated-action-item-dialog-field-query-value-text",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_QUERY_DELETE_BUTTON:
    "add-automated-action-item-dialog-field-query-delete-button",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_METHOD: "add-automated-action-item-dialog-field-method",
  ADD_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_URL: "add-automated-action-item-dialog-field-url",
  ADD_ACTION_ITEM_BUTTON: "add-action-item-button",
  ADD_ACTION_ITEM_DIALOG: "add-action-item-dialog",
  ADD_ACTION_ITEM_DIALOG_FIELD_APPROVAL_DUE_DATE: "add-action-dialog-field-approval-due-date",
  ADD_ACTION_ITEM_DIALOG_FIELD_APPROVAL_LEAD_TIME_IN_WORKFLOW:
    "add-action-item-dialog-field-approval-lead-time-in-workflow",
  ADD_ACTION_ITEM_DIALOG_FIELD_APPROVERS: "add-action-item-dialog-field-approvers",
  ADD_ACTION_ITEM_DIALOG_FIELD_APPROVERS_IN_WORKFLOW: "add-action-item-dialog-field-approvers-in-workflow",
  ADD_ACTION_ITEM_DIALOG_DELETE_APPROVAL: "add-action-item-dialog-delete-approval",
  ADD_ACTION_ITEM_DIALOG_FIELD_ASSIGNEES: "add-action-item-dialog-field-assignees",
  ADD_ACTION_ITEM_DIALOG_FIELD_BLUEPRINT: "add-action-item-dialog-field-blueprint",
  ADD_ACTION_ITEM_DIALOG_FIELD_CC: "add-action-item-dialog-field-cc",
  ADD_ACTION_ITEM_DIALOG_FIELD_DESCRIPTION: "add-action-item-dialog-field-description",
  ADD_ACTION_ITEM_DIALOG_FIELD_DOCUMENTS: "add-action-item-dialog-field-documents",
  ADD_ACTION_ITEM_DIALOG_FIELD_DUE_DATE: "add-action-item-dialog-field-due-date",
  ADD_ACTION_ITEM_DIALOG_FIELD_REQUEST_ACK: "add-action-item-dialog-field-request-ack",
  ADD_ACTION_ITEM_DIALOG_FIELD_REQUEST_APPROVAL: "add-action-item-dialog-field-request-approval",
  ADD_ACTION_ITEM_DIALOG_FIELD_REQUEST_APPROVAL_IN_WORKFLOW:
    "add-action-item-dialog-field-request-approval-in-workflow",
  ADD_ACTION_ITEM_DIALOG_FIELD_LEAD_TIME: "add-action-item-dialog-field-lead-time",
  ADD_ACTION_ITEM_DIALOG_FIELD_SUBJECT: "add-action-item-dialog-field-subject",
  ADD_ACTION_ITEM_DIALOG_FIELD_TAGS: "add-action-item-dialog-field-tags",
  ADD_ACTION_ITEM_DIALOG_FIELD_INSTANCE_LIST_ITEM: "add-action-item-dialog-field-instance-list-item",
  ADD_ACTION_ITEM_DIALOG_SELECT_FIELDS_BUTTON: "add-action-item-dialog-select-fields-button",

  ADD_ACTION_ITEM_DIALOG_ADD_FORM_BUTTON: "add-action-item-dialog-add-form-button",
  ADD_ACTION_ITEM_DIALOG_DELETE_FORM_BUTTON: "add-action-item-dialog-delete-form-button",
  ADD_ACTION_ITEM_DIALOG_SPECTRUM_FORM_SELECT: "add-action-item-dialog-spectrum-form-select",
  ADD_ACTION_ITEM_DIALOG_VIEW_FORM_BUTTON: "add-action-item-dialog-view-form-button",

  ADD_AUTOMATED_ACTION_ITEM_DIALOG: "add-automated-action-item-dialog",
  ADD_AUTOMATION_FORM_DIALOG: "add-automation-form-dialog",
  ADD_TAG_DIALOG: "add-tag-dialog",
  ADD_TAG_DIALOG_FIELD_NAME: "add-tag-dialog-field-name",
  ADD_TAG_DIALOG_FIELD_TAG_TYPE: "add-tag-dialog-field-tag-type",
  ADD_TAG_TYPE_DIALOG: "add-tag-type-dialog",
  ADD_TAG_TYPE_DIALOG_FIELD_TAG_NAME: "add-tag-type-dialog-field-tag-name",
  ADD_TAG_TYPE_DIALOG_FIELD_TAG_TYPE: "add-tag-type-dialog-field-tag-type",
  ADD_TAG_TYPE_DIALOG_FIELD_TAG_TYPE_CREATE_OPTION: "add-tag-type-dialog-field-tag-type-create-option",
  ADD_TAG_TYPE_DIALOG_SUBMIT_BUTTON: "add-tag-type-dialog-submit-button",
  ADD_USER_DIALOG: "add-user-dialog",
  ADD_USER_DIALOG_FIELD_ACCESS_TYPE: "add-user-dialog-field-access-type",
  ADD_USER_DIALOG_FIELD_ACCESS_CUSTOM_ROLES: "add-user-dialog-field-access-custom-roles",
  ADD_USER_DIALOG_FIELD_ACCESS_LEVEL: "add-user-dialog-field-access-level",
  ADD_USER_DIALOG_FIELD_ACCESS_ROLE: "add-user-dialog-field-access-role",
  ADD_USER_DIALOG_FIELD_EMAIL: "add-user-dialog-field-email",
  ADD_USER_DIALOG_FIELD_MULTIPLE_EMAILS: "add-user-dialog-field-multiple-emails",
  ADD_USER_DIALOG_FIELD_NAME: "add-user-dialog-field-name",
  ADD_USER_DIALOG_SUBMIT_BUTTON: "add-user-dialog-submit-button",
  ADD_ROLE_DIALOG: "add-role-dialog",
  ADD_ROLE_DIALOG_ALLOW_INVITING_CHECKBOX: "add-role-dialog-allow-inviting",
  ADD_WORKFLOW_DESCRIPTION_INPUT_WRAPPER: "add-workflow-description-input-wrapper",
  ADD_WORKFLOW_DIALOG: "add-workflow-dialog",
  ADD_WORKFLOW_NAME_INPUT_WRAPPER: "add-workflow-name-input-wrapper",
  AUDIT_ITEM_TEXT_DIFF: "audit-item-text-diff",
  AUTOMATION_SYNC_ICON: "automation-sync-icon",
  AUTOMATION_SYNC_STATUS: "automation-sync-status",
  BUTTON_SPINNER: "button-spinner",
  BLUEPRINT_CONTACT_EMAIL_ALIAS_INPUT: "blueprint-contact-email-alias-input",
  BULK_EDIT_DIALOG_FIELD_ASSIGNEES: "bulk-edit-dialog-field-assignees",
  BULK_EDIT_DIALOG_FIELD_CCS: "bulk-edit-dialog-field-ccs",
  CONFIGURE_AUTOMATION_DIALOG_ADD_BUTTON: "configure-automation-dialog-add-button",
  CONFIGURE_AUTOMATION_DIALOG_ADD_NEW_CONNECTION_BUTTON: "configure-automation-dialog-add-new-connection-button",
  CONFIGURE_AUTOMATION_DIALOG_BACK_BUTTON: "configure-automation-dialog-back-button",
  CONFIGURE_AUTOMATION_DIALOG_BOX_SELECT_AUTOMATION_SAVE_SAVE_FILE_TO_FOLDER_BUTTON:
    "configure-automation-dialog-box-select-automation-save-file-to-folder-button",
  CONFIGURE_AUTOMATION_DIALOG_SHAREPOINT_SELECT_AUTOMATION_SAVE_SAVE_FILE_TO_FOLDER_BUTTON:
    "configure-automation-dialog-sharepoint-select-automation-save-file-to-folder-button",
  CONFIGURE_AUTOMATION_DIALOG_DOCUSIGN_SELECT_AUTOMATION_CREATE_DRAFT_FROM_TEMPLATE_BUTTON:
    "configure-automation-dialog-docusign-select-automation-create-draft-from-template-button",
  CONFIGURE_AUTOMATION_DIALOG_DOCUSIGN_SELECT_AUTOMATION_SEND_FOR_SIGNATURE_BUTTON:
    "configure-automation-dialog-docusign-select-automation-send-for-signature-button",
  CONFIGURE_AUTOMATION_DIALOG_HTTP_RESPONSE_ADD_VALUE_BUTTON:
    "configure-automation-dialog-http-response-add-value-button",
  CONFIGURE_AUTOMATION_DIALOG_HTTP_RESPONSE_DELETE_VALUE_BUTTON:
    "configure-automation-dialog-http-response-delete-value-button",
  CONFIGURE_AUTOMATION_DIALOG_NEXT_BUTTON: "configure-automation-dialog-next-button",
  CONFIGURE_AUTOMATION_DIALOG_SELECT_APPLICATION_BOX_BUTTON:
    "configure-automation-dialog-select-application-box-button",
  CONFIGURE_AUTOMATION_DIALOG_SELECT_APPLICATION_CUSTOM_BUTTON:
    "configure-automation-dialog-select-application-custom-button",
  CONFIGURE_AUTOMATION_DIALOG_SELECT_APPLICATION_DOCUSIGN_BUTTON:
    "configure-automation-dialog-select-application-docusign-button",
  CONFIGURE_AUTOMATION_DIALOG_SELECT_APPLICATION_DOCUSIGN_DEVELOPER_BUTTON:
    "configure-automation-dialog-select-application-docusign-developer-button",
  CONFIGURE_AUTOMATION_DIALOG_SELECT_AUTHENTICATION_VIEW_LIST:
    "configure-automation-dialog-select-authentication-view-list",
  CONFIGURE_AUTOMATION_DIALOG_EXPAND_TEST_SECTION_BUTTON: "configure-automation-dialog-expand-test-section-button",
  CONFIGURE_AUTOMATION_DIALOG_TEST_BUTTON: "configure-automation-dialog-test-button",
  CONFIGURE_AUTOMATION_DIALOG_TEST_MAPPED_VALUE: "configure-automation-dialog-test-mapped-value-button",
  CONFIGURE_BLUEPRINT_CONTACT_EMAIL_BUTTON: "configure-blueprint-contact-email-button",
  CONFIRMATION_DIALOG_CANCEL_BUTTON: "confirmation-dialog-cancel-button",
  CONFIRMATION_DIALOG_CONFIRM_BUTTON: "confirmation-dialog-confirm-button",
  CONFIRMATION_DIALOG_CONTENT: "confirmation-dialog-content",
  CONFIRMATION_DIALOG_SECONDARY_BUTTON: "confirmation-dialog-secondary-button",
  CONFIRMATION_DIALOG_TITLE: "confirmation-dialog-title",
  CONSTRAINT_RULE_ADD: "constraint-rule-add",
  CONSTRAINT_RULE_PREVIEW: "constraint-rule-preview",
  CONTENT_CHIP: "content-chip",
  CONTINUE_WITH_GOOGLE_BUTTON: "continue-with-google-button",
  CREATE_AUTOMATION_TASK_BUTTON: "create-automation-task-button",
  CREATE_FIELD_BUTTON: "create-field-button",
  CREATE_FIELD_HEADER_BUTTON: "create-field-header-button",
  CREATE_FIELD_DIALOG: "create-field-dialog",
  CREATE_FIELD_DIALOG_FIELD_ADD_OPTION_BUTTON: "create-field-dialog-field-add-option-button",
  CREATE_FIELD_DIALOG_FIELD_CONSTRAINT: "create-field-dialog-field-constraint",
  CREATE_FIELD_DIALOG_FIELD_DATA_FORMAT_SWITCH: "create-field-dialog-field-data-format-switch",
  CREATE_FIELD_DIALOG_FIELD_DELETE_OPTION_BUTTON: "create-field-dialog-field-delete-option-button",
  CREATE_FIELD_DIALOG_FIELD_ALLOWED_VALUE: "create-field-dialog-field-allowed-value",
  CREATE_FIELD_DIALOG_FIELD_NAME: "create-field-dialog-field-name",
  CREATE_FIELD_DIALOG_FIELD_TYPE: "create-field-dialog-field-type",
  CREATE_FIELD_DIALOG_FIELD_UNIT: "create-field-dialog-field-unit",
  CREATE_FIELD_DIALOG_EDIT_MULTIPLICITY: "create-field-dialog-edit-multiplicity",
  CREATE_NEW_WORKFLOW_BUTTON: "create-new-workflow-button",
  CREATE_OBJECT_BUTTON: "create-object-button",
  CREATE_TEAM_BUTTON: "create-team-button",
  CREATE_TEMPLATE_BUTTON: "create-template-button",
  CREATE_TEMPLATE_DIALOG: "create-template-dialog",
  CREATE_TEMPLATE_DIALOG_DESCRIPTION_INPUT: "create-template-dialog-description-input",
  CREATE_TEMPLATE_DIALOG_NAME_INPUT: "create-template-dialog-name-input",
  CREATE_TEMPLATE_DIALOG_SUBMIT_BUTTON: "create-template-dialog-submit-button",
  CREATE_TENANT_DIALOG: "create-tenant-dialog",
  CREATE_TENANT_DIALOG_CUSTOMER_NAME: "create-tenant-dialog-customer-name",
  CREATE_TENANT_DIALOG_TENANT_NAME: "create-tenant-dialog-tenant-name",
  CREATE_TENANT_MENU_ITEM: "create-tenant-menu-item",
  CREATE_WORKFLOW_EXPAND_MORE_BUTTON: "create-workflow-expand-more-button",
  CREATE_WORKFLOW_FROM_CSV_BUTTON: "create-workflow-from-csv-button",
  CSV_UPLOAD_INPUT: "csv-upload-input",
  CUSTOM_FIELD_DELETE_FROM_ACTION_ITEM_BUTTON: "custom-field-delete-from-action-item-button",
  CUSTOM_FIELD_INPUT_MAPPING_FIELD_SELECT: "custom-field-input-mapping-field-select",
  CUSTOM_FIELD_INPUT_MAPPING_ROW: "custom-field-input-mapping-row",
  CUSTOM_FIELD_INPUT_TYPE: "custom-field-input-type",
  CUSTOM_FIELD_INPUT_TYPE_SWITCH: "custom-field-input-type-switch",
  CUSTOM_FIELD_LABEL: "custom-field-label",
  CUSTOM_FIELD_NAME_SELECT: "custom-field-name-select",
  CUSTOM_FIELD_IS_ALLOWED_TO_APPEND_ALLOWED_VALUES_SWITCH: "custom-field-is-allowed-to-append-allowed-values-switch",
  CUSTOM_FIELD_TABLE: "custom-field-table",
  CUSTOM_FIELD_TYPE_SELECT: "custom-field-type-select",
  CUSTOM_FIELD_VALUE: "custom-field-value",
  CUSTOM_FIELD_MULTISELECT_VALUE: "custom-field-multiselect-value",
  CUSTOM_FIELD_SAVE_VALUES_BUTTON: "custom-field-save-values-button",
  CUSTOM_FIELD_VALUE_INPUT: "custom-field-value-input",
  CUSTOM_FIELD_VALUE_TYPE_SELECT: "custom-field-value-type-select",
  DATA_SOURCE_PRIMARY_FIELD_SELECT: "data-source-primary-field-select",
  DATA_SOURCES_CSV_BUTTON: "data-sources-csv-button",
  DATA_SOURCES_DOWNLOAD_BUTTON: "data-sources-download-button",
  DATA_SOURCES_REST_BUTTON: "data-sources-rest-button",
  DATA_SOURCES_UPDATE_BUTTON: "data-sources-update-button",
  DATA_SOURCES_VIEW_LOG_BUTTON: "data-sources-view-log-button",
  DATA_SOURCES_DELETE_BUTTON: "data-sources-delete-button",
  DATA_SOURCES_REST_NAME_INPUT: "data-sources-rest-name-input",
  DATA_SOURCES_REST_URL_INPUT: "data-sources-rest-url-input",
  DELETE_FIELD_BUTTON: "delete-field-button",
  DELETE_FIELD_DIALOG: "delete-field-dialog",
  DELETE_ROLE_BUTTON: "delete-role-button",
  DELETE_ROLE_DIALOG: "delete-role-dialog",
  DIALOG_ACTION_DROPDOWN_TRIGGER: "dialog-action-dropdown-trigger",
  DOCUMENT_CHIP: "document-chip",
  DOCUMENT_CHIP_PROGRESS: "document-chip-progress",
  DOCUMENT_DELETE_BUTTON: "document-delete-button",
  DOCUMENT_TABLE_FILENAME_CELL: "document-table-filename-cell",
  DOCUMENT_TABLE_WORKFLOW_CELL: "document-table-workflow-cell",
  DIALOG_CLOSE_BUTTON: "dialog-close-button",
  DIALOG_MAXIMIZE_BUTTON: "dialog-maximize-button",
  EDIT_AUTOMATION_FORM_DIALOG: "edit-automation-form-dialog",
  EDIT_FIELD_BUTTON: "edit-field-button",
  EDIT_FIELD_DIALOG: "edit-field-dialog",
  EDIT_FIELD_DIALOG_FIELD_NAME: "edit-field-dialog-field-name",
  EDIT_ROLE_BUTTON: "edit-role-button",
  EDIT_TEMPLATE_BUTTON: "edit-template-button",
  EDIT_USER_DIALOG: "edit-user-dialog",
  EDIT_AUTOMATED_ACTION_ITEM_DIALOG: "edit-automation-action-item-dialog",
  ENABLE_LOGIN_SPOOFING_TOGGLE: "enable-login-spoofing-toggle",
  FIELD_ERROR: "field-error",
  FIELD_SETTINGS_SHOW_ME_BUTTON: "field-settings-show-me-button",
  FIELD_SETTINGS_LEARN_MORE_BUTTON: "field-settings-learn-more-button",
  FIELD_INSTANCE_GROUP_HEADING: "field-instance-group-heading",
  FORMS_PAGE_CREATE_FORM_BUTTON: "forms-page-create-form-button",
  FORM_BUILDER_ACTION_BAR_EDIT_BUTTON: "form-builder-action-bar-edit-button",
  FORM_BUILDER_ACTION_BAR_DELETE_BUTTON: "form-builder-action-bar-delete-button",
  FORM_BUILDER_ADD_SECTION_BUTTON: "form-builder-add-section-button",
  FORM_BUILDER_CREATE_FIELD_BUTTON: "form-builder-create-field-button",
  FORM_BUILDER_DND_FIELD: "form-builder-dnd-field",
  FORM_BUILDER_DND_INSTRUCTION: "form-builder-dnd-instruction",
  FORM_BUILDER_DND_INSTRUCTION_INPUT: "form-builder-dnd-instruction-input",
  FORM_BUILDER_DND_SECTION: "form-builder-dnd-section",
  FORM_BUILDER_DND_SECTION_NAME: "form-builder-section-name",
  FORM_BUILDER_FIELD_CHIP: "form-builder-field-chip",
  FORM_BUILDER_FIELD_DESCRIPTION_ICON: "form-builder-field-description-icon",
  FORM_BUILDER_LAYOUT_CHIP: "form-builder-layout-chip",
  FORM_BUILDER_NAME: "form-builder-name",
  FORM_BUILDER_PREVIEW_BUTTON: "form-builder-preview-button",
  FORM_BUILDER_PREVIEW_DIALOG: "form-builder-preview-dialog",
  FORM_BUILDER_PUBLISH_BUTTON: "form-builder-publish-button",
  FORM_BUILDER_SAVE_BUTTON: "form-builder-save-button",
  FORM_BUILDER_SEARCH: "form-builder-search",
  FORM_DIALOG_AUTOSAVE_INDICATOR: "form-dialog-autosave-indicator",
  FORM_DIALOG_CANCEL_BUTTON: "form-dialog-cancel-button",
  FORM_DIALOG_SUBMIT_BUTTON_PRIMARY: "form-dialog-submit-button-primary",
  FORM_DIALOG_SUBMIT_BUTTON_PRIMARY_ALTERNATE: "form-dialog-submit-button-primary-alternate",
  FORM_DIALOG_SUBMIT_BUTTON_SECONDARY: "form-dialog-submit-button-secondary",
  FORM_FIELD_DATE_CALENDAR_BUTTON: "form-field-date-calendar-button",
  FORM_FIELD_DATE_DATE_SEGMENT: "form-field-date-date-segment",
  FORM_FIELD_DATE_TODAY_BUTTON: "form-field-date-today-button",
  FORM_FIELD_DATE_CLEAR_BUTTON: "form-field-date-clear-button",
  FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG: "form-field-document-add-link-dialog",
  FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG_FILE_NAME: "form-field-document-add-link-dialog-file-name",
  FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG_FILE_URL: "form-field-document-add-link-dialog-file-url",
  FORM_FIELD_MULTISELECT_DISPLAY_TAG: "form-field-multiselect-display-tag",
  FORM_FIELD_MULTISELECT_DROP_DOWN: "form-field-multiselect-drop-down",
  FORM_FIELD_SELECT_LOADING_OPTION: "form-field-select-loading-option", // Shared
  FORM_FIELD_SELECT_ADD_NOT_ALLOWED_OPTION: "form-field-select-not-allowed-option", // Shared
  FORM_FIELD_SELECT_OPTION: "form-field-select-option", // Shared
  FORM_FIELD_SELECT_OPTIONS_ROOT: "form-field-select-options-root",
  FORM_FIELD_SELECT_MENU_INPUT: "form-field-select-menu-input",
  FORM_FIELD_SELF_CONTAINED_FORM_CANCEL_BUTTON: "form-field-self-contained-form-cancel-button",
  FORM_FIELD_SELF_CONTAINED_FORM_SAVE_BUTTON: "form-field-self-contained-form-save-button",
  FORM_SECTION_TOGGLE_VISIBILITY_BUTTON: "form-section-toggle-visibility-button",
  ICON_PAUSED_FOR_EXCEPTION: "icon-paused-for-exception",
  PAGE_CHECKBOX_FILTER_CONTAINER_TITLE_WRAPPER: "page-checkbox-filter-container-title-wrapper",
  PAGE_CHECKBOX_FILTER_ITEM: "page-checkbox-filter-item",
  PAGE_CHECKBOX_FILTER_ITEM_ONLY_BUTTON_WRAPPER: "page-checkbox-filter-item-only-button-wrapper",
  PAGE_LAYOUT: "page-layout",
  PAGE_SHOW_COMPLETED_SWITCH: "page-show-completed-switch",
  PAGE_TAB_ITEM: "page-tab-item",
  PAGE_TAB_ITEM_ABOUT: "page-tab-item-about",
  PAGE_TITLE: "page-title",
  PAGE_DESCRIPTION: "page-description",
  HOME_PAGE_CANCEL: "home-page-cancel",
  HOME_PAGE_DELETE: "home-page-delete",
  HOME_PAGE_EDIT: "home-page-edit",
  HOME_PAGE_FILTER_POPOVER_FORM: "home-page-filter-popover-form",
  HOME_PAGE_REMOVE: "home-page-remove",
  HOME_PAGE_RENAME: "home-page-rename",
  HOME_PAGE_SAVE: "home-page-save",
  HOME_PAGE_SAVE_AS: "home-page-save-as",
  HOME_PAGE_SELECT: "home-page-select",
  HOME_PAGE_SHARE: "home-page-share",
  HOME_SAVE_VIEW_DIALOG: "home-save-view-dialog",
  HOME_SAVE_VIEW_NAME_INPUT: "home-save-view-name-input",
  HOME_SAVE_VIEW_PARTY_INPUT: "home-save-view-party-input",
  HOME_SAVE_VIEW_WORKSPACE_SHARE_CHECKBOX: "home-view-workspace-share-checkbox",
  HOME_SHARE_VIEW_PARTY_INPUT: "home-share-view-party-input",
  HOME_TABLE_COMPLETED_CELL: "home-table-completed-cell",
  HOME_TABLE_DUE_SOON_CELL: "home-table-due-soon-cell",
  HOME_TABLE_EXCEPTION_CELL: "home-table-exception-cell",
  HOME_TABLE_LAST_UPDATED_CELL: "home-table-last-updated-cell",
  HOME_TABLE_NAME_CELL: "home-table-name-cell",
  HOME_TABLE_OPEN_CELL: "home-table-open-cell",
  HOME_TABLE_OPEN_TASKS_CELL: "home-table-open-tasks-cell",
  HOME_TABLE_OVERDUE_CELL: "home-table-overdue-cell",
  HOME_VIEW_EXPORT: "home-view-export",
  HOME_VIEW_EXPORT_WORKFLOWS_BUTTON: "home-view-export-workflows-button",
  HOME_VIEW_EXPORT_TASKS_BUTTON: "home-view-export-tasks-button",
  HOME_VIEW_FILTER_BUTTON: "home-view-filter-button",
  HOME_VIEW_FILTER_ADD_FILTER_BUTTON: "home-view-filter-add-filter-button",
  HOME_VIEW_FILTER_COLUMN_SELECT: "home-view-filter-column-select",
  HOME_VIEW_FILTER_DELETE_FILTER_BUTTON: "home-view-filter-delete-filter-button",
  HOME_VIEW_FILTER_APPLY_BUTTON: "home-view-filter-apply-button",
  HOME_VIEW_FILTER_TEXT_INPUT: "home-view-filter-text-input",
  HOME_VIEW_LOADING_INDICATOR: "home-view-loading-indicator",
  HOME_VIEW_LIST_ITEM: "home-view-list-item",
  HOME_VIEW_LIST_ITEM_DEFAULT_ICON: "home-view-list-item-default-icon",
  HOME_VIEW_MENU: "home-view-menu",
  HOME_VIEW_MENU_PROVIDED_VIEWS_SECTION: "home-view-menu-provided-views-section",
  HOME_VIEW_SELECT: "home-view-select",
  HOME_VIEW_SETTINGS_BUTTON: "home-view-settings-button",
  INFO_ICON: "info-icon",
  LOCKED_APPROVALS_SWITCH: "locked-approvals-switch",
  LOCK_WORKFLOWS_SWITCH: "lock-workflows-switch",
  MESSAGE_SECTION_DROPDOWN: "message-section-dropdown",
  MESSAGE_SEND_AND_RESOLVE_MENU_OPTION: "message-send-and-resolve-menu-option",
  MESSAGE_TEXT: "message-text",
  MESSAGE_THREAD: "message-thread",
  MESSAGE_THREAD_ITEM: "message-thread-item",
  MESSAGE_THREAD_ITEM_DETAILS: "message-thread-item-details",
  MORE_ACTIONS_BUTTON: "more-actions-button",
  MORE_DETAILS_BUTTON: "more-details-button",
  NAVIGATE_BACK_BUTTON: "navigate-back-button",
  NEW_WORKFLOW_DIALOG: "new-workflow-dialog",
  NON_EDITABLE_FORM_FIELD_HELPER_TEXT: "non-editable-form-field-helper-text",
  PAGE_ALL_ACTION_ITEMS_BUTTON: "page-all-action-items-button",
  PAGE_MY_ACTION_ITEMS_BUTTON: "page-my-action-items-button",
  PAGE_NONIDEAL_STATE_TITLE: "page-nonideal-state-title",
  PERMISSION_DROPDOWN_BUTTON: "permission-dropdown-button",

  GRANT_ACCESS_TO_BLUEPRINT_DIALOG: "grant-access-to-blueprint-dialog",
  GRANT_ACCESS_TO_BLUEPRINT_DIALOG_PARTIES_FIELD: "grant-access-to-blueprint-dialog-parties-field",
  GRANT_ACCESS_TO_BLUEPRINT_DIALOG_DRAFT_ACCESS_RADIO_GROUP:
    "grant-access-to-blueprint-dialog-draft-access-radio-group",

  BULK_START_WORKFLOW_SUCCESS_COUNT: "bulk-start-workflow-success-count",
  BULK_START_WORKFLOW_TAKE_ME_THERE_BUTTON: "bulk-start-workflow-take-me-there-button",

  CONFIGURE_TEAM_DIALOG: "configure-team-dialog",
  CONFIGURE_TEAM_DIALOG_ADMINS: "configure-team-dialog-admins",
  CONFIGURE_TEAM_DIALOG_MEMBERS: "configure-team-dialog-members",
  CONFIGURE_TEAM_DIALOG_TEAM_NAME: "configure-team-dialog-team-name",
  CONFIGURE_TEAM_DIALOG_TEAM_EMAIL: "configure-team-dialog-team-email",
  CONFIGURE_TEAM_DIALOG_TEAM_TYPE: "configure-team-dialog-team-type",

  CONFIGURE_SPECTRUM_FIELD_DIALOG_NAME: "configure-spectrum-field-dialog-name",
  CONFIGURE_SPECTRUM_FIELD_DIALOG_HELPER_TEXT: "configure-spectrum-field-dialog-helper-text",
  CONFIGURE_SPECTRUM_FIELD_DIALOG_FIELD_TYPE: "configure-spectrum-field-dialog-field-type",
  CONFIGURE_FORM_ENTITY_DIALOG: "configure-form-entity-dialog",
  CONFIGURE_FORM_ENTITY_DIALOG_ACTIVE_RULE_TITLE: "configure-form-entity-dialog-active-rule-title",
  CONFIGURE_FORM_ENTITY_DIALOG_ACTIVE_RULE_CONDITION: "configure-form-entity-dialog-active-rule-condition",
  CONFIGURE_FORM_ENTITY_DIALOG_ACTIVE_RULE_CONDITIONED_FIELD:
    "configure-form-entity-dialog-active-rule-conditioned-field",
  CONFIGURE_FORM_ENTITY_DIALOG_BUTTON: "configure-form-entity-dialog-button",
  CONFIGURE_FORM_ENTITY_DIALOG_CLOSE_BUTTON: "configure-form-entity-dialog-close-button",
  CONFIGURE_FORM_ENTITY_DIALOG_CONSTRAINT: "configure-form-entity-dialog-constraint",
  CONFIGURE_FORM_ENTITY_DIALOG_HELPER_TEXT: "configure-form-entity-dialog-helper-text",
  CONFIGURE_FORM_ENTITY_DIALOG_TITLE: "configure-form-entity-dialog-title",
  CONFIGURE_FORM_ENTITY_DIALOG_ADD_RULE_BUTTON: "configure-form-entity-dialog-add-rule-button",
  CONFIGURE_FORM_ENTITY_DIALOG_REQUIRED_SWITCH: "configure-form-entity-dialog-required-switch",
  CONFIGURE_FORM_ENTITY_DIALOG_PREPOPULATE_SWITCH: "configure-form-entity-dialog-prepopulate-switch",

  RULE_BUILDER_DIALOG: "rule-builder-dialog",
  RULE_BUILDER_COMPOSITE_OPERATOR_SELECT: "rule-builder-composite-operator-select", // (hchen): The operator that combines the results of it's nested rules
  RULE_BUILDER_OPERATOR_SELECT: "rule-builder-operator-select", // (hchen): The operator used to evaluate a single field.
  RULE_BUILDER_FIELD_SELECT: "rule-builder-field-select",
  RULE_BUILDER_RULE_ARG: "rule-builder-rule-arg",
  RULE_BUILDER_RULE_SELECT: "rule-builder-rule-select",
  RULE_BUILDER_DELETE_RULE_BUTTON: "rule-builder-delete-rule-button",

  DETAIL_DIALOG: "detail-dialog",
  DETAIL_DIALOG_ACCEPT_INVITE_BUTTON: "detail-dialog-accept-invite-button",
  DETAIL_DIALOG_ATTACHMENT_ROW_BUTTON: "detail-dialog-attachment-row-button",
  DETAIL_DIALOG_APPROVAL_FORM_APPROVE_BUTTON: "detail-dialog-approval-form-approve-button",
  DETAIL_DIALOG_APPROVAL_FORM_REJECT_BUTTON: "detail-dialog-approval-form-reject-button",
  DETAIL_DIALOG_CLOSE_BUTTON: "detail-dialog-close-button",
  DETAIL_DIALOG_DESCRIPTION_ACCORDION_WRAPPER: "detail-dialog-description-accordion-wrapper",
  DETAIL_DIALOG_DESCRIPTION_INPUT: "detail-dialog-description-input",
  DETAIL_DIALOG_DESCRIPTION_BUTTON: "detail-dialog-description-button",
  DETAIL_DIALOG_DOCUMENT_ROW: "detail-dialog-document-row",
  DETAIL_DIALOG_DOCUMENTS_HEADER: "detail-dialog-documents-header",
  DETAIL_DIALOG_FIELD_APPROVERS: "detail-dialog-field-approvers",
  DETAIL_DIALOG_FIELD_ASSIGNED_BY: "detail-dialog-field-assigned-by",
  DETAIL_DIALOG_FIELD_ASSIGNEES: "detail-dialog-field-assignees",
  DETAIL_DIALOG_FIELD_CC: "detail-dialog-field-cc",
  DETAIL_DIALOG_FIELD_DESCRIPTION: "detail-dialog-field-description",
  DETAIL_DIALOG_FIELD_DUE_DATE: "detail-dialog-field-due-date",
  DETAIL_DIALOG_FIELD_GENERIC_DISPLAY_VALUE: "detail-dialog-field-generic-display-value",
  DETAIL_DIALOG_FIELD_GENERIC_EDIT_BUTTON: "detail-dialog-field-generic-edit-button",
  DETAIL_DIALOG_FIELD_LEAD_TIME_BUTTON: "detail-dialog-field-lead-time-button",
  DETAIL_DIALOG_FIELD_LEAD_TIME_INPUT: "detail-dialog-field-lead-time-input",
  DETAIL_DIALOG_FIELD_NAME: "detail-dialog-field-name",
  DETAIL_DIALOG_FIELD_SUBMITTED_BY: "detail-dialog-field-submitted-by",
  DETAIL_DIALOG_FIELD_TAGS: "detail-dialog-field-tags",
  DETAIL_DIALOG_FORM_SECTION: "detail-dialog-form-section",
  DETAIL_DIALOG_FORM_SECTION_SIDEBAR_BUTTON: "detail-dialog-form-section-sidebar-button",
  DETAIL_DIALOG_HEADER_MENU_BUTTON: "detail-dialog-header-menu-button",
  DETAIL_DIALOG_DELETE_BUTTON: "detail-dialog-delete-button",
  DETAIL_DIALOG_MESSAGE_INPUT_IS_EMPTY: "detail-dialog-message-input-is-empty",
  DETAIL_DIALOG_MESSAGE_INPUT_WRAPPER: "detail-dialog-message-input-wrapper",
  DETAIL_DIALOG_MESSAGE_SEND_BUTTON: "detail-dialog-message-send-button",
  DETAIL_DIALOG_MESSAGE_UPLOAD_FILE_BUTTON: "detail-dialog-message-upload-file-button",
  DETAIL_DIALOG_REPORT_EXCEPTION_BUTTON: "detail-dialog-report-exception-button",
  DETAIL_DIALOG_SECONDARY_STATUS_TEXT: "detail-dialog-secondary-status-text",
  DETAIL_DIALOG_STATE_CHANGE_CURRENT_STEP_LABEL: "detail-dialog-state-change-current-step-label",
  DETAIL_DIALOG_STATE_CHANGE_WRAPPER: "detail-dialog-state-change-wrapper",
  DETAIL_DIALOG_STATUS_CHANGE_BUTTON: "detail-dialog-status-change-button",
  DETAIL_DIALOG_STATUS_CHIP: "detail-dialog-status-chip",
  DETAIL_DIALOG_STATUS_TEXT: "detail-dialog-status-text",
  DETAIL_DIALOG_UPLOAD_ROW_FILE_INPUT: "detail-dialog-upload-row-file-input",
  DETAIL_DIALOG_WORKFLOW_LINK: "detail-dialog-workflow-link",
  DETAIL_DIALOG_DOWN_BUTTON: "detail-dialog-down-button",
  DETAIL_DIALOG_UP_BUTTON: "detail-dialog-up-button",
  DETAIL_DIALOG_OPEN_LINE_ITEMS_TABLE_BUTTON: "detail-dialog--open-line-items-table-button",
  EMAIL_DOMAIN: "email-domain",
  FORGOT_PASSWORD_BUTTON: "forgot-password-button",

  INVITE_PEOPLE_HEADER_BUTTON: "invite-people-header-button",

  LINE_ITEMS_DIALOG_SUBMIT_BUTTON: "line-items-table-submit-button",
  LINE_ITEMS_DIALOG_ADD_LINE_BUTTON: "line-items-dialog-add-line-button",
  LINE_ITEMS_DIALOG_ADD_COLUMN_BUTTON: "line-items-dialog-add-column-button",

  LINKED_WORKFLOW_LINK: "linked-workflow-link",
  LINKED_WORKFLOW_PARENT_WORKFLOW_LINK: "linked-workflow-parent-workflow-link",

  NAME_TEMPLATE_ADD_FIELD_BUTTON: "name-template-add-field-button",
  NAME_TEMPLATE_FIELD_SELECT: "name-template-field-select",
  NAME_TEMPLATE_INPUT: "name-template-input",
  NAME_TEMPLATE_PREVIEW: "name-template-preview",
  NAME_TEMPLATE_SUFFIX_INPUT: "name-template-suffix-input",
  NAME_TEMPLATE_SWITCH: "name-template-switch",

  TEMPLATE_CARD_OPEN_BUTTON: "template-card-open-button",

  PARTY_SELECT_BUTTON: "party-select-button",
  PARTY_SELECT_CREATE_BUTTON: "party-select-create-button",
  PARTY_SELECT_INPUT: "party-select-input",
  PARTY_SELECT_OPTION: "party-select-option",
  PARTY_SELECT_TAB_ALL: "party-select-tab-all",
  PARTY_SELECT_TAB_FIELD_INSTANCE: "party-select-tab-field-instance",
  PARTY_SELECT_TAB_TEAM: "party-select-tab-team",
  PARTY_SELECT_TAB_USER: "party-select-tab-user",
  PARTY_SELECT_TAB_ROLE: "party-select-tab-role",

  PEOPLE_TABLE_SCIM_SYNC_LOG_BUTTON: "people-table-scim-sync-log-button",
  PEOPLE_TABLE_SYNC_LOG_DIALOG: "people-table-sync-log-dialog",
  PEOPLE_TABLE_EDIT_BUTTON: "people-table-edit-button",
  PEOPLE_TABLE_DELETE_BUTTON: "people-table-delete-button",

  PHONE_INPUT_COUNTRY_BUTTON: "phone-input-country-button",
  PHONE_INPUT_COUNTRY_FLAG: "phone-input-country-flag",
  PHONE_INPUT_INPUT: "phone-input-input",

  PROVIDE_MISSING_INPUT_FIELD_FORM_DIALOG: "provide-missing-input-field-form-dialog",
  REGRELLO_CARD: "regrello-card",
  REGRELLO_LOGO: "regrello-logo",
  RESEND_INVITE_INPUT: "resend-invite-input",
  RESEND_EMAIL_INVITE_BUTTON: "resend-email-invite-button",
  RESEND_INVITE_SUBMIT_BUTTON: "resend-invite-submit-button",
  SIMPLE_NAME_DESCRIPTION_FORM_DIALOG_FIELD_DESCRIPTION: "simple-name-description-form-dialog-field-description",
  SIMPLE_NAME_DESCRIPTION_FORM_DIALOG_FIELD_NAME: "simple-name-description-form-dialog-field-name",
  STAGE_NAME: "stage-name",
  TABLE: "table",
  TABLE_ROW: "table-row",
  TABLE_CELL: "table-cell",
  TABLE_FILTER_ONLY_BUTTON: "table-filter-only-button",
  TABLE_SEARCH_INPUT: "table-search-input",
  TABLE_SORT_ICON_ASC: "table-sort-icon-asc",
  TABLE_SORT_ICON_DESC: "table-sort-icon-desc",
  TAGS_SETTINGS_ADD_TAG_BUTTON: "tags-settings-add-tag-button",
  TAGS_SETTINGS_DELETE_TAG_BUTTON: "tags-settings-delete-tag-button",
  TAGS_SETTINGS_EDIT_TAG_BUTTON: "tags-settings-edit-tag-button",
  TEMPLATE_CARD: "template-card",
  TEMPLATE_CARD_NAME: "template-card-name",
  TEMPLATE_DELETE_BUTTON: "template-delete-button",
  TEMPLATE_EDIT_NAME_MENU_ITEM: "template-edit-name-menu-item",
  TEMPLATE_EDIT_STAGE_DIALOG: "template-edit-stage-dialog",
  TEMPLATE_LINK_BUTTON: "template-link-button",
  TEMPLATE_MORE_BUTTON: "template-more-button",
  TEMPLATE_SAVE_BUTTON: "template-save-button",
  TEMPLATE_SHARE_BUTTON: "template-share-button",
  TEMPLATE_STAR_MENU_ITEM: "template-star-menu-item",
  TOAST_MESSAGE: "toast-message",
  TOAST_MESSAGE_CLOSE_BUTTON: "toast-message-close-button",
  USE_TEMPLATE_BUTTON: "use-template-button",
  USE_TEMPLATE_DIALOG: "use-template-dialog",
  USE_TEMPLATE_DIALOG_ADD_MULTILINE_CHECKBOX: "use-template-dialog-add-multiline-checkbox",
  USE_TEMPLATE_DIALOG_EDIT_TABLE_BUTTON: "use-template-dialog-edit-table-button",

  SELECT_AUTOMATED_ACTION_ITEM_DIALOG: "select-automation-action-item-dialog",
  SELECT_AUTOMATED_ACTION_ITEM_DIALOG_FIELD_SELECT_TEMPLATE:
    "select-automation-action-item-dialog-field-select-template",

  SELECT_FIELD_DIALOG: "select-field-dialog",
  SELECT_FIELD_DIALOG_CHECKBOX: "select-field-dialog-checkbox",

  SEARCH_DIALOG_CONTENT: "search-dialog-content",
  SEARCH_DIALOG_DELETE_FILTER_BUTTON: "search-dialog-delete-filter-button",
  SEARCH_DIALOG_INPUT: "search-dialog-input",
  SEARCH_DIALOG_NO_RESULTS: "search-dialog-no-results",
  SEARCH_DIALOG_RESULT: "search-dialog-result",
  SEARCH_DIALOG_RESULT_SUBJECT: "search-dialog-result-subject",
  SEARCH_DIALOG_RESULT_SNIPPET_LABEL: "search-dialog-result-snippet-label",
  SEARCH_DIALOG_RESULT_SNIPPET_VALUE: "search-dialog-result-snippet-value",
  SEARCH_DIALOG_RESULTS: "search-dialog-results",
  SEARCH_DIALOG_SECTION_TAGS: "search-dialog-section-tags",

  SETTINGS_ADD_USER_BUTTON: "settings-add-user-button",
  SETTINGS_ADD_ROLE_BUTTON: "settings-add-role-button",

  SIDEBAR_CREATE_MENU_OPTION_PEOPLE: "sidebar-create-menu-option-people",
  SIDEBAR_CREATE_MENU_OPTION_TASK: "sidebar-create-menu-option-task",
  SIDEBAR_CREATE_MENU_OPTION_TEAM: "sidebar-create-menu-option-team",
  SIDEBAR_CREATE_MENU_OPTION_ROLE: "sidebar-create-menu-option-role",
  SIDEBAR_CREATE_MENU_OPTION_WORKFLOW: "sidebar-create-menu-option-workflow",
  SIDEBAR_CREATE_MENU_OPTION_WORKFLOW_TEMPLATE: "sidebar-create-menu-option-workflow-template",
  SIDEBAR_CREATE_MENU_BUTTON: "sidebar-create-menu-button",
  SIDEBAR_COLLAPSE: "sidebar-collapse",
  SIDEBAR_LINK_ADMIN: "sidebar-link-admin",
  SIDEBAR_LINK_ASSIGNED_TO_ME: "sidebar-link-assigned-to-me",
  SIDEBAR_LINK_BLUEPRINTS: "sidebar-link-blueprints",
  SIDEBAR_LINK_CCD_TASKS: "sidebar-link-ccd-tasks",
  SIDEBAR_LINK_COMPANY_TEMPLATES: "sidebar-link-company-templates",
  SIDEBAR_LINK_CUSTOM_FIELDS: "sidebar-link-custom-fields",
  SIDEBAR_LINK_DOCUMENTS: "sidebar-link-documents",
  SIDEBAR_LINK_HOME: "sidebar-link-home",
  SIDEBAR_LINK_MY_TEMPLATES: "sidebar-link-my-templates",
  SIDEBAR_LINK_MY_WORK: "sidebar-link-my-work",
  SIDEBAR_LINK_PEOPLE: "sidebar-link-people",
  SIDEBAR_LINK_SPECTRUM_FORMS: "sidebar-link-spectrum-forms",
  SIDEBAR_LINK_STARRED_VIEW: "sidebar-link-starred-view",
  SIDEBAR_LINK_STARRED_V2: "sidebar-link-starred-v2",
  SIDEBAR_LINK_TAGS: "sidebar-link-tags",
  SIDEBAR_LINK_TASKS: "sidebar-link-tasks",
  SIDEBAR_LINK_TEMPLATES: "sidebar-link-templates",
  SIDEBAR_LINK_TENANT_SWITCH: "sidebar-link-tenant-switch",
  SIDEBAR_LOGO: "sidebar-logo",
  SIDEBAR_LOGOUT: "sidebar-logout",
  SIDEBAR_PROFILE_MENU_BUTTON: "sidebar-profile-menu-button",
  SIDEBAR_SEARCH_BUTTON: "sidebar-search-button",
  SIDEBAR_STARRED_VIEW_UNSTAR_BUTTON: "sidebar-starred-view-unstar-button",
  SIDEBAR_TENANT_MENU_BUTTON: "sidebar-tenant-menu-button",
  SIDEBAR_TENANT_MENU_SIGN_OUT_BUTTON: "sidebar-tenant-menu-sign-out-button",

  SIGN_IN_BUTTON: "sign-in-button",
  SIGN_IN_WITH_SSO_BUTTON: "sign-in-with-sso-button",

  SPECTRUM_FORM_CARD: "spectrum-form-card",
  SPECTRUM_FORM_CARD_DELETE_BUTTON: "spectrum-form-card-delete-button",
  SPECTRUM_FORM_CARD_PUBLISH_BUTTON: "spectrum-form-card-publish-button",
  SPECTRUM_FORM_CARD_SAVE_BUTTON: "spectrum-form-card-save-button",
  SPECTRUM_FORM_CARD_PRIMARY_BUTTON: "spectrum-form-card-primary-button",
  SPECTRUM_FORM_FIELD_WRAPPER: "spectrum-form-field-wrapper",
  SPECTRUM_FORM_SELECT: "spectrum-form-select",

  START_DEPENDENCY_TASK_START_AFTER_TASK_LABEL: "start-dependency-task-start-after-task-label",
  START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_SELECT: "start-dependency-task-start-after-task-value-select",
  START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_MULTI_SELECT:
    "start-dependency-task-start-after-task-value-multi-select",
  START_DEPENDENCY_TASK_START_ON_DATE_LABEL: "start-dependency-task-start-on-date-label",

  SCHEDULE_TIME_SELECT: "schedule-time-select",
  SPLITTER_FIELD_SELECT: "splitter-field-select",
  START_DEPENDENCY_START_CONDITION_FIELD_SELECT: "start-dependency-start-condition-field-select",
  START_DEPENDENCY_START_AFTER_STAGE_SELECT: "start-dependency-start-after-stage-select",
  START_DEPENDENCY_FIELD_PROPERTY: "start-dependency-field-property",
  START_DEPENDENCY_OPERATOR_SELECT: "start-dependency-operator-select",
  START_DEPENDENCY_ADD_START_CONDITION_BUTTON: "start-dependency-add-start-condition-button",
  START_DEPENDENCY_CONDITION_CONNECTIVE_SELECT: "start-dependency-condition-connective-select",
  STAGE_START_INFO_BANNER: "stage-start-info-banner",

  SUBMISSION_PDF_DOWNLOAD_BUTTON: "submission-pdf-download-button",
  SUBMISSION_PDF_GENERATION_INDICATOR: "submission-pdf-generation-indicator",
  SUBMISSION_PDF_TOGGLE: "submission-pdf-toggle",

  SCHEDULE_TIME_ON_DATE_FIELD: "schedule-time-on-date-field",
  SCHEDULE_TIME_DAYS_TO_COMPLETE_FIELD: "schedule-time-days-to-complete-field",
  SCHEDULE_TIME_TIME_TO_COMPLETE_FIELD: "schedule-time-time-to-complete-field",
  SCHEDULE_TIME_FROM_CUSTOM_FIELD_SELECT: "schedule-time-from-custom-field-select",

  SYNCED_OBJECTS_COLUMN_VISIBILITY_SWITCH: "synced-objects-column-visibility-switch",
  SYNCED_OBJECTS_MULTI_SELECT_SWITCH: "synced-objects-multi-select-switch",
  SYNCED_OBJECTS_NEXT_BUTTON: "synced-obejcts-next-button",
  SYNCED_OBJECTS_PRIMARY_KEY_SWITCH: "synced-objects-primary-key-switch",
  SYNCED_OBJECTS_PROJECTION_BUTTON: "synced-objects-projection-button",
  SYNCED_OBJECTS_SELECT_BUTTON: "synced-objects-select-button",
  SYNCED_OBJECTS_SELECT_DIALOG_DONE_BUTTON: "synced-objects-select-dialog-done-button",
  SYNCED_OBJECTS_SELECTED_VALUES: "synced-objects-selected-values",

  TABLE_COLUMN_TASK: "table-column-task",
  TABLE_MANAGE_COLUMN_MENU: "table-manage-column_menu",
  TABLE_MANAGE_COLUMN_OPTION: "table-manage-column-option",
  TABLE_MANAGE_COLUMN_CANCEL: "table-manage-column-cancel",
  TABLE_MANAGE_COLUMN_RESET: "table-manage-column-reset",
  TABLE_MANAGE_COLUMN_SAVE: "table-manage-column-save",

  TAG_VIEW_COLUMNS_BUTTON: "tag-view-columns-button",
  TAG_VIEW_FILTERS_BUTTON: "tag-view-filters-button",
  TAG_VIEW_OVERVIEW_TAB_STAT_EXCEPTION: "tag-view-overview-tab-stat-exception",
  TAG_VIEW_OVERVIEW_TAB_STAT_ON_TRACK: "tag-view-overview-tab-stat-on-track",
  TAG_VIEW_OVERVIEW_TAB_STAT_OVERDUE: "tag-view-overview-tab-stat-overdue",
  TAG_VIEW_OVERVIEW_TAB_TEMPLATES_SELECTOR: "tag-view-overview-tab-templates-selector",
  TAG_VIEW_STAR_BUTTON: "tag-view-star-button",
  TAG_VIEW_TABLE_DOCUMENT_ICON: "action-item-table-document-icon",
  TAG_VIEW_TABLE_STATUS_CELL: "tag-view-table-status-cell",
  TAG_VIEW_TABLE_SUBJECT_CELL: "tag-view-table-subject-cell",
  TAG_VIEW_WORKFLOW_STATUS_SELECTOR: "tag-view-workflow-status-selector",

  TEAMS_TABLE_EDIT_BUTTON: "teams-table-edit-button",
  TEAMS_TABLE_DELETE_BUTTON: "teams-table-delete-button",

  TENANT_BANNER: "tenant-banner",

  TOGGLE_TEXT_CLAMP_BUTTON: "toggle-text-clamp-button",

  UNAUTHENTICATED_EMAIL_SENT_PAGE_ROOT: "unauthenticated-email-sent-page-root",
  UNAUTHENTICATED_EMAIL_REQUEST_PAGE_ROOT: "unauthenticated-email-request-root",

  UPDATE_TENANT_DISPLAY_NAME_FORM_FIELD_NAME: "update-tenant-display-name-form-field-name",
  UPDATE_TENANT_DISPLAY_NAME_MENU_ITEM: "update-tenant-display-name-menu-item",

  VIEW_BLUEPRINTS_DIALOG: "view-blueprints-dialog",

  WORKFLOW_ABOUT_BLUEPRINT_LINK: "workflow-about-blueprint-link",
  WORKFLOW_ABOUT_DATE_CREATED: "workflow-about-date-created",
  WORKFLOW_ABOUT_DATE_MODIFIED: "workflow-about-date-modified",
  WORKFLOW_ABOUT_DESCRIPTION: "workflow-about-description",
  WORKFLOW_ABOUT_END_DATE: "workflow-about-end-date",
  WORKFLOW_ABOUT_MODIFIED_BY: "workflow-about-modified-by",
  WORKFLOW_ABOUT_NAME: "workflow-about-name",
  WORKFLOW_ABOUT_OWNER: "workflow-about-owner",
  WORKFLOW_ABOUT_START_DATE: "workflow-about-start-date",
  WORKFLOW_ABOUT_TAGS: "workflow-about-tags",
  WORKFLOW_ABOUT_PLANNED_END_DATE: "workflow-about-planned-end-date",
  WORKFLOW_ACTION_ITEM_CARD_NAME: "action-item-name",
  WORKFLOW_ADD_ACTION_ITEM_DIALOG: "workflow-add-action-item-dialog",
  WORKFLOW_ADD_ACTION_TO_STAGE_BUTTON: "workflow-add-action-to-stage-button",
  WORKFLOW_ADD_ACTION_TO_STAGE_MORE_BUTTON: "workflow-add-action-to-stage-more-button",
  WORKFLOW_ADD_AUTOMATED_ACTION_ITEM_TO_STAGE_MENU_ITEM: "workflow-add-automation-action-item-to-stage-menu-item",
  WORKFLOW_ADD_APPROVAL_ACTION_ITEM_TO_STAGE_MENU_ITEM: "workflow-add-approval-action-item-to-stage-menu-item",
  WORKFLOW_ADD_STAGE_BUTTON: "workflow-add-stage-button",
  WORKFLOW_ADD_STAGE_DIALOG: "add-stage-dialog",
  WORKFLOW_APPROVAL_TASK: "workflow-approval-task",

  WORKFLOW_CARD_ACTION_BAR_BUTTON_START: "workflow-card-action-bar-button-start",
  WORKFLOW_CARD_ACTION_BAR_BUTTON_EDIT: "workflow-card-action-bar-button-edit",
  WORKFLOW_CARD_ACTION_BAR_BUTTON_DUPLICATE: "workflow-card-action-bar-button-duplicate",
  WORKFLOW_CARD_ACTION_BAR_BUTTON_DELETE: "workflow-card-action-bar-button-delete",

  WORKFLOW_ACCESS_TAB_SECTION_BLUEPRINT_ACCESS: "workflow-access-tab-section-blueprint-access",
  WORKFLOW_COMPLETED_PILL: "workflow-completed-pill",
  WORKFLOW_ENDED_PILL: "workflow-ended-pill",
  WORKFLOW_DATA_FIELD_KEY: "workflow-data-field-key",
  WORKFLOW_DATA_FIELD_VALUE: "workflow-data-field-value",
  WORKFLOW_DATA_STAGE_FIELDS: "workflow-data-stage-fields",
  WORKFLOW_DATA_WORKFLOW_FIELDS: "workflow-data-workflow-fields",
  WORKFLOW_DELETE_BUTTON: "workflow-delete-button",
  WORKFLOW_DELETE_STAGE_BUTTON: "workflow-delete-stage-button",
  WORKFLOW_FIELD_INSTANCE_DRAG_HANDLE: "workflow-field-instance-drag-handle",
  WORKFLOW_FORM_DIALOG_FIELD_DESCRIPTION: "workflow-form-dialog-field-description",
  WORKFLOW_FORM_DIALOG_FIELD_NAME: "workflow-form-dialog-field-name",
  WORKFLOW_FORM_DIALOG_FIELD_TAGS: "workflow-form-dialog-field-tags",
  WORKFLOW_FORM_DIALOG_FIELD_COLLABORATORS: "workflow-form-dialog-collaborators",
  WORKFLOW_FORM_DIALOG_FIELD_OWNER: "workflow-form-dialog-owner",
  WORKFLOW_EDIT_CUSTOM_FIELDS_BUTTON: "workflow-edit-custom-fields-button",
  WORKFLOW_EDIT_DESCRIPTION_DIALOG: "workflow-edit-description-dialog",
  WORKFLOW_EDIT_DESCRIPTION_DIALOG_INPUT: "workflow-edit-description-dialog-input",
  WORKFLOW_EDIT_DESCRIPTION_DIALOG_SUBMIT_BUTTON: "workflow-edit-description-dialog-submit-button",
  WORKFLOW_EDIT_DETAILS_BUTTON: "workflow-edit-details-button",
  WORKFLOW_EDIT_FIELDS_BUTTON: "workflow-edit-fields-button",
  WORKFLOW_EDIT_NAME_BUTTON: "workflow-edit-name-button",
  WORKFLOW_EDIT_OWNER_BUTTON: "workflow-edit-owner-button",
  WORKFLOW_EDIT_NAME_DIALOG: "workflow-edit-name-dialog",
  WORKFLOW_EDIT_STAGE_BUTTON: "workflow-edit-stage-button",
  WORKFLOW_EDIT_STAGE_DIALOG: "workflow-edit-stage-dialog",
  WORKFLOW_EDIT_COLLABORATORS_BUTTON: "workflow-edit-collaborators-button",
  WORKFLOW_EDIT_COLLABORATORS_DIALOG: "workflow-edit-collaborators-dialog",
  WORKFLOW_EDIT_COLLABORATORS_DIALOG_SUBMIT_BUTTON: "workflow-edit-collaborators-dialog-submit-button",
  WORKFLOW_GET_LINK_BUTTON: "workflow-get-link-button",
  WORKFLOW_HEADER_MENU_DELETE_BUTTON: "workflow-header-menu-delete-button",
  WORKFLOW_HEADER_STAR_BUTTON: "workflow-header-star-button",
  WORKFLOW_LABELED_VALUE_ICON: "workflow-labeled-value-icon",
  WORKFLOW_LABELED_VALUE_LABEL: "workflow-labeled-value-label",
  WORKFLOW_LABELED_VALUE_VALUE: "workflow-labeled-value-value",
  WORKFLOW_NAME_CELL: "workflow-name-cell",

  WORKFLOW_STAGE_CARD_ACTION_BAR: "workflow-stage-card-action-bar",
  WORKFLOW_STAGE_CARD_ACTION_BAR_INTERACTION_TARGET: "workflow-stage-card-action-bar-interaction-target",
  WORKFLOW_STAGE_CARD_ADD_BUTTON_APPROVAL_TASK: "workflow-stage-card-add-button-approval-task",
  WORKFLOW_STAGE_CARD_ADD_BUTTON_AUTOMATED_TASK: "workflow-stage-card-add-button-automated-task",
  WORKFLOW_STAGE_CARD_ADD_BUTTON_LINKED_WORKFLOW_TASK: "workflow-stage-card-add-button-linked-workflow-task",
  WORKFLOW_STAGE_CARD_ADD_BUTTON_STAGE: "workflow-stage-card-add-button-stage",
  WORKFLOW_STAGE_CARD_ADD_BUTTON_TASK: "workflow-stage-card-add-button-task",
  WORKFLOW_STAGE_CARD_DRAG_HANDLE: "workflow-stage-card-drag-handle",
  WORKFLOW_STAGE_CARD_INFO_BUTTON: "workflow-stage-card-info-button",
  WORKFLOW_STAGE_CARD_INFO_POPOVER: "workflow-stage-card-info-popover",
  WORKFLOW_STAGE_CARD_NAME: "workflow-stage-card-name",
  WORKFLOW_STAGE_CARD_START_DATE_LABEL: "workflow-stage-card-start-date-label",
  WORKFLOW_STAGE_CARD_START_DATE_VALUE: "workflow-stage-card-start-date-value",
  WORKFLOW_STAGE_CARD_STATUS_CHIP: "workflow-stage-card-status-chip",
  WORKFLOW_STAGE_CARD_STATUS_TARGET: "workflow-stage-card-status-target",
  WORKFLOW_STAGE_CARD_STATUS: "workflow-stage-card-status",
  WORKFLOW_STAGE_CARD: "workflow-stage-card",

  WORKFLOW_END_BUTTON: "workflow-end-button",
  WORKFLOW_PAUSE_BUTTON: "workflow-pause-button",
  WORKFLOW_RESUME_BUTTON: "workflow-resume-button",
  WORKFLOW_START_BUTTON: "workflow-start-button",
  WORKFLOW_START_STAGE_BUTTON: "workflow-start-stage-button",
  WORKFLOW_STATUS_CHIP: "workflow-status-chip",
  WORKFLOW_TABLE_EXPORT_BUTTON: "workflow-table-export-button",

  WORKFLOW_TASK: "workflow-task",
  WORKFLOW_TASK_CARD_ACTION_BAR: "workflow-task-card-action-bar",
  WORKFLOW_TASK_CARD_ACTION_BAR_INTERACTION_TARGET: "workflow-task-card-action-bar-interaction-target",
  WORKFLOW_TASK_CARD_ASSIGNEES: "workflow-task-card-assignees",
  WORKFLOW_TASK_CARD_CHILD_TASKS: "workflow-task-card-child-tasks",
  WORKFLOW_TASK_CARD_DRAG_HANDLE: "workflow-task-card-drag-handle",
  WORKFLOW_TASK_CARD_DUE_DATE: "workflow-task-card-due-date",
  WORKFLOW_TASK_CARD_END_DATE: "workflow-task-card-end-date",
  WORKFLOW_TASK_CARD_END_ICON: "workflow-task-card-end-icon",
  WORKFLOW_TASK_CARD_INDICATOR_DOCUMENTS: "workflow-task-card-indicator-documents",
  WORKFLOW_TASK_CARD_INDICATOR_ERROR: "workflow-task-card-indicator-error",
  WORKFLOW_TASK_CARD_INDICATOR_UNREAD_MESSAGES: "workflow-task-card-indicator-unread-messages",
  WORKFLOW_TASK_CARD_NAME: "workflow-task-card-name",
  WORKFLOW_TASK_CARD_PERCENT_COMPLETE: "workflow-task-card-percent-complete",
  WORKFLOW_TASK_CARD_START_DATE: "workflow-task-card-start-date",
  WORKFLOW_TASK_CARD: "workflow-task-card",

  WORKFLOW_TASK_TEMPLATE: "workflow-task-template",
  WORKFLOW_TEMPLATE_CSV_DOWNLOAD_BUTTON: "workflow-template-csv-download-button",
  WORKFLOW_TEMPLATE_CSV_UPLOAD_BUTTON: "workflow-template-csv-upload-button",
  WORKFLOW_TEMPLATE_EDIT_DESCRIPTION_DIALOG: "workflow-template-edit-description-dialog",
  WORKFLOW_TEMPLATE_EDIT_NAME_DIALOG: "workflow-template-edit-name-dialog",
  WORKFLOW_TEMPLATE_EXPORT_BUTTON: "workflow-template-export-button",

  CHANGE_ASSIGNED_TO_BUTTON: "change-assigned-to-button",
  CHANGE_CC_BUTTON: "change-cc-button",
  CHANGE_DUE_DATE_BUTTON: "change-due-date-button",

  USER_MENU_BUTTON: "user-menu-button",
  USER_MENU_SIGN_OUT_BUTTON: "user-menu-sign-out-button",

  GRANULAR_ACCESS_TOKEN: "granular-access-token",

  WORKSPACE_SETTINGS_EDIT_BUTTON: "workspace-settings-edit-button",
  WORKSPACE_SETTINGS_FORM_LOGO_FILE_INPUT: "workspace-settings-logo-file-input",
  WORKSPACE_SETTINGS_FORM_FOREGROUND_COLOR_INPUT: "workspace-settings-foreground-color-input",
  WORKSPACE_SETTINGS_FORM_BACKGROUND_COLOR_INPUT: "workspace-settings-background-color-input",
  WORKSPACE_SETTINGS_FORM_SIDEBAR_RESET_BUTTON: "workspace-settings-sidebar-reset-button",

  ROLES_TABLE: "roles-table",
};
