// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type RoleFields = { id: number, name: string, isSystem: boolean, scimId?: string | null };

export const RoleFields = gql`
    fragment RoleFields on Role {
  id
  name
  isSystem
  scimId
}
    `;