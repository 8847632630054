import { RegrelloButtonProps, RegrelloControlWithLabel, RegrelloSwitch, RegrelloSwitchProps } from "@regrello/ui-core";
import React, { useId } from "react";
import { useMount } from "react-use";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";

export interface RegrelloFormFieldSwitchProps
  // (clewis): Switches don't work well with the 'selfContainedForm' option, because they don't
  // really consist of a key/value pair. Can maybe revisit in the future (e.g., if we want to show
  // "Label: True/False").
  //
  // (clewis): Switches also don't work well with the 'labelWidth' option, because they don't use
  // the same left-aligned label as other form fields.
  extends Omit<RegrelloFormFieldBaseProps<boolean>, "selfContainedForm">,
    Pick<RegrelloSwitchProps, "checked" | "defaultChecked" | "onBlur" | "ref">,
    Pick<RegrelloButtonProps, "onClick"> {
  onChange: (nextIsChecked: boolean) => void;
  secondaryLabel?: JSX.Element | string;
}

export const RegrelloFormFieldSwitch = React.memo<RegrelloFormFieldSwitchProps>(
  React.forwardRef(function RegrelloFormFieldSwitchFn(
    {
      className,
      dataTestId,
      error,
      helperText,
      isDefaultMarginsOmitted,
      isEmphasized: _isEmphasized, // (clewis): Extract from SwitchProps so we don't get HTML invalid-attribute errors.
      isRequiredAsteriskShown,
      labelWidth,
      label,
      onChange,
      secondaryLabel,
      ...switchProps
    },
    ref,
  ) {
    const id = useId();
    // (krashanoff): If a default state is provided, fire the onChange handler
    // on mount to update the form state to match.
    useMount(() => {
      if (switchProps.defaultChecked != null) {
        onChange(switchProps.defaultChecked);
      }
    });

    return (
      <RegrelloFormFieldLayout
        className={className}
        dataTestId={dataTestId}
        error={error}
        helperText={helperText}
        htmlFor={id}
        isDefaultMarginsOmitted={isDefaultMarginsOmitted}
        isRequiredAsteriskShown={isRequiredAsteriskShown}
        label={label}
        labelWidth={labelWidth}
      >
        <div
          // (clewis): Vertically center the baselines of the switch text with the baseline of the Label.
          className="mt-2.25"
        >
          <RegrelloControlWithLabel
            control={<RegrelloSwitch {...switchProps} ref={ref} onCheckedChange={onChange} />}
            id={id}
            label={secondaryLabel}
          />
        </div>
      </RegrelloFormFieldLayout>
    );
  }),
);
