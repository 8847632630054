import {
  clsx,
  ComparatorResult,
  EMPTY_ARRAY,
  EMPTY_STRING,
  isArraysEqual,
  noop,
  stringComparator,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  CreateFieldInstanceValueInputs,
  DocumentFields,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceMultiValueDocumentFields,
  FieldInstanceValueInputType,
  PropertyDataType,
  PropertyTypeFields,
  UpdateFieldInstanceValueInputs,
  UpdateStartingConditionsInputs,
  ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloButton, RegrelloChipList, RegrelloIcon, RegrelloPopover, RegrelloTypography } from "@regrello/ui-core";
import { Document, DocumentsCount, HelperTextAttachOneOrMoreDocuments } from "@regrello/ui-strings";
import first from "lodash/first";
import React, { ReactNode } from "react";
import { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { StageStartConditionOperatorConfig } from "./types/StageStartConditionOperator";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { FieldInstanceBaseFields } from "../../../../types";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionDocumentValue,
  getRegrelloFilterDefinitionDocumentValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloDocumentChip } from "../../documentChip/RegrelloDocumentChip";
import { RegrelloControlledFormFieldDocument } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'document' field is invalid";
const ERROR_INVALID_FORM_VALUE =
  "Provided 'document'-field form value does not seeem to be a valid DocumentFields object";
const ERROR_INVALID_VALUE_COUNT = "Provided 'document' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE =
  "Provided 'document' field instance value must have type 'FieldInstanceMultiValueDocument'";
const WARNING_START_CONDITIONS_UNSUPPORTED =
  "'operator' must not be defined as 'document' fields are not yet supported in start conditions";

// (anthony): This was increased from 10 to 50 so as not to limit our users. Chosen by Amy and
// Garrett.
const MAX_DOCUMENTS_ATTACHED_TO_FIELD_COUNT = 50;

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.DOCUMENT_ID;
}

function canProcessField(field: FieldFields): boolean {
  return field.allowedValues.length === 0 && canProcessPropertyDataType(field.propertyType.dataType);
}

type DocumentFieldPluginFrontendValue = DocumentFields[];
type DocumentFieldPluginType = CustomFieldPlugin<DocumentFieldPluginFrontendValue>;

const renderDisplayValue: DocumentFieldPluginType["renderDisplayValue"] = (fieldInstance, options = {}) => {
  const { value } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);

  if (value == null || value.length === 0) {
    return null;
  }

  if (value.length === 1) {
    return renderDocumentChip(value[0]);
  }

  // Render all chips in popover accessible via a "[N] documents" button.
  if (options?.context === "table" || options?.context === "tableV2") {
    return (
      <RegrelloPopover
        content={
          <div className="flex flex-col min-h-0">
            <ul className="flex-1 flex flex-col gap-2 p-4 overflow-y-auto">
              {value.map((document) => (
                <li key={document.id}>{renderDocumentChip(document)}</li>
              ))}
            </ul>
            <div className="flex-none bg-backgroundSoft rounded-b border-t p-2 text-center">
              <RegrelloTypography className="text-textMuted" variant="body-xs">
                {DocumentsCount(value.length)}
              </RegrelloTypography>
            </div>
          </div>
        }
        contentProps={{
          className: clsx("p-0", "w-auto", "max-w-100", "flex", "flex-col"),
        }}
      >
        <RegrelloButton endIcon="expand-more" intent="primary" size="x-small" variant="ghost">
          {DocumentsCount(value.length)}
        </RegrelloButton>
      </RegrelloPopover>
    );
  }

  // Render all chips in a line-wrappable list.
  return (
    <RegrelloChipList>
      {value.map((document) => (
        <RegrelloDocumentChip key={document.id} document={document} />
      ))}
    </RegrelloChipList>
  );
};

const renderDocumentChip = (document: DocumentFields) => {
  return <RegrelloDocumentChip document={document} />;
};

const sortComparator: DocumentFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return DocumentFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = String(first(fieldPlugin.getValueForFrontend(fieldInstance1) as DocumentFields[])?.name ?? "");
  const value2 = String(first(fieldPlugin.getValueForFrontend(fieldInstance2) as DocumentFields[])?.name ?? "");

  return stringComparator(value1, value2);
};

/**
 * Describes a custom field that holds a document (in the form of an attachment chip).
 */
export const DocumentFieldPlugin: DocumentFieldPluginType = {
  uri: "com.regrello.customField.document",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.DOCUMENT_ID)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    return {
      fieldId: field.id,
      documentMultiValue: value != null && value.length > 0 ? value.map((document) => document.id) : undefined,
      inputType,
      displayOrder,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return Document;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionDocumentValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    const value = filter.value ?? EMPTY_STRING;
    return getRegrelloFilterDefinitionDocumentValue(value);
  },

  getEmptyValueForFrontend: (): DocumentFields[] => {
    return EMPTY_ARRAY;
  },

  getIconName: () => {
    return "file-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null || value.length === 0) {
      return undefined;
    }
    return value.map((document) => document.name).join(", ");
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.DOCUMENT_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },
  // (zstanik): Document fields are not supported in starting conditions yet.
  getStageStartConditionOperators: (): StageStartConditionOperatorConfig[] => {
    return EMPTY_ARRAY;
  },

  // (zstanik): Document fields are not supported in starting conditions yet.
  getUpdateStartingConditionsInputsFromFormValues: (
    _leftFieldInstance: FieldInstanceFields,
    _value: unknown,
    _operatorType: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    return undefined;
  },

  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    const value = fieldInstance.values[0] as FieldInstanceMultiValueDocumentFields;

    return [
      {
        inputType: FieldInstanceValueInputType.REQUESTED,
        id: value.id,
        documentMultiValue: value.documentMultiValue.map((val) => val.id),
      },
    ];
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): DocumentFields[] => {
    const multiDocument = extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceMultiValueDocument",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => undefined,
      getterIfValue: (fieldInstanceValue) => fieldInstanceValue.documentMultiValue ?? undefined,
    });
    return multiDocument != null ? multiDocument : EMPTY_ARRAY;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value.length === 0;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    if (
      frontendFieldInstance.value.length === 0 &&
      (proposedChange.documentMultiValue == null || proposedChange.documentMultiValue.length === 0)
    ) {
      return true;
    }

    if (
      frontendFieldInstance.value.length === 0 ||
      proposedChange.documentMultiValue == null ||
      proposedChange.documentMultiValue.length === 0
    ) {
      return false;
    }

    return isArraysEqual(
      frontendFieldInstance.value.map((document) => document.id),
      proposedChange.documentMultiValue,
      (a, b) => a === b,
    );
  },

  isMultiValued: (): boolean => {
    return true;
  },

  renderDisplayValue: renderDisplayValue,

  // eslint-disable-next-line react/display-name
  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    // (zstanik): Document type fields aren't supported in start conditions, and it's unclear if
    // they will be in the future.
    const { operator } = props;
    if (operator != null) {
      console.warn(WARNING_START_CONDITIONS_UNSUPPORTED, { field, operator });
      return undefined;
    }

    return (
      <RegrelloControlledFormFieldDocument
        {...props}
        key={props.controllerProps.name}
        allowedFileTypes={props.allowedFileTypes}
        allowPasteLink={props.isDocumentLinkDisallowed == null || !props.isDocumentLinkDisallowed}
        corruptVersionIds={props.corruptDocumentIds}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        helperText={HelperTextAttachOneOrMoreDocuments}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
        maxDocumentsCount={MAX_DOCUMENTS_ATTACHED_TO_FIELD_COUNT}
        onUploadFinish={props.onChangeFinish ?? noop}
        onUploadStart={props.onChangeStart ?? noop}
        size="small"
      />
    );
  },

  renderIcon: (props): React.ReactChild => {
    return <RegrelloIcon {...props} iconName="file-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  shouldDisplayMultiValuedToggle: () => false,

  sortComparator,
};

interface FrontendDocumentFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: DocumentFields[];
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendDocumentFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceMultiValueDocument",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: EMPTY_ARRAY,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceMultiValueDocument != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceMultiValueDocument.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceMultiValueDocument?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceMultiValueDocument?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceMultiValueDocument?.id
        : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceMultiValueDocument?.inputType
        : undefined,
      value: fieldInstanceValue.documentMultiValue != null ? fieldInstanceValue.documentMultiValue : EMPTY_ARRAY,
    }),
  });
}

function isValueValid(value: unknown): value is DocumentFieldPluginFrontendValue | null {
  return (
    value == null ||
    (Array.isArray(value) && value.length === 0) ||
    (Array.isArray(value) &&
      value.every(
        (element) =>
          typeof element.id === "number" &&
          typeof element.name === "string" &&
          typeof element.documentType === "object" &&
          typeof element.currentVersion === "object" &&
          Array.isArray(element.tags),
      ))
  );
}
