// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
export type SpectrumFieldVersionFields = { id: number, uuid: string, createdAt: string, name: string, helperText: string, description: string, internalName: string, spectrumField: SpectrumFieldFields, propertyType: PropertyTypeFields, validationType: SpectrumFieldValidationTypeFields, fieldConstraints: Array<SpectrumFieldConstraintFields>, allowedValues: Array<SpectrumFieldAllowedValueFields>, fieldUnit?: FieldUnitFields | null, field?: (
    { workflowTemplateCounts: number }
    & FieldFields
  ) | null };

export const SpectrumFieldVersionFields = gql`
    fragment SpectrumFieldVersionFields on SpectrumFieldVersion {
  id
  uuid
  createdAt
  name
  helperText
  description
  internalName
  spectrumField {
    ...SpectrumFieldFields
  }
  propertyType {
    ...PropertyTypeFields
  }
  validationType {
    ...SpectrumFieldValidationTypeFields
  }
  fieldConstraints {
    ...SpectrumFieldConstraintFields
  }
  allowedValues {
    ...SpectrumFieldAllowedValueFields
  }
  fieldUnit {
    ...FieldUnitFields
  }
  field {
    ...FieldFields
    workflowTemplateCounts
  }
}
    `;