// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { GenericWorkflowStageFields } from './GenericWorkflowStageFields.generated';
import { GenericWorkflowActionItemFields } from './GenericWorkflowActionItemFields.generated';
import { WorkflowGraphActionItemFields } from './WorkflowGraphActionItemFields.generated';
import { gql } from '@apollo/client';
export type WorkflowGraphStageFields = { stage: GenericWorkflowStageFields, next: Array<{ stage: GenericWorkflowStageFields }>, rootActionItems: Array<WorkflowGraphActionItemFields>, headlessActionItems: Array<WorkflowGraphActionItemFields> };

export const WorkflowGraphStageFields = gql`
    fragment WorkflowGraphStageFields on WorkflowGraphStage {
  stage {
    ...GenericWorkflowStageFields
  }
  next {
    stage {
      ...GenericWorkflowStageFields
    }
  }
  rootActionItems {
    ...WorkflowGraphActionItemFields
  }
  headlessActionItems {
    ...WorkflowGraphActionItemFields
  }
}
    `;