// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type UserFieldsWithoutRoles = { __typename: 'User', id: number, name?: string | null, email: string, accessLevel: Types.UserAccessLevel, deletedAt?: string | null, isAdmin: boolean, isAuthAdmin: boolean, isMuted: boolean, isPendingSignUp: boolean, isVerified: boolean, globallyUniqueMetricsId: string, domain: string, timeZone?: string | null, isTimeZoneLocked: boolean, createdAt: string, defaultViewId?: number | null, userType: Types.UserType, accessRole?: AccessRoleFields | null, tenant: { id: number, name: string, displayName?: string | null, globallyUniqueMetricsId: string }, organization?: { name: string } | null, createdBy?: { __typename: 'User', id: number, name?: string | null, email: string, domain: string, createdAt: string, globallyUniqueMetricsId: string, isAdmin: boolean, isAuthAdmin: boolean, isMuted: boolean, isPendingSignUp: boolean, isVerified: boolean, accessLevel: Types.UserAccessLevel, userType: Types.UserType, party: { id: number }, tenant: { id: number, name: string, displayName?: string | null, globallyUniqueMetricsId: string }, accessRole?: AccessRoleFields | null, scimDetails: { isAddedViaScim: boolean, isActivelyScimProvisioned: boolean, managePermissions: boolean } } | null, scimDetails: { isAddedViaScim: boolean, isActivelyScimProvisioned: boolean, managePermissions: boolean } };

export const UserFieldsWithoutRoles = gql`
    fragment UserFieldsWithoutRoles on User {
  __typename
  id
  name
  email
  accessLevel
  accessRole {
    ...AccessRoleFields
  }
  deletedAt
  isAdmin
  isAuthAdmin
  isMuted
  isPendingSignUp
  isVerified
  globallyUniqueMetricsId
  domain
  tenant {
    id
    name
    displayName
    globallyUniqueMetricsId
  }
  timeZone
  isTimeZoneLocked
  organization {
    name
  }
  createdAt
  createdBy {
    __typename
    id
    name
    email
    domain
    createdAt
    globallyUniqueMetricsId
    isAdmin
    isAuthAdmin
    isMuted
    isPendingSignUp
    isVerified
    accessLevel
    party {
      id
    }
    tenant {
      id
      name
      displayName
      globallyUniqueMetricsId
    }
    accessRole {
      ...AccessRoleFields
    }
    userType
    scimDetails {
      isAddedViaScim
      isActivelyScimProvisioned
      managePermissions
    }
  }
  defaultViewId
  userType
  scimDetails {
    isAddedViaScim
    isActivelyScimProvisioned
    managePermissions
  }
}
    `;