// Z-indexes for absolutely positioned elements in the app-wide stacking context.q
//
// Some of these may never appear on the same page, but even so, please use a distinct integer for
// each constant. This will make things easiest to reason about.

// (zstanik): These 3 (or at least `Z_INDEX_PAGE_HEADER_V2`) should always be the greatest for now
// since the DOM elements they reference are often displayed on the same page as the ones referenced
// below and their stacking context should layer on top.
export const Z_INDEX_OAUTH_PAGE = 20;

export const Z_INDEX_CLASS_APP_HEADER_V2 = "z-15";
export const Z_INDEX_SEARCH_RESULTS_DIALOG = 13;
export const Z_INDEX_PAGE_HEADER_V2 = 12;
export const Z_INDEX_PAGE_HEADER_V2_CSS_CLASS = "z-12";

export const Z_INDEX_DATA_GRID_COLUMN_HEADER_DRAG_PREVIEW = 3;
export const Z_INDEX_DATA_GRID_COLUMN_HEADER_RESIZER = 2;
export const Z_INDEX_DATA_GRID_TABLE_HEAD = 2;
export const Z_INDEX_DATA_GRID_ROW_EXPAND_ICON = 1;

export const Z_INDEX_WORKFLOW_HEADER = 5;
export const Z_INDEX_CLASS_MOBILE_FLOATING_CREATE_BUTTON = "z-2";

export const Z_INDEX_SELF_CONTAINED_FORM_POPOVER = 10;
