// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PermissionedSidebarContentFlagsFields } from './PermissionedSidebarContentFlagsFields.generated';
import { PermissionedCreateMenuContentFlagsFields } from './PermissionedCreateMenuContentFlagsFields.generated';
import { gql } from '@apollo/client';
export type UserPermissionsToWorkspaceFields = { canAssignRoles: boolean, canCreateRolesWithInvite: boolean, permissionedNavigationFlags: { sidebarContentFlags: PermissionedSidebarContentFlagsFields, createMenuContentFlags: PermissionedCreateMenuContentFlagsFields }, customFieldPermissions: { canCreate: boolean, canEdit: boolean, canDelete: boolean } };

export const UserPermissionsToWorkspaceFields = gql`
    fragment UserPermissionsToWorkspaceFields on UserPermissionsToWorkspace {
  permissionedNavigationFlags {
    sidebarContentFlags {
      ...PermissionedSidebarContentFlagsFields
    }
    createMenuContentFlags {
      ...PermissionedCreateMenuContentFlagsFields
    }
  }
  customFieldPermissions {
    canCreate
    canEdit
    canDelete
  }
  canAssignRoles
  canCreateRolesWithInvite
}
    `;