import { clsx } from "@regrello/core-utils";
import React from "react";

import { RegrelloIntentV2 } from "../../../utils/enums/RegrelloIntentV2";
import { RegrelloIcon, RegrelloIconName } from "../../icons/RegrelloIcon";
import { RegrelloTooltipV4 } from "../../tooltip/RegrelloTooltipV4";
import { RegrelloTooltippedText } from "../../tooltippedText/RegrelloTooltippedText";

export interface RegrelloMenuV2ItemContentProps {
  /**
   * Children are not permitted. Use `text`, `icon`, `secondaryText`, and `keyboardShortcut`
   * instead.
   */
  children?: never;

  /**
   * Enables the item's content to be selectable via mouse. This property holds regardless
   * whether the `disabled` is `true` or not.
   *
   * @default false
   */
  enableSelection?: boolean;

  /**
   * An optional element to show on the right side of the menu item. Should be either a keyboard
   * shortcut or a submenu icon or a "selected" checkmark.
   */
  endElement?: React.ReactNode;

  /** An icon to show at the start of the menu item. */
  icon?: RegrelloIconName | JSX.Element;

  /**
   * If the menu item doesn't have an icon, pass `inset={true}` to left-align this item's text
   * with the text of neighboring items that _do_ have icons.
   *
   * ___Warning:___ Do not pass `inset={true}` when an `icon` is also provided, because this will
   * result in excessive space. A warning will be logged to the console in this case.
   */
  inset?: boolean;

  /**
   * A semantic color to apply to the menu item.
   * @default "neutral"
   */
  intent?: Exclude<RegrelloIntentV2, "secondary">;

  /** Optional helper text to display beneath the main `text`. */
  secondaryText?: string | JSX.Element;

  /** Whether this menu item should appear as visibly selected. */
  selected?: boolean;

  /**
   * Optional additional / contextual text to display between the main `text` and `secondaryText`.
   */
  tertiaryText?: string | JSX.Element;

  /** The main text to show in the menu item. */
  text: string | React.ReactNode;

  tooltip?: React.ReactNode;
}

/** Renders the content that should be supported in all menu items. */
export const RegrelloMenuV2ItemContent = ({
  enableSelection = false,
  endElement,
  icon,
  inset = false,
  intent = "neutral",
  secondaryText,
  selected: _isSelected = false, // should be styled by the caller via menuItemVariants().
  tertiaryText,
  text,
  tooltip,
}: RegrelloMenuV2ItemContentProps) => {
  const textMutedClassName = clsx({
    "text-neutral-text": intent === "neutral", // TODO (clewis): Change this to neutral-textMuted to be consistent with other colors?
    "text-primary-textMuted": intent === "primary",
    "text-success-textMuted": intent === "success",
    "text-warning-textMuted": intent === "warning",
    "text-danger-textMuted": intent === "danger",
  });

  return (
    <RegrelloTooltipV4 align="start" content={tooltip}>
      <div
        // (clewis): Taller on mobile to meet 44px minimum height for fingers, but 36px tall on other
        // devices (including tablets, which we aren't designing for at the moment).
        className={clsx("flex", "items-start", "gap-2", "px-2", "py-3", "sm:py-2", {
          ["select-none"]: enableSelection,
        })}
      >
        {inset && <RegrelloIcon iconName="blank" />}
        {typeof icon === "string" ? (
          <RegrelloIcon className={textMutedClassName} iconName={icon} intent={intent} size="small" />
        ) : icon != null ? (
          <div className="shrink-0">{icon}</div>
        ) : undefined}
        <div className="flex flex-col w-full">
          <div className="flex w-full">
            {/*
             * (clewis): include min-w-0 so that arbitrary child flex content (e.g., RegrelloChips)
             * will be clamped elegantly.
             */}
            <div className="text-sm flex-auto min-w-0">
              <RegrelloTooltippedText lines={3}>{text}</RegrelloTooltippedText>
            </div>
          </div>
          {tertiaryText != null && <div className="text-sm">{tertiaryText}</div>}
          {secondaryText != null && <div className={clsx("text-xs", textMutedClassName)}>{secondaryText}</div>}
        </div>
        {endElement != null && <div className={clsx("shrink-0 text-sm", textMutedClassName)}>{endElement}</div>}
      </div>
    </RegrelloTooltipV4>
  );
};
