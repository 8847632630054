import { useDateSegment } from "@react-aria/datepicker";
import { DateFieldState, DateSegment } from "@react-stately/datepicker";
import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { useRef } from "react";

import { RegrelloSize } from "../../utils/enums/RegrelloSize";

export interface RegrelloDateSegmentProps {
  onBlur?: () => void;

  segment: DateSegment;

  /** The size of the parent input. */
  size: RegrelloSize;

  state: DateFieldState;
}

export function RegrelloDateSegment({ onBlur, segment, size, state }: RegrelloDateSegmentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={clsx(
        "px-0.5",
        {
          ["py-0.5"]: size === "x-large" || size === "large" || size === "medium",
        },
        "tabular-nums",
        "text-center",
        "outline-none",
        "rounded",
        "text-textDefault",
        "focus:bg-primary-solid",
        "focus:text-textContrast",
        "aria-[invalid]:text-danger-textMuted",
        {
          "text-textPlaceholder": segment.isPlaceholder,
        },
      )}
      data-testid={segment.isEditable ? DataTestIds.FORM_FIELD_DATE_DATE_SEGMENT : undefined}
      onBlur={onBlur}
      style={{
        ...segmentProps.style,
        minWidth: segment.maxValue != null ? String(segment.maxValue).length + 1 + "ch" : undefined,
      }}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className="block w-full text-center focus:text-textContrast"
        style={{
          visibility: segment.isPlaceholder ? undefined : "hidden",
          height: segment.isPlaceholder ? undefined : 0,
          pointerEvents: "none",
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}
