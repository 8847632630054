/* eslint-disable class-methods-use-this */
import { noop } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  FieldFields,
  FormConstraintConditionOperator,
  PropertyDataType,
  PropertyTypeFields,
  SpectrumFieldValidationTypeFields,
  SpectrumFieldVersionFields,
  SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import { RegrelloIconName } from "@regrello/ui-core";
import {
  ConditionContains,
  ConditionDoesNotContain,
  ConditionEndsWith,
  ConditionIsEmpty,
  ConditionIsEqual,
  ConditionIsNotEmpty,
  ConditionIsNotEqual,
  ConditionStartsWith,
  Email,
} from "@regrello/ui-strings";
import { ReactNode } from "react";
import { FieldArrayWithId, FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { FrontendValueConstraintRuleName } from "./utils/spectrumFieldConstraintUtils";
import { ValidationRules } from "../../../constants/globalConstants";
import { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import {
  CustomFieldPlugin,
  CustomFieldPluginV2RenderFormFieldProps,
} from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldText } from "../formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldText } from "../formFields/RegrelloFormFieldText";

type TextFieldPluginFrontendValue = string;

type NewType = UseFormReturn<ConfigureSpectrumFieldFormFormFields>;

export class SpectrumEmailFieldPluginDecorator extends SpectrumFieldPluginDecorator<TextFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<TextFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.email";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.EMAIL;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.EMAIL,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.MAX_CHARACTER ||
        valueConstraintRule === FrontendValueConstraintRuleName.MIN_CHARACTER,
    );
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public override getFieldDisplayName = () => {
    return Email;
  };

  public override getIconName = (): RegrelloIconName => {
    return "email";
  };

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: ConditionIsEmpty,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: ConditionIsNotEmpty,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: ConditionIsEqual,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: ConditionIsNotEqual,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_CONTAINS]: {
        label: ConditionContains,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_NOT_CONTAINS]: {
        label: ConditionDoesNotContain,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_STARTS_WITH]: {
        label: ConditionStartsWith,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_ENDS_WITH]: {
        label: ConditionEndsWith,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloFormFieldText disabled={true} onChange={noop} size="medium" value="" />;
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints", "id">>;
    disabled: boolean;
    form: NewType;
    focusField: `valueConstraints.${number}.args.${number}`;
  }): ReactNode {
    return null;
  }

  public override renderSpectrumFormField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    _field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactChild => {
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    return (
      <RegrelloControlledFormFieldText
        {...props}
        key={props.controllerProps.name}
        controllerProps={{
          ...props.controllerProps,
          rules: { ...props.controllerProps.rules, ...ValidationRules.VALID_OR_EMPTY_EMAIL },
        }}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description ?? undefined}
        isDeleted={isDeleted}
      />
    );
  };
}
