import { clsx, WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { memo, ReactNode } from "react";

import { RegrelloButton, RegrelloButtonProps } from "../button/RegrelloButton";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloNonIdealStateActionProps
  extends WithDataTestId,
    Omit<RegrelloButtonProps, "intent" | "shape" | "size"> {
  /** Callback invoked when the button is clicked. */
  onClick: () => void;

  /** Text to show in the button. */
  text: string;
}

export interface RegrelloNonIdealStateProps {
  /** The main button to show. Should allow the user to get out of the non-ideal state. */
  actionProps?: RegrelloNonIdealStateActionProps;

  /** An optional description to display beneath the title. */
  description?: ReactNode;

  /** Secondary actions to show. Will appear as text buttons below the main action. */
  secondaryActionProps?: RegrelloNonIdealStateActionProps[];

  /**
   * The size of the title and other elements.
   * @default "medium"
   */
  size?: "medium" | "large";

  /** A concise, user-facing explanation of what went wrong (e.g., "Error", "Load failed"). */
  title: ReactNode;

  /** A visually meaningful icon or graphic to display at the top. */
  visual?: ReactNode;
}

/**
 * A fallback element that displays a visually centered message when something unexpected prevents a
 * view from loading normally.
 */
export const RegrelloNonIdealState = memo<RegrelloNonIdealStateProps>(function RegrelloNonIdealStateFn({
  actionProps,
  description,
  secondaryActionProps,
  size = "medium",
  title,
  visual,
}) {
  const hasVisual = visual != null;

  return (
    <div
      className={clsx(`
        flex
        flex-col
        items-center
        justify-center

        w-full
        h-full
        min-w-75
        max-w-[50vw]

        mx-auto
        p-5

        text-center
      `)}
    >
      {hasVisual && <div className="text-6xl text-neutral-icon">{visual}</div>}
      <RegrelloTypography
        className={clsx({
          "mt-4": hasVisual && size === "medium",
          "mt-6": hasVisual && size === "large",
        })}
        variant={size === "large" ? "h2" : "h5"}
      >
        <span data-testid={DataTestIds.PAGE_NONIDEAL_STATE_TITLE}>{title}</span>
      </RegrelloTypography>
      <RegrelloTypography className="break-words mt-2" muted={true} variant="body">
        {description}
      </RegrelloTypography>
      <div className="flex gap-2 mt-6">
        {actionProps != null && (
          <RegrelloButton dataTestId={actionProps.dataTestId} intent="primary" size="x-large" {...actionProps}>
            {actionProps.text}
          </RegrelloButton>
        )}
        {secondaryActionProps?.map((secondaryActionPropsObj, index) => (
          <RegrelloButton key={index} variant="outline" {...secondaryActionPropsObj} size="x-large">
            {secondaryActionPropsObj.text}
          </RegrelloButton>
        ))}
      </div>
    </div>
  );
});
