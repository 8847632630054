import { arrayRetainDefinedElements, EMPTY_STRING } from "@regrello/core-utils";
import { useCallback } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

import { useQueryStrings } from "./useQueryStrings";
import { RoutePaths, RoutePathsWithId, RouteQueryStringKeys } from "../../ui/app/routes/consts";

type QueryParams = Partial<Record<RouteQueryStringKeys, string | number>>;

type LocationDescriptorInternal = {
  pathname: string;
  search?: string;
};

export namespace useRegrelloHistory {
  export interface Return {
    /** Returns `true` if there is a Regrello app route to `goBack()` to. */
    canGoBack: () => boolean;

    /** Returns the specified query value from the URL, or `undefined` if it is not present.  */
    getQueryParam: (queryStringKey: RouteQueryStringKeys) => string | undefined;

    /** Navigates back a page using `history.goBack()`. */
    goBack: () => void;

    /** Navigates forward a page using `history.goForward()`. */
    goForward: () => void;

    /** The current history location. */
    location: Location;

    /**
     * We construct all generic page URLs at Regrello with the following structure:
     * `/{page}?{query strings to describe the view state}`
     *
     * @example /my/tasks?page-filter=overdue
     *
     * If a parameter value is `null` or `undefined` or `""`, that parameter will be omitted.
     */
    push: (page: RoutePaths, queryParams?: QueryParams) => void;

    /**
     * We construct all specific page URLs at Regrello with the following structure:
     * `/{page}/{page ID}?{query strings to describe the view state}`
     *
     * @example /tag/23?task-id=123
     *
     * If a parameter value is `null` or `undefined` or `""`, that parameter will be omitted.
     */
    pushWithId: (page: RoutePathsWithId, pageId: number, queryParams?: QueryParams) => void;

    /** Removes a specific query param from the URL. */
    popQueryParam: (queryStringKey: RouteQueryStringKeys, options?: { replace?: boolean }) => void;

    /** Adds a specific query param to the URL. */
    pushQueryParam: (
      queryStringKey: RouteQueryStringKeys,
      queryStringValue: string | number,
      options?: {
        clearOthers?: boolean;

        /**
         * Whether to use `history.replace` instead of the default `history.push`. You should use
         * this if you're automatically pushing a query param when a view is first loaded -
         * otherwise the browser's "Back" button won't work.
         */
        replace?: boolean;
      },
    ) => void;

    /**
     * Same as `push`, but invokes `history.replace` under the hood to avoid pushing a new entry
     * onto the history stack.
     */
    replace: (page: RoutePaths, queryParams?: QueryParams) => void;
  }
}

export function useRegrelloHistory(): useRegrelloHistory.Return {
  const navigate = useNavigate();
  const location = useLocation();
  const queryStrings = useQueryStrings();

  /**
   * Build the path, path ID, and query strings of the URL.
   *
   */
  const buildPathAndQueryStringsInternal = useCallback(
    (page: RoutePaths | RoutePathsWithId, pageId?: number, queryParams?: QueryParams): LocationDescriptorInternal => {
      const stringifiedQueryParams =
        queryParams == null
          ? undefined
          : Object.entries(queryParams)
              .filter(([_, value]) => value != null && value !== EMPTY_STRING)
              .map(([key, value]) => `${key}=${value}`)
              .join("&");

      // (clewis): We return an object because it is sure to replace the existing search string,
      // whereas returning a pre-built string is not.
      return {
        pathname: arrayRetainDefinedElements([page, pageId]).join("/"),
        search: stringifiedQueryParams,
      };
    },
    [],
  );

  /**
   * Build the path and query strings of the URL based on the current location path and any query strings
   *
   */
  const buildQueryStringsInternal = useCallback((): LocationDescriptorInternal => {
    return {
      pathname: location.pathname,
      search: queryStrings.toString(),
    };
  }, [location.pathname, queryStrings]);

  const push = useCallback(
    (page: RoutePaths, queryParams?: QueryParams) => {
      navigate(buildPathAndQueryStringsInternal(page, undefined, queryParams));
    },
    [buildPathAndQueryStringsInternal, navigate],
  );

  const replace = useCallback(
    (page: RoutePaths, queryParams?: QueryParams) => {
      navigate(buildPathAndQueryStringsInternal(page, undefined, queryParams), { replace: true });
    },
    [buildPathAndQueryStringsInternal, navigate],
  );

  const pushWithId = useCallback(
    (page: RoutePathsWithId, pageId: number, queryParams?: QueryParams) => {
      navigate(buildPathAndQueryStringsInternal(page, pageId, queryParams));
    },
    [buildPathAndQueryStringsInternal, navigate],
  );

  const popQueryParam = useCallback(
    (queryStringKey: RouteQueryStringKeys, options: { replace?: boolean } = {}) => {
      queryStrings.delete(queryStringKey);

      const newUrl = buildQueryStringsInternal();
      navigate(newUrl, { replace: options.replace });
    },
    [buildQueryStringsInternal, navigate, queryStrings],
  );

  const pushQueryParam = useCallback(
    (
      queryStringKey: RouteQueryStringKeys,
      queryStringValue: string | number,
      options: { clearOthers?: boolean; replace?: boolean } = {},
    ) => {
      if (queryStrings.get(queryStringKey) === queryStringValue.toString()) {
        // No-op.
        return;
      }
      if (options.clearOthers) {
        // (clewis): Must pull out the keys so that we can delete keys while iterating through them.
        const existingKeys = [...queryStrings.keys()];
        for (const key of existingKeys) {
          if (key !== queryStringKey) {
            queryStrings.delete(key);
          }
        }
      }
      queryStrings.set(queryStringKey, queryStringValue.toString());
      const newUrl = buildQueryStringsInternal();

      navigate(newUrl, { replace: options.replace });
    },
    [buildQueryStringsInternal, navigate, queryStrings],
  );

  const getQueryParam = useCallback(
    (queryStringKey: RouteQueryStringKeys) => {
      return queryStrings.get(queryStringKey) ?? undefined;
    },
    [queryStrings],
  );

  const canGoBack = useCallback(() => window.history.state.idx > 0, []);
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const goForward = useCallback(() => navigate(1), [navigate]);

  return {
    canGoBack,
    getQueryParam,
    goBack,
    goForward,
    location,
    push,
    pushWithId,
    popQueryParam,
    pushQueryParam,
    replace,
  };
}
