import { EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCase } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldUnit, SpectrumValueConstraintFields, useFieldUnitsQueryLazyQuery } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloChip, RegrelloIcon } from "@regrello/ui-core";
import {
  AddOption,
  CreateFieldDialogFieldTypeHelperText,
  CurrencySymbol,
  DataFormat,
  HelperText,
  Name,
  QueryToastMessageError,
  Type,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";

import { RegrelloDraggableRowItem } from "./RegrelloDraggableRowItem";
import { ValidationRules } from "../../../../../../constants/globalConstants";
import { useErrorHandler } from "../../../../../../utils/hooks/useErrorHandler";
import { UserFieldPlugin } from "../../../../../molecules/customFields/plugins/UserFieldPlugin";
import { RegrelloFormFieldSelectOption } from "../../../../../molecules/formFields/_internal/selectOptions/RegrelloFormFieldSelectOption";
import {
  RegrelloControlledFormFieldSelect,
  RegrelloControlledFormFieldSwitch,
  RegrelloControlledFormFieldTextMultiline,
} from "../../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { SpectrumUserFieldPluginDecorator } from "../../../../../molecules/spectrumFields/SpectrumUserFieldPluginDecorator";
import { SpectrumFieldPluginDecorator } from "../../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import {
  ALLOW_MULTIPLE_PARTY_ARG,
  FrontendValueConstraintRuleName,
  getNumberOfArgsByConstraint,
} from "../../../../../molecules/spectrumFields/utils/spectrumFieldConstraintUtils";

export interface ConfigureSpectrumFieldFormFormFields {
  name: string;
  helpText: string;
  pluginUri: string | undefined;
  allowedValues: Array<{ value: string }>;
  fieldUnit?: FieldUnit;
  isValueConstraintsEnabled: boolean;
  valueConstraints: Array<{
    constraint: SpectrumValueConstraintFields;
    args: string[];
  }>;
}

export interface ConfigureSpectrumFieldFormProps {
  spectrumFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>>;

  /** Callback that retrieves the full plugin class with the uri. */
  getPluginByUri: (pluginUri: string) => SpectrumFieldPluginDecorator<unknown> | undefined;

  /**
   * The name of the form field that will be focused when the dialog is open. Currently only "name"
   * and "helpText" is supported
   */
  focusField?: string;

  form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;

  /** The `mode` property determines whether the field **type** will be editable. */
  mode?: "create" | "edit";

  loadedValueConstraints: SpectrumValueConstraintFields[];
}

export const ConfigureSpectrumFieldForm = React.memo<ConfigureSpectrumFieldFormProps>(
  function ConfigureSpectrumFieldFormFn({
    getPluginByUri,
    spectrumFieldPlugins,
    form,
    mode = "create",
    loadedValueConstraints,
    focusField = "name",
  }) {
    const { handleError } = useErrorHandler();
    const selectedPluginUri = useWatch({ control: form.control, name: "pluginUri" });
    const isValueConstraintsEnabled = useWatch({ control: form.control, name: "isValueConstraintsEnabled" });
    useWatch({ control: form.control });

    const selectedPlugin = useMemo(
      () => (selectedPluginUri != null ? getPluginByUri(selectedPluginUri) : undefined),
      [getPluginByUri, selectedPluginUri],
    );

    const isSelectedPluginUriNeedsFieldUnit = selectedPlugin?.isNeedsFieldUnit?.();
    const isSelectedPluginUriNeedsAllowedValues = selectedPlugin?.hasAllowedValues?.();

    const [getFieldUnitsAsync, fieldUnitsResult] = useFieldUnitsQueryLazyQuery({
      onError: () => handleError(QueryToastMessageError),
    });
    const fieldUnits = fieldUnitsResult?.data?.fieldUnits ?? EMPTY_ARRAY;

    useEffect(() => {
      if (isSelectedPluginUriNeedsFieldUnit) {
        void getFieldUnitsAsync();
      }
    }, [isSelectedPluginUriNeedsFieldUnit, getFieldUnitsAsync]);

    const {
      fields: allowedValueRows,
      append: appendAllowedValue,
      remove: removeAllowedValue,
      move: moveAllowedValue,
    } = useFieldArray({
      control: form.control,
      name: "allowedValues",
    });

    const {
      fields: constraintRows,
      append: appendConstraint,
      update: updateConstraint,
      remove: removeConstraint,
    } = useFieldArray({
      control: form.control,
      name: "valueConstraints",
    });

    const updateApplicableConstraints = useCallback(
      (nextPlugin: SpectrumFieldPluginDecorator<unknown> | null) => {
        const applicableConstraints =
          nextPlugin?.findValueConstraintsFromLoadedValueConstraints(loadedValueConstraints) ?? EMPTY_ARRAY;

        // (hchen): Remove all previous constraints
        removeConstraint();

        appendConstraint(
          applicableConstraints.map((constraint) => {
            if (constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_PARTY) {
              return {
                constraint,
                args: new Array(getNumberOfArgsByConstraint(constraint)).fill(ALLOW_MULTIPLE_PARTY_ARG),
              };
            }
            return {
              constraint,
              args: new Array(getNumberOfArgsByConstraint(constraint)).fill(EMPTY_STRING),
            };
          }),
        );
      },
      [appendConstraint, loadedValueConstraints, removeConstraint],
    );

    const sortedPluginUris = useMemo(() => {
      const creationAllowedFieldPlugins = spectrumFieldPlugins.filter((plugin) => plugin.isCreateAndEditAllowed);
      return sortIgnoreCase(creationAllowedFieldPlugins, (plugin) => plugin.getFieldDisplayName()).map(
        (plugin) => plugin.uri,
      );
    }, [spectrumFieldPlugins]);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!event.metaKey && event.key === "Enter" && isSelectedPluginUriNeedsAllowedValues) {
          appendAllowedValue({ value: "" });
        }
      },
      [appendAllowedValue, isSelectedPluginUriNeedsAllowedValues],
    );

    // (hchen): Add a input field at the first time a select type is chosen. This is not only a UX
    // nicety but also necessary because there must be at least one option for the select types.
    useEffect(() => {
      if (isSelectedPluginUriNeedsAllowedValues && allowedValueRows.length === 0) {
        appendAllowedValue({ value: "" });
      }
    }, [allowedValueRows.length, appendAllowedValue, isSelectedPluginUriNeedsAllowedValues]);

    const renderFieldUnitOption = useCallback((fieldUnit: FieldUnit) => {
      return (
        <RegrelloFormFieldSelectOption
          mainSnippets={[{ highlight: false, text: getFieldUnitDisplayName(fieldUnit) }]}
        />
      );
    }, []);

    const renderPluginOption = useCallback(
      (spectrumFieldUri?: string | null) => {
        if (spectrumFieldUri == null) {
          return null;
        }

        const plugin = getPluginByUri(spectrumFieldUri);
        if (plugin == null) {
          return null;
        }
        return (
          <RegrelloFormFieldSelectOption
            mainSnippets={[{ highlight: false, text: plugin.getFieldDisplayName() }]}
            startAdornment={
              <div className="mr-1">
                <RegrelloIcon iconName={plugin.getIconName()} />
              </div>
            }
          />
        );
      },
      [getPluginByUri],
    );

    const renderPluginConstraints = useCallback(() => {
      const shouldDisableConstraintForUserField =
        selectedPlugin?.uri === new SpectrumUserFieldPluginDecorator(UserFieldPlugin).uri && mode === "edit";
      return selectedPlugin?.renderValueConstraints({
        constraints: constraintRows,
        disabled: !(selectedPlugin?.isCreateAndEditAllowed ?? true) || shouldDisableConstraintForUserField,
        form,
        focusField: focusField as `valueConstraints.${number}.args.${number}`,
        updateConstraint,
      });
    }, [constraintRows, focusField, form, mode, selectedPlugin, updateConstraint]);

    const handlePluginChange = useCallback(
      (_name: string, nextPluginUri: string | null) => {
        const nextPlugin = (nextPluginUri != null ? getPluginByUri(nextPluginUri) : null) ?? null;
        if (selectedPlugin?.hasAllowedValues?.() && !nextPlugin?.hasAllowedValues?.()) {
          form.resetField("allowedValues", { defaultValue: EMPTY_ARRAY });
        }
        if (selectedPlugin?.isNeedsFieldUnit?.() && !nextPlugin?.isNeedsFieldUnit?.()) {
          form.resetField("fieldUnit", { defaultValue: undefined });
        }
        updateApplicableConstraints(nextPlugin);
      },
      [form, getPluginByUri, selectedPlugin, updateApplicableConstraints],
    );

    useEffect(() => {
      // (hchen): If the form has default values, don't try to reset the applicable constraints with
      // `handlePluginChange`
      const valueConstraints = form.getValues("valueConstraints");
      if (valueConstraints?.length > 0) {
        return;
      }

      const pluginUri = form.getValues("pluginUri");
      if (pluginUri == null) {
        return;
      }
      handlePluginChange("pluginUri", pluginUri);
    }, [form, handlePluginChange]);

    return (
      <div onKeyDown={onKeyDown}>
        {/* Field name */}
        <RegrelloControlledFormFieldText
          autoFocus={focusField === "name"}
          controllerProps={{
            control: form.control,
            name: "name",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_NAME}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          isRequiredAsteriskShown={true}
          label={Name}
        />

        {/* Field helper text */}
        <RegrelloControlledFormFieldTextMultiline
          autoFocus={focusField === "helpText"}
          controllerProps={{
            control: form.control,
            name: "helpText",
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_HELPER_TEXT}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          label={HelperText}
        />

        {/* Field type */}
        <RegrelloControlledFormFieldSelect
          controllerProps={{
            control: form.control,
            name: "pluginUri",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_FIELD_TYPE}
          disabled={mode === "edit"}
          getOptionLabel={(option) => {
            return getPluginByUri(option)?.getFieldDisplayName() ?? EMPTY_STRING;
          }}
          helperText={selectedPlugin?.getHelperText?.() ?? CreateFieldDialogFieldTypeHelperText}
          isRequiredAsteriskShown={true}
          label={Type}
          onValueChange={handlePluginChange}
          options={sortedPluginUris}
          renderOption={renderPluginOption}
          renderSelectedValue={renderPluginOption}
        />

        {/* (If needed) Allowed values */}
        {isSelectedPluginUriNeedsAllowedValues && (
          <>
            {allowedValueRows.map((row, index) => {
              return (
                <div key={row.id} className="pl-20">
                  <RegrelloDraggableRowItem
                    index={index}
                    isDragEnabled={true}
                    moveRow={moveAllowedValue}
                    preview={<RegrelloChip>{row.value}</RegrelloChip>}
                    row={row}
                  >
                    <div className="flex items-start">
                      <RegrelloControlledFormFieldText
                        className="flex-1 mr-2"
                        controllerProps={{
                          control: form.control,
                          name: `allowedValues.${index}.value`,
                          rules: {
                            ...ValidationRules.REQUIRED,
                            ...ValidationRules.UNIQUE_IN({
                              otherValues: allowedValueRows
                                .filter((_, uniqueInIndex) => uniqueInIndex !== index)
                                .map(({ value }) => value),
                            }),
                          },
                        }}
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ALLOWED_VALUE}
                      />

                      {/* Delete */}
                      <RegrelloButton
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DELETE_OPTION_BUTTON}
                        disabled={index === 0 && allowedValueRows.length === 1}
                        iconOnly={true}
                        intent="neutral"
                        onClick={() => removeAllowedValue(index)}
                        startIcon="delete"
                        variant="ghost"
                      />
                    </div>
                  </RegrelloDraggableRowItem>
                </div>
              );
            })}

            {/* Add */}
            <div className="ml-26">
              <RegrelloButton
                dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ADD_OPTION_BUTTON}
                disabled={!selectedPlugin?.isCreateAndEditAllowed}
                onClick={() => appendAllowedValue({ value: EMPTY_STRING })}
                startIcon="add-circle"
                variant="outline"
              >
                {AddOption}
              </RegrelloButton>
            </div>
          </>
        )}

        {/* (If needed) Field unit */}
        {isSelectedPluginUriNeedsFieldUnit && (
          <RegrelloControlledFormFieldSelect
            controllerProps={{
              control: form.control,
              name: "fieldUnit",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_UNIT}
            getOptionLabel={(fieldUnit) => getFieldUnitDisplayName(fieldUnit)}
            isRequiredAsteriskShown={true}
            label={CurrencySymbol}
            options={fieldUnits}
            renderOption={renderFieldUnitOption}
          />
        )}

        {selectedPlugin?.isValueConstraintEnabled() && (
          <RegrelloControlledFormFieldSwitch
            className="ml-2"
            controllerProps={{
              control: form.control,
              name: "isValueConstraintsEnabled",
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DATA_FORMAT_SWITCH}
            disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
            label={EMPTY_STRING}
            secondaryLabel={DataFormat}
          />
        )}

        {isValueConstraintsEnabled && renderPluginConstraints()}
      </div>
    );
  },
);

function getFieldUnitDisplayName(fieldUnit: FieldUnit) {
  return `${fieldUnit.symbol} ${fieldUnit.name}`;
}
