import {
  RegrelloFullColorLogoMarkIcon,
  RegrelloIconStyler,
  RegrelloLinkV2,
  RegrelloTypography,
} from "@regrello/ui-core";
import {
  CopyrightTitleCased,
  PleaseContactLinkForAssistance,
  PrivacyPolicyTitleCased,
  ReturnToHome,
  ReturnToLogin,
  TheresAProblemWithYourCredentials,
} from "@regrello/ui-strings";
import React, { useMemo } from "react";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RoutePaths } from "../../../../app/routes/consts";

export const UnauthenticatedCredentialsErrorPage = React.memo(function UnauthenticatedCredentialsErrorPageFn() {
  const { redirect } = useQueryMap();

  const redirectTo = useMemo(() => (redirect === RoutePaths.LOGIN ? RoutePaths.LOGIN : RoutePaths.HOME), [redirect]);

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col px-12 pb-4 w-200">
        <div className="flex flex-col flex-1 justify-center gap-10">
          <RegrelloIconStyler size="x-large">
            <RegrelloFullColorLogoMarkIcon />
          </RegrelloIconStyler>
          <RegrelloTypography variant="h1">{TheresAProblemWithYourCredentials}</RegrelloTypography>
          <RegrelloTypography variant="body">
            {PleaseContactLinkForAssistance(
              <RegrelloLinkV2 to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>{REGRELLO_SUPPORT_EMAIL}</RegrelloLinkV2>,
            )}
          </RegrelloTypography>

          <RegrelloLinkV2 to={redirectTo}>
            {redirect === RoutePaths.LOGIN ? ReturnToLogin : ReturnToHome}
          </RegrelloLinkV2>
        </div>
        <RegrelloTypography className="text-center flex gap-6 justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="hover:underline" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          {CopyrightTitleCased}
        </RegrelloTypography>
      </div>
    </div>
  );
});
