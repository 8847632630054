import { isDefined } from "@regrello/core-utils";
import { SortOrder } from "@regrello/graphql-api";
import { ColumnSort, SortingState, Updater } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import {
  decodeArray,
  decodeObject,
  encodeArray,
  encodeObject,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";

import { DEBOUNCE_TIMEOUT } from "../../constants/globalConstants";
import { PAGINATION_LIMIT } from "../../constants/numbers";
import { RouteQueryStringKeys } from "../../ui/app/routes/consts";

// TODO (anthony): Persist table filters to URL.

const SortingStateParam = {
  encode: (array: SortingState | null | undefined) => {
    if (array == null) {
      return undefined;
    }
    const paramMap = array.map((item) => encodeObject(item as unknown as Record<string, string>, ",", "|"));
    return encodeArray(paramMap.filter(isDefined));
  },

  decode: (arrayStr: string | Array<string | null> | null | undefined) =>
    decodeArray(arrayStr)
      ?.map((item) => {
        const decodedItem = decodeObject(item, ",", "|");
        // Failed to decode object
        if (!decodedItem) {
          return undefined;
        }
        // Object has wrong shape - ignore
        if (decodedItem.id == null || decodedItem.desc == null) {
          return undefined;
        }
        return {
          id: decodedItem.id,
          desc: decodedItem.desc === "true",
        } satisfies ColumnSort;
      })
      // Filter unrecognized items
      .filter(isDefined),
};

export interface usePaginationAndSortingArgs {
  /**
   * Overrides default debounce timeout
   * @default 150 ms
   */
  debounceTimeout?: number;

  /** Default state to load for the sort options. */
  defaultSortingState: SortingState;

  /**
   * Number of records to load into the page at one time.
   *
   * @see PAGINATION_LIMIT
   * @default 50
   */
  pageSize?: number;
}

/**
 * Handles pagination and sorting state persisted in the URL.
 */
export function usePaginationAndSorting<SortBy = unknown>({
  debounceTimeout = DEBOUNCE_TIMEOUT,
  defaultSortingState,
  pageSize = PAGINATION_LIMIT,
}: usePaginationAndSortingArgs) {
  // Pagination
  const [pageNumberParam, setPageNumberParam] = useQueryParam(RouteQueryStringKeys.PAGE, NumberParam);

  const pageNumber = pageNumberParam ?? 1;
  const setPageNumber = setPageNumberParam;
  const offset = (pageNumber - 1) * pageSize;

  const onNextPageClick = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber]);

  const onPreviousPageClick = useCallback(() => {
    setPageNumber(Math.max(pageNumber - 1, 1));
  }, [pageNumber, setPageNumber]);

  // Sorting
  const [sortParam, setSortParam] = useQueryParam(RouteQueryStringKeys.SORT, SortingStateParam, {
    updateType: "replaceIn",
  });

  const sortState = sortParam ?? defaultSortingState;
  const setSortState = useCallback(
    (updater: Updater<SortingState> | undefined) => {
      let newState: SortingState | undefined;
      if (typeof updater === "function") {
        newState = updater(sortParam || []);
      } else {
        newState = updater;
      }

      if (pageNumber !== 1) {
        setPageNumberParam(1);
      }
      setSortParam(newState);
    },
    [pageNumber, setPageNumberParam, setSortParam, sortParam],
  );

  const querySortParams = useMemo(() => {
    const sortColumn = sortState != null && sortState.length > 0 ? sortState[0] : defaultSortingState[0];
    return {
      sortBy: sortColumn.id as SortBy,
      sortOrder: sortColumn.desc ? SortOrder.DESC : SortOrder.ASC,
    };
  }, [defaultSortingState, sortState]);

  // Search
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [throttledSearchValue, setThrottledSearchValue] = useQueryParam(RouteQueryStringKeys.SEARCH, StringParam);

  const debouncedSetThrottledSearchValue = useCallback(() => {
    setThrottledSearchValue(searchValue);
    if (pageNumberParam != null && pageNumberParam > 1) {
      setPageNumberParam(1);
    }
  }, [pageNumberParam, searchValue, setPageNumberParam, setThrottledSearchValue]);

  // Debounce search changes to another state variable that can be used for API calls.
  useDebounce(debouncedSetThrottledSearchValue, debounceTimeout, [searchValue]);

  return {
    // Pagination
    offset,
    onNextPageClick,
    onPreviousPageClick,
    pageNumber,
    setPageNumber,
    // Sorting
    sortState,
    setSortState,
    querySortParams,
    // Search
    searchValue,
    setSearchValue,
    throttledSearchValue,
  };
}
