import { getLocalTimeZone, parseDateTime, toLocalTimeZone, toZoned, ZonedDateTime } from "@internationalized/date";
import { clsx, endOfDay, startOfDay, WithClassName } from "@regrello/core-utils";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";

import { RegrelloDatePicker, RegrelloDatePickerProps } from "../../atoms/dateTime/RegrelloDatePicker";
import { RegrelloTimePicker, RegrelloTimePickerProps } from "../../atoms/dateTime/RegrelloTimePicker";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";

// (surya): parseDateTime needs the specified isoFormat to convert the date object to the
// CalendarDateTime object for the time picker.
const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm";

export interface RegrelloDateTimePickerProps extends WithClassName {
  /** Default value for uncontrolled input. */
  defaultValue?: Date;

  /**
   * Whether both inputs are non-interactive.
   * @default false
   */
  disabled?: boolean;

  /** Props to pass to the date input. */
  datePickerProps?: Omit<RegrelloDatePickerProps, "disabled" | "maxValue" | "minValue" | "onChange" | "size" | "value">;

  /**
   * The default time for time-picker is 11:59PM. Set this to True
   * to use Start of Day instead, i.e. 12:00AM.
   * @default false
   */
  isDefaultTimeStartOfDay?: boolean;

  /** The maximum selectable date-time. */
  maxValue?: Date;

  /** The minimum selectable date-time. */
  minValue?: Date;

  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;

  /** Change handler for the controlled value. Must be set along `value` property. */
  onChange?: (date: Date | string | null) => void;

  /**
   * Whether to show a time-picker with the date-picker.
   * @default false
   */
  showTimePicker?: boolean;

  /**
   * The size of both inputs.
   * @default "large"
   */
  size?: RegrelloSize;

  /** Props to pass to the time input. */
  timePickerProps?: Omit<RegrelloTimePickerProps, "disabled" | "maxValue" | "minValue" | "onChange" | "size" | "value">;

  /** Currently selected controlled value. Must be set along `onChange` property. */
  value?: Date | string | null;

  /** Should chosen value be represented as string instead of `Date`. */
  valueAsString?: boolean;
}

function isDate(date: string | Date): date is Date {
  return Object.prototype.toString.call(date) === "[object Date]";
}

/**
 * Renders a combination date/time picker, with a date picker next to a time picker. The two work in
 * tandem to take a single `value` and emit a single `onChange` event. Currently optimized for
 * selecting due dates, so will do things like default to the end of the day if no time is
 * specified.
 */
export function RegrelloDateTimePicker({
  className,
  datePickerProps,
  defaultValue,
  disabled: isDisabled = false,
  isDefaultTimeStartOfDay = false,
  maxValue,
  minValue,
  onBlur,
  onChange,
  showTimePicker = false,
  size = "large",
  timePickerProps,
  value,
  valueAsString,
}: RegrelloDateTimePickerProps) {
  const defaultTimeValue = useMemo(() => {
    return defaultValue == null ? null : extractLocalTimeValue(defaultValue, isDefaultTimeStartOfDay);
  }, [defaultValue, isDefaultTimeStartOfDay]);

  const maxTimeValue = useMemo(() => {
    return maxValue == null ? null : extractLocalTimeValue(maxValue, isDefaultTimeStartOfDay);
  }, [maxValue, isDefaultTimeStartOfDay]);

  const minTimeValue = useMemo(() => {
    return minValue == null ? null : extractLocalTimeValue(minValue, isDefaultTimeStartOfDay);
  }, [minValue, isDefaultTimeStartOfDay]);

  const [internalValue, setInternalValue] = useState<Date | null>(null);

  // (zstanik/surya): We use a state variable here so that updates to the time value
  // could be properly tracked, and not overwritten if the user has already set a time.
  const [timeValue, setTimeValue] = useState<ZonedDateTime | null>(
    extractLocalTimeValue(value, isDefaultTimeStartOfDay),
  );

  const dateValue = useMemo(() => {
    if (value != null) {
      return extractDateValue(value);
    }
    if (internalValue != null) {
      return extractDateValue(internalValue);
    }
    return null;
  }, [internalValue, value]);

  const clearValue = useCallback(() => {
    setInternalValue(null);
    setTimeValue(null);
    onChange?.(null);
  }, [onChange]);

  // Updates the date and time when the calendar is interacted with. Sets the default time to the
  // user's local EOD.
  const onDateChange = useCallback(
    (date: string | Date | null) => {
      if (date == null) {
        // If the date is cleared, clear time too.
        clearValue();
        return;
      }

      const dateAsDate = isDate(date) ? date : new Date(date);

      // (surya): Set the time to EOD if not set, else use previously saved time.
      const nextDateTimeValue = new Date(
        timeValue != null
          ? setTimeWithEndOfDayDefault(dateAsDate, {
              hour: timeValue.hour,
              minute: timeValue.minute,
              second: timeValue.second,
              millisecond: timeValue.millisecond,
            })
          : isDefaultTimeStartOfDay
            ? startOfDay(dateAsDate)
            : endOfDay(dateAsDate),
      );
      setInternalValue(nextDateTimeValue);

      if (timeValue == null) {
        setTimeValue(extractLocalTimeValue(nextDateTimeValue, isDefaultTimeStartOfDay));
      }

      if (valueAsString) {
        onChange?.(nextDateTimeValue.toISOString());
      } else {
        onChange?.(nextDateTimeValue);
      }
    },
    [timeValue, isDefaultTimeStartOfDay, valueAsString, clearValue, onChange],
  );

  // Converts ZonedDateTime to timezone-aware date object for the onChange handler.
  const onTimeChange = useCallback(
    (time?: ZonedDateTime | null) => {
      setTimeValue(time ?? null);

      if (time != null && internalValue != null) {
        const nextDateValue = new Date(
          internalValue.getFullYear(),
          internalValue.getMonth(),
          internalValue.getDate(),
          time.hour,
          time.minute,
          time.second,
          time.millisecond,
        );
        setInternalValue(nextDateValue);
        onChange?.(
          toLocalTimeZone(toZoned(parseDateTime(format(nextDateValue, ISO_FORMAT)), getLocalTimeZone())).toDate(),
        );
      } else if (time != null) {
        // (surya): If only time set but not date, default to today's date.
        const today = new Date();
        const nextDateValue = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          time.hour,
          time.minute,
          time.second,
          time.millisecond,
        );
        setInternalValue(nextDateValue);
        onChange?.(
          toLocalTimeZone(toZoned(parseDateTime(format(nextDateValue, ISO_FORMAT)), getLocalTimeZone())).toDate(),
        );
      }
    },
    [onChange, internalValue],
  );

  return (
    <div className={clsx("flex gap-2", className)} onBlur={onBlur}>
      <RegrelloDatePicker
        {...datePickerProps}
        defaultValue={defaultValue}
        disabled={isDisabled}
        maxValue={maxValue}
        minValue={minValue}
        onChange={onDateChange}
        size={size}
        value={dateValue}
      />
      {showTimePicker && (
        <RegrelloTimePicker
          {...timePickerProps}
          defaultValue={defaultTimeValue ?? undefined}
          disabled={isDisabled}
          maxValue={maxTimeValue ?? undefined}
          minValue={minTimeValue ?? undefined}
          onChange={onTimeChange}
          size={size}
          value={timeValue}
        />
      )}
    </div>
  );
}

/**
 * Given a full date-time value, returns just the time part (in the local time zone). Returns `null`
 * if the provided value is not defined.
 *
 * @param isDefaultTimeStartOfDay Whether to default the time to the start of the day if no time is
 * specified. By default, the time will default to the last instant of the day if no time is
 * specified.
 */
function extractLocalTimeValue(value: Date | string | null | undefined, isDefaultTimeStartOfDay: boolean) {
  if (value == null || !(value instanceof Date)) {
    return null;
  }

  const calendarDateTime = parseDateTime(format(value, ISO_FORMAT));
  const localTimeZone = getLocalTimeZone();
  const timeValue = toZoned(calendarDateTime, localTimeZone);

  const isTimeSpecified = timeValue.hour !== 0 || timeValue.minute !== 0;

  if (isTimeSpecified) {
    // Return the time part as is.
    return timeValue;
  }

  if (isDefaultTimeStartOfDay) {
    // Default to the start of day.
    return timeValue.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  // Default to the last instant of the day if the time part is unspecified.
  return timeValue.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
}

/** Creates a date that will be safe from time-zone issues. */
function extractDateValue(value: Date | string): Date {
  return isDate(value) ? value : new Date(value);
}

/**
 * Create date object with hours, minutes, seconds, and milliseconds all specifiable.
 */
function setTimeWithEndOfDayDefault(
  value: Date,
  time: {
    hour: number;
    minute?: number;
    second?: number;
    millisecond?: number;
  },
): Date {
  const result = new Date(value);
  result.setHours(time.hour, time.minute ?? 59, time.second ?? 59, time.millisecond ?? 999);
  return result;
}
