import { WithChildren, WithKey } from "@regrello/core-utils";
import React, { useCallback } from "react";

import { RegrelloButton, RegrelloButtonProps } from "../../atoms/button/RegrelloButton";

export interface RegrelloButtonBarProps {
  /**
   * Props describing any additional buttons you want to render on the "end" (i.e., right) side of
   * the bar. It's common to pass a "Cancel" button here. Buttons in the array will be rendered in
   * start-to-end order.
   */
  additionalEndSideButtonProps?: Array<Omit<RegrelloButtonProps, "size" | "variant"> & WithKey & WithChildren>;

  /**
   * Props describing any additional buttons you want to render on the "start" (i.e., left) side of
   * the bar. It's common to pass a "Cancel" button here. Buttons in the array will be rendered in
   * start-to-end order.
   */
  additionalStartSideButtonProps?: Array<Omit<RegrelloButtonProps, "size" | "variant"> & WithKey & WithChildren>;

  /**
   * Props to pass to the confirm button, which is the only button that's required. This will be
   * the end-most (i.e., right-most) button in the bar.
   *
   * @default intent - "primary"
   */
  confirmButtonProps: Omit<RegrelloButtonProps, "intent" | "variant" | ("size" & WithChildren)> & {
    intent?: "primary" | "danger";
  };
}

/**
 * A bar that displays a row of buttons on the "start" (i.e., left) and "end" (i.e., right) side.
 * Useful in dialog footers when you want to show Submit and Cancel buttons on one side with a
 * possible additional button on the other.
 */
export const RegrelloButtonBar = React.memo<RegrelloButtonBarProps>(function RegrelloButtonBarFn({
  confirmButtonProps,
  additionalEndSideButtonProps,
  additionalStartSideButtonProps,
}) {
  const renderAdditionalButton = useCallback(
    (buttonProps: Omit<RegrelloButtonProps, "variant" | "size"> & { key: React.Key }) => (
      <div key={buttonProps.key}>
        <RegrelloButton {...buttonProps} variant="outline" />
      </div>
    ),
    [],
  );

  return (
    <div className="flex items-center mt-4 w-full">
      {additionalStartSideButtonProps != null && (
        <div className="flex-auto flex gap-2">{additionalStartSideButtonProps?.map(renderAdditionalButton)}</div>
      )}
      <div className="flex-auto flex justify-end gap-2">
        {additionalEndSideButtonProps?.map(renderAdditionalButton)}
        <RegrelloButton {...confirmButtonProps} intent={confirmButtonProps.intent ?? "primary"} variant="solid" />
      </div>
    </div>
  );
});
