import { EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  CreateFieldInstanceValueInputs,
  FieldInstanceValueInputType,
  useCreateWorkflowTemplateMutation,
  WorkflowTemplateForSelectFieldFields,
  WorkflowTemplateType,
} from "@regrello/graphql-api";
import {
  AddWorkflowTemplateCollaboratorsHelperText,
  CreateBlueprint,
  ErrorCreateWorkflowTemplate,
  FieldSectionHelperTextWhenConfiguringBlueprintV2,
  FromUserStartingTheWorkflow,
  RequestedInformation,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import { useErrorHandler } from "../../../../utils/hooks/useErrorHandler";
import { useRegrelloHistory } from "../../../../utils/hooks/useRegrelloHistory";
import { useTitle } from "../../../../utils/hooks/useTitle";
import { getWorkflowTemplatesListQueryWithVariables } from "../../../../utils/queryUtils";
import { RegrelloFormDialogName } from "../../../../utils/sentryScopeUtils";
import { RoutePathsWithId } from "../../../app/routes/consts";
import { NameTemplateHandle } from "../../../molecules/nameTemplate/utils/nameTemplateUtils";
import { RegrelloConfigureCustomFieldsForm } from "../formDialogs/customFields/RegrelloConfigureCustomFieldsForm";
import {
  ConfigureWorkflowFormNativeFieldKeys,
  RegrelloConfigureWorkflowFormDialog,
  RegrelloConfigureWorkflowFormFieldValues,
} from "../formDialogs/workflow/RegrelloConfigureWorkflowFormDialog";

export interface CreateWorkflowTemplateDialogProps {
  /** Default name to initially populate the create template dialog. */
  defaultName?: string;

  /** Whether the dialog is currently open. */
  isOpen: boolean;

  /** Callback invoked when the dialog closes. */
  onClose: () => void;

  /** Callback invoked when the dialog is submitted and a new workflow template is created. */
  onSubmit?: (newWorkflowTemplate: WorkflowTemplateForSelectFieldFields) => void;
}

/**
 * A dialog that allows the user to create a new workflow template. This dialog uses our new form
 * fields as of Dec 2021.
 */
export const CreateWorkflowTemplateDialog = React.memo(function CreateWorkflowTemplateDialogFn({
  defaultName,
  isOpen,
  onClose,
  onSubmit,
}: CreateWorkflowTemplateDialogProps) {
  const { handleError } = useErrorHandler();
  const regrelloHistory = useRegrelloHistory();

  const isStage0Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.STAGE_0_2024_03);
  const isSpectrumEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.SPECTRUM_2023_08);

  const [createWorkflowTemplateAsync] = useCreateWorkflowTemplateMutation({
    onError: (error) => handleError(error, { toastMessage: ErrorCreateWorkflowTemplate }),
  });

  const { getTitle, setTitle } = useTitle();
  useEffect(() => {
    const oldTitle = getTitle();
    if (isOpen) {
      setTitle(CreateBlueprint);
      return () => setTitle(oldTitle);
    }
    return () => null;
  }, [getTitle, isOpen, setTitle]);

  const customFieldsFormV2 = useForm<RegrelloConfigureCustomFieldsForm.Fields>({
    mode: "onChange",
    defaultValues: {
      customFields: EMPTY_ARRAY,
    },
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const nameTemplateFormFieldRef = useRef<NameTemplateHandle>(null);

  const handleSubmit = useCallback(
    async (data: RegrelloConfigureWorkflowFormFieldValues) => {
      const isCustomFieldsFormValid = await customFieldsFormV2.trigger(undefined, { shouldFocus: true });
      if (!isCustomFieldsFormValid) {
        return false;
      }

      const fieldInstances: CreateFieldInstanceValueInputs[] =
        RegrelloConfigureCustomFieldsForm.getMutationCreatePayloadFromFormValues(customFieldsFormV2.getValues());

      const nameTemplateInputs = nameTemplateFormFieldRef.current?.getNameTemplateInputs();
      const result = await createWorkflowTemplateAsync({
        variables: {
          input: {
            name: data.name,
            nameTemplate: nameTemplateInputs,
            description: data.description,
            fieldInstances,
            tagIds: EMPTY_ARRAY,
          },
        },
        refetchQueries: [
          getWorkflowTemplatesListQueryWithVariables({
            types: [WorkflowTemplateType.MINE],
          }),
        ],
      });

      if (result.errors != null) {
        return false;
      }

      const workflowTemplate = result?.data?.createWorkflowTemplate?.workflowTemplate;

      if (workflowTemplate == null) {
        return false;
      }

      if (onSubmit != null) {
        onSubmit(workflowTemplate);
      } else if (isSpectrumEnabled) {
        regrelloHistory.pushWithId(RoutePathsWithId.BLUEPRINTS, workflowTemplate.id);
      } else {
        regrelloHistory.pushWithId(RoutePathsWithId.WORKFLOW_TEMPLATE, workflowTemplate.id);
      }
      return true;
    },
    [createWorkflowTemplateAsync, customFieldsFormV2, onSubmit, regrelloHistory, isSpectrumEnabled],
  );

  return (
    <RegrelloConfigureWorkflowFormDialog
      addCollaborationsFieldProps={{
        defaultCollaborations: EMPTY_ARRAY,
        helperText: AddWorkflowTemplateCollaboratorsHelperText,
      }}
      addCustomFieldProps={{
        addCustomFieldFormV2: customFieldsFormV2,
        customFieldFormHelperText: FieldSectionHelperTextWhenConfiguringBlueprintV2,
        customFieldFormTitle: RequestedInformation,
        customFieldValueHelperText: FromUserStartingTheWorkflow,
        allowSelectWorkflowOwner: true,
        disallowSelectInputType: false,
        disallowEditFieldValue: true,
        isCustomFieldSectionHidden: isStage0Enabled,
      }}
      dataTestId={DataTestIds.CREATE_TEMPLATE_DIALOG}
      defaultInteractionType={FieldInstanceValueInputType.REQUESTED}
      defaultName={defaultName}
      hiddenNativeFields={[
        ConfigureWorkflowFormNativeFieldKeys.COLLABORATIONS,
        ConfigureWorkflowFormNativeFieldKeys.TAGS,
      ]}
      isAddFieldsButtonHidden={isStage0Enabled}
      isOpen={isOpen}
      nameTemplateProps={{ nameTemplateFormFieldRef, context: "blueprint" }}
      onClose={handleClose}
      onSubmit={handleSubmit}
      scopeNameForSentry={RegrelloFormDialogName.ADD_WORKFLOW}
      submitButtonText={CreateBlueprint}
      title={CreateBlueprint}
    />
  );
});
