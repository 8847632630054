import { useAtom } from "jotai";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";

import { toastsAtom, ToastWithId } from "../../../state/applicationState";

export type Toast = Omit<ToastWithId, "id">;

export namespace useToastMessageQueue {
  export interface Return {
    /** Shows a toast and returns the `toastId` that can be passed to `dismissToast`. */
    showToast: (newToast: Toast) => string;
    dismissToast: (toastId: string) => void;
    toasts: ToastWithId[];
  }
}

const DEFAULT_AUTOCLOSE_TIMEOUT = 5000;

/**
 * This hook exposes two API for showing and dismissing a global toast message.
 *  @return showToast: (newToast: Toast) => string: add a new toast to the message queue and display.
 *  @return dismissToast: (toastId: string) => void: dismiss a toast message with the provided id.
 *  @return toast: Toast: the toast message array connected to Jotai.
 *
 * @tip Typically you should only need to use showToast. Dismissing a toast message is handled by
 * the <RegrelloToastMessage> component internally.
 */
export function useToastMessageQueue(): useToastMessageQueue.Return {
  const [toasts, setToasts] = useAtom(toastsAtom);

  const showToast = useCallback(
    (newToast: Toast): string => {
      const toastId = uuid().substring(0, 5);
      setToasts((currentToasts) => [
        ...currentToasts,
        {
          id: toastId,
          intent: "none",
          timeout: DEFAULT_AUTOCLOSE_TIMEOUT,
          ...newToast,
        },
      ]);

      return toastId;
    },
    [setToasts],
  );

  const dismissToast = useCallback(
    (toastId: string) => {
      setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== toastId));
    },
    [setToasts],
  );

  return {
    showToast,
    dismissToast,
    toasts,
  };
}
