import { clsx } from "@regrello/core-utils";
import {
  RegrelloButtonProps,
  RegrelloCheckbox,
  RegrelloCheckboxProps,
  RegrelloControlWithLabel,
  RegrelloTooltippedInfoIcon,
  RegrelloTypography,
} from "@regrello/ui-core";
import { Deleted, DeleteFieldHelperText } from "@regrello/ui-strings";
import React, { useId } from "react";

import { RegrelloFormFieldBaseProps, RegrelloFormFieldLabelPlacement } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";

import {} from "./_internal/RegrelloFormLabel";

export interface RegrelloFormFieldCheckboxProps
  // (clewis): Checkboxes don't work well with the 'selfContainedForm' option, because they don't
  // really consist of a key/value pair. Can maybe revisit in the future (e.g., if we want to show
  // "Label: True/False").
  //
  // (clewis): Checkboxes also don't work well with the 'labelWidth' option, because they don't use
  // the same left-aligned label as other form fields.
  extends Omit<RegrelloFormFieldBaseProps<boolean>, "selfContainedForm">,
    Pick<RegrelloCheckboxProps, "onBlur" | "ref">,
    Pick<RegrelloButtonProps, "onClick"> {
  onChange: (nextChecked: boolean) => void;
  value: NonNullable<RegrelloCheckboxProps["checked"]>;
}

export const RegrelloFormFieldCheckbox = React.memo<RegrelloFormFieldCheckboxProps>(
  React.forwardRef(function RegrelloFormFieldCheckboxFn(
    {
      className,
      dataTestId,
      error,
      helperText,
      isDefaultMarginsOmitted,
      isDeleted,
      isEmphasized: _isEmphasized, // (clewis): Extract from checkboxProps so we don't get HTML invalid-attribute errors.
      label,
      labelPlacement,
      labelWidth,
      onChange,
      value,
      ...checkboxProps
    },
    ref,
  ) {
    const id = useId();
    const hasError = error != null;

    return (
      <RegrelloFormFieldLayout
        className={clsx("items-center", className, {
          "mb-5": checkboxProps.variant === "spectrum",
        })}
        dataTestId={dataTestId}
        error={error}
        helperText={helperText != null ? <div className="mt--2 ml-7">{helperText}</div> : undefined}
        htmlFor={id}
        infoTooltipIconName={checkboxProps.variant !== "spectrum" ? checkboxProps.infoTooltipIconName : undefined}
        infoTooltipText={checkboxProps.variant !== "spectrum" ? checkboxProps.infoTooltipText : undefined}
        infoTooltipVariant={checkboxProps.variant !== "spectrum" ? checkboxProps.infoTooltipVariant : undefined}
        isDefaultMarginsOmitted={isDefaultMarginsOmitted}
        isDeleted={isDeleted}
        isRequiredAsteriskShown={false} // (hchen): Checkbox is always optional
        label={labelPlacement === RegrelloFormFieldLabelPlacement.START ? label : undefined}
        labelWidth={labelPlacement === RegrelloFormFieldLabelPlacement.START ? labelWidth : undefined}
        variant={checkboxProps.variant}
      >
        {labelPlacement === RegrelloFormFieldLabelPlacement.START ? (
          <RegrelloCheckbox
            {...checkboxProps}
            ref={ref}
            checked={value}
            hasError={hasError}
            id={id}
            onCheckedChange={onChange}
          />
        ) : (
          <RegrelloControlWithLabel
            control={
              <RegrelloCheckbox
                {...checkboxProps}
                ref={ref}
                checked={value}
                hasError={hasError}
                id={id}
                onCheckedChange={onChange}
              />
            }
            disabled={checkboxProps.disabled}
            label={
              checkboxProps.variant === "spectrum" ? (
                // (hchen): Cannot use <FormLabel> here because it blocks the click event on the
                // field text.
                <div className="flex items-center">
                  <RegrelloTypography className="mr-1 rg-textFormLabel">{label}</RegrelloTypography>
                  <RegrelloTooltippedInfoIcon
                    iconName={checkboxProps.infoTooltipIconName}
                    tooltipText={checkboxProps.infoTooltipText}
                    variant={checkboxProps.infoTooltipVariant}
                  />
                  {isDeleted && (
                    <RegrelloTypography className="flex items-center" muted={true}>
                      {`(${Deleted})`}
                      <RegrelloTooltippedInfoIcon
                        iconName="info-outline"
                        tooltipText={DeleteFieldHelperText}
                        variant="popover"
                      />
                    </RegrelloTypography>
                  )}
                </div>
              ) : (
                label
              )
            }
          />
        )}
      </RegrelloFormFieldLayout>
    );
  }),
);
