import { clsx } from "@regrello/core-utils";

/**
 * Placeholder component for other components that might be loading initially.
 * Ideally you would want to override `height` and `width` to match component size.
 */
export function RegrelloSkeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  // (clewis): Subjectively needs to be a little darker than the default "soft" to be noticeable.
  return <div className={clsx("animate-pulse rounded bg-neutral-softHovered h-4 w-40", className)} {...props} />;
}
