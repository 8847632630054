import { clsx, EMPTY_ARRAY, EMPTY_STRING, isDefined, sortIgnoreCaseWithExactMatchFirst } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  FieldInstanceFields,
  FormFieldFields,
  FormVersionFields,
  useCreateFormAndVersionMutation,
  useFormsQueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloButton, RegrelloLinkV2, RegrelloTooltipV4, RegrelloTypography } from "@regrello/ui-core";
import {
  AddOptionToRegrello,
  CreateNewForm,
  CreateNewFormNotAllowed,
  ErrorCreatingForm,
  FormDisabledBecauseOfOccupiedFields,
  FormDisabledBecauseOfOccupiedFieldsList,
  MyForms,
  NoAccess,
  PublishedForms,
  QueryToastMessageError,
  ViewForm,
  ViewFormDisabledReason,
} from "@regrello/ui-strings";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDeepCompareEffect, useMount, useUnmount } from "react-use";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2ErrorOption } from "./_internal/selectOptions/RegrelloSelectV2ErrorOption";
import { RegrelloFormFieldSelectPropsV2, RegrelloFormFieldSelectV2 } from "./RegrelloFormFieldSelectV2";
import { formCreatedViaFormSelectCreateOptionAtom } from "../../../state/applicationState";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { AsyncLoaded } from "../../../utils/typescript/AsyncLoaded";
import { useUser } from "../../app/authentication/userContextUtils";
import { getRouteToFormBuilder } from "../../app/routes/routeCreatorUtils";
import {
  recursivelyAggregateSpectrumFieldNamesById,
  recursivelyPairFieldInstancesWithFormFields,
} from "../../views/modals/spectrumTaskDetailView/_internal/form/spectrumFormInstanceUtils";

export type FormSelectFormVersionFields = Pick<FormVersionFields, "description" | "id" | "name" | "uuid" | "form"> & {
  /**
   * Map from IDs to names of the spectrum fields that are used in the current version of the form.
   *
   * ___Note:___ This is not field-version ID because we will use this information to determine
   * which fields in `Shared Information` and `Request Information` need to be disabled. We will
   * also determine which form should be disabled if an ad-hoc field is selected.
   */
  spectrumFieldNamesById: Map<number, string>;
  createdBy: {
    id: number;
  };
  compositeFieldInstances: Array<{
    formField: FormFieldFields;
    fieldInstance: FieldInstanceFields;
  }>;
  isPublic?: boolean;
};

export interface RegrelloFormFieldSpectrumFormSelectProps
  extends RegrelloFormFieldBaseProps<FormSelectFormVersionFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<FormSelectFormVersionFields>,
      "inputRef" | "onChange" | "onClearClick" | "placeholder" | "size" | "value"
    > {
  /**
   * Whether to allow creating new forms.
   * @default false
   */
  allowCreateForms?: boolean;

  /**
   * Whether the specturm form is being loaded. If `true`, The preview button will be in the
   * loading state.
   */
  isSpectrumLoading?: boolean;

  /**
   * The list of spectrum field ids that are already occupied by other fields. Forms having spectrum
   * fields in this set will be disabled in the dropdown.
   */
  occupiedSpectrumFieldIds?: number[];
}

/**
 * This component renders a multiselect input field where the user can select from all fields in
 * the system. It is a wrapper around RegrelloFormFieldSelect. It loads the filed data from the
 * graphql query Fields.
 */
export const RegrelloFormFieldSpectrumFormSelect = React.memo<RegrelloFormFieldSpectrumFormSelectProps>(
  function RegrelloFormFieldSpectrumFormSelectFn({
    allowCreateForms = true,
    isSpectrumLoading = false,
    size,
    onChange,
    occupiedSpectrumFieldIds,
    ...multiselectProps
  }) {
    const { handleError } = useErrorHandler();
    const { currentUser } = useUser();
    const [getFormsLazyAsync, formsQueryResult] = useFormsQueryLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        handleError(error, { toastMessage: QueryToastMessageError });
      },
    });

    const asyncLoadedFormsQueryResult = useMemo(
      () => AsyncLoaded.fromGraphQlQueryResult(formsQueryResult),
      [formsQueryResult],
    );

    const [createFormAndVersionAsync] = useCreateFormAndVersionMutation({
      onError: (error) => {
        handleError(error, { toastMessage: ErrorCreatingForm });
      },
    });
    const [formCreatedViaFormSelectCreateOption, setFormCreatedViaFormSelectCreateOption] = useAtom(
      formCreatedViaFormSelectCreateOptionAtom,
    );

    const [loadedOptionsV2, setLoadedOptions] = useState<FormSelectFormVersionFields[]>(EMPTY_ARRAY);
    const isSelectedForm = useMemo(() => {
      return multiselectProps.value?.uuid !== "";
    }, [multiselectProps.value?.uuid]);

    const isSelectedFormAccessible =
      multiselectProps.value?.form?.createdBy?.id === currentUser.partyId || multiselectProps.value?.isPublic;

    useEffect(() => {
      if (AsyncLoaded.isLoading(asyncLoadedFormsQueryResult)) {
        return;
      }

      if (AsyncLoaded.isError(asyncLoadedFormsQueryResult) || AsyncLoaded.isNotLoaded(asyncLoadedFormsQueryResult)) {
        setLoadedOptions([]);
        return;
      }

      const options = asyncLoadedFormsQueryResult.value.forms.map((form) => ({
        ...form.latestFormVersion,
        spectrumFieldNamesById: recursivelyAggregateSpectrumFieldNamesById(form.latestFormVersion.defaultFormSection),
        compositeFieldInstances: recursivelyPairFieldInstancesWithFormFields(
          form.latestFormVersion.defaultFormSection,
          form.latestFormVersion.fieldInstances,
        ),
        isPublic: form.publishedFromFormID != null,
      }));
      // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
      // include the "Add" option in order for it to emit successfully via onChange.
      setLoadedOptions(sortOptions(options));
    }, [asyncLoadedFormsQueryResult]);

    const handleAutocompleteOpen = useCallback(async () => {
      // Reload the fields when the autocomplete opens.
      await getFormsLazyAsync();
    }, [getFormsLazyAsync]);

    const onAddForm = useCallback(async () => {
      const result = await createFormAndVersionAsync({
        variables: {
          input: {
            name: EMPTY_STRING, // (hchen): BE will give it a placeholder name
            description: EMPTY_STRING,
          },
        },
      });
      if (result.errors != null || result.data?.createFormAndVersion.form.uuid == null) {
        handleError(ErrorCreatingForm, { toastMessage: ErrorCreatingForm });
        return;
      }
      setFormCreatedViaFormSelectCreateOption({ uuid: result.data.createFormAndVersion.form.uuid });

      window.open(`${window.location.origin}${getRouteToFormBuilder(result.data.createFormAndVersion.form.uuid)}`);
    }, [createFormAndVersionAsync, handleError, setFormCreatedViaFormSelectCreateOption]);

    const refetchCallback = useCallback(() => {
      void formsQueryResult.refetch();
    }, [formsQueryResult]);

    useEffect(() => {
      if (formCreatedViaFormSelectCreateOption != null) {
        const formVersionToSelect = loadedOptionsV2.find(
          (formVersion) => formVersion.form.uuid === formCreatedViaFormSelectCreateOption.uuid,
        );
        if (formVersionToSelect != null) {
          onChange(formVersionToSelect, "select-option");
          setFormCreatedViaFormSelectCreateOption(undefined);
        }
      }
    }, [formCreatedViaFormSelectCreateOption, loadedOptionsV2, onChange, setFormCreatedViaFormSelectCreateOption]);

    // (hchen): event listener attachment and detachment must be done on mount and unmount instead
    // of a useEffect to avoid detaching prematurely.
    useMount(() => {
      window.addEventListener("focus", refetchCallback);
    });

    useUnmount(() => {
      window.removeEventListener("focus", refetchCallback);
    });

    useDeepCompareEffect(() => {
      if (multiselectProps.value?.form == null) {
        return;
      }
      const latestFormVersion = loadedOptionsV2.find(
        (formVersion) =>
          formVersion.form.uuid === multiselectProps.value?.form.uuid &&
          formVersion.uuid !== multiselectProps.value?.uuid,
      );
      if (latestFormVersion == null) {
        return;
      }
      onChange(latestFormVersion, "select-option");
    }, [loadedOptionsV2, multiselectProps.value, onChange]);

    useUnmount(() => {
      setFormCreatedViaFormSelectCreateOption(undefined);
    });

    const getOptionDisabled = useCallback(
      (option: FormSelectFormVersionFields) => {
        if (occupiedSpectrumFieldIds == null) {
          return false;
        }
        return occupiedSpectrumFieldIds.some((fieldId) => option.spectrumFieldNamesById.has(fieldId));
      },
      [occupiedSpectrumFieldIds],
    );

    const getOptionTooltip = useCallback(
      (option: FormSelectFormVersionFields) => {
        if (getOptionDisabled(option)) {
          if (occupiedSpectrumFieldIds == null) {
            return FormDisabledBecauseOfOccupiedFields;
          }
          // Show a list of fields that are preventing this form from being selected.
          const occupiedNames = (occupiedSpectrumFieldIds ?? EMPTY_ARRAY)
            .map((fieldId) => option.spectrumFieldNamesById.get(fieldId))
            .filter(isDefined)
            .sort();

          return (
            <div>
              <div>{FormDisabledBecauseOfOccupiedFieldsList}</div>
              <ul className="pt-2">
                {occupiedNames.map((fieldName, i) => (
                  <li key={i}>{fieldName}</li>
                ))}
              </ul>
            </div>
          );
        }
        return EMPTY_STRING;
      },
      [getOptionDisabled, occupiedSpectrumFieldIds],
    );

    const renderSelectedFormName = useCallback(() => {
      if (!isSelectedForm) {
        return EMPTY_STRING;
      }

      if (!isSelectedFormAccessible) {
        return (
          <RegrelloTypography className="flex">
            {getFormLabel(multiselectProps.value)}{" "}
            <RegrelloTypography className="ml-1" muted={true}>{`(${NoAccess})`}</RegrelloTypography>
          </RegrelloTypography>
        );
      }
      return getFormLabel(multiselectProps.value);
    }, [multiselectProps.value, isSelectedForm, isSelectedFormAccessible]);

    return (
      <>
        <RegrelloFormFieldSelectV2
          extraEndOptions={[
            AsyncLoaded.isError(asyncLoadedFormsQueryResult) ? (
              <RegrelloSelectV2ErrorOption key="option-error" />
            ) : (
              <RegrelloSelectV2AddOption
                key="option-add"
                allowCreateOptions={allowCreateForms}
                iconName="add"
                message={(inputValue) => {
                  if (!allowCreateForms) {
                    return CreateNewFormNotAllowed;
                  }
                  return inputValue !== EMPTY_STRING
                    ? AddOptionToRegrello({ name: <strong>{inputValue}</strong> })
                    : CreateNewForm;
                }}
                onSelect={onAddForm}
              />
            ),
          ]}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getFormLabel}
          getOptionTooltip={getOptionTooltip}
          getOptionValue={getFormValue}
          groupBy={(option) => (option.isPublic ? PublishedForms : MyForms)}
          isLoading={AsyncLoaded.isLoading(asyncLoadedFormsQueryResult)}
          onChange={onChange}
          onOpen={handleAutocompleteOpen}
          options={loadedOptionsV2}
          renderSelectedValue={renderSelectedFormName}
          size={size}
          {...multiselectProps}
        />
        {multiselectProps.value?.form?.uuid != null && multiselectProps.value.form.uuid.length > 0 && (
          <RegrelloTooltipV4 content={isSelectedFormAccessible ? EMPTY_STRING : ViewFormDisabledReason}>
            {/* (hchen): This div is necessary for showing the tooltip when disabled. */}
            <div>
              <RegrelloLinkV2
                className={clsx({
                  "pointer-events-none": !isSelectedFormAccessible,
                })}
                target="_blank"
                to={getRouteToFormBuilder(
                  multiselectProps.value.form.uuid,
                  multiselectProps.value.form.publishedFromFormID != null,
                )}
                unstyled={true}
              >
                <RegrelloButton
                  className="h-9"
                  dataTestId={DataTestIds.ADD_ACTION_ITEM_DIALOG_VIEW_FORM_BUTTON}
                  disabled={!isSelectedFormAccessible}
                  endIcon="launch"
                  intent="primary"
                  loading={isSpectrumLoading}
                  size="small"
                  variant="ghost"
                >
                  {ViewForm}
                </RegrelloButton>
              </RegrelloLinkV2>
            </div>
          </RegrelloTooltipV4>
        )}
      </>
    );
  },
);

function getFormLabel(form: FormSelectFormVersionFields | null): string {
  return form?.name ?? EMPTY_STRING;
}

function getFormValue(form: FormSelectFormVersionFields): string {
  return `${form.name}|${form.isPublic ? PublishedForms : MyForms}`;
}

function sortOptions(options: FormSelectFormVersionFields[]): FormSelectFormVersionFields[] {
  // (dosipiuk): Merge `isPublic` with `name` to achieve proper grouping
  return sortIgnoreCaseWithExactMatchFirst([...options], (option) => `${!option.isPublic}${option.name}`, EMPTY_STRING);
}
