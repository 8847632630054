import { ApolloCache, FetchResult } from "@apollo/client";
import { arrayRemoveAtIndex, EMPTY_ARRAY } from "@regrello/core-utils";
import {
  AccessRoleName,
  ConditionalExpressionGroupFields,
  CreateTagTypeMutation,
  CurrentUserQuery,
  CurrentUserQueryDocument,
  CurrentUserQueryVariables,
  DeleteUserMutation,
  FieldInstanceFields,
  HasCurrentUserAcknowledgedPrivacyPolicyQuery,
  HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument,
  HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables,
  NameTemplateFields,
  OutOfOfficeEventFields,
  PartyBaseFields,
  TagFields,
  TagTypesQuery,
  TagTypesQueryDocument,
  TeamFields,
  TeamsQuery,
  TeamsQueryDocument,
  TenantFields,
  TenantsQuery,
  TenantsQueryDocument,
  TenantsQueryVariables,
  UserFields,
  UserQueryDocument,
  UsersQuery,
  WorkflowCollaborationFields,
  WorkflowFields,
  WorkflowQuery,
  WorkflowQueryDocument,
  WorkflowQueryVariables,
  WorkflowStageFields,
  WorkflowStageTemplateFields,
  WorkflowTemplateCollaborationFields,
  WorkflowTemplateFields,
  WorkflowTemplatesQuery,
  WorkflowTemplatesQueryDocument,
  WorkflowTemplatesQueryVariables,
} from "@regrello/graphql-api";

// Out Of Office
// ================

export function updateCacheOnCurrentUserOutOfOfficeCleared(cache: ApolloCache<unknown>) {
  updateCacheOnCurrentUserOutOfOffice(cache, null);
}

export function updateCacheOnCurrentUserOutOfOfficeSet(
  cache: ApolloCache<unknown>,
  currentOutOfOfficeEvent: OutOfOfficeEventFields,
) {
  updateCacheOnCurrentUserOutOfOffice(cache, currentOutOfOfficeEvent);
}

function updateCacheOnCurrentUserOutOfOffice(
  cache: ApolloCache<unknown>,
  currentOutOfOfficeEvent: OutOfOfficeEventFields | null,
) {
  const cachedValue = cache.readQuery<CurrentUserQuery>({
    query: CurrentUserQueryDocument,
  });

  if (cachedValue?.currentUser == null) {
    return;
  }

  cache.writeQuery<CurrentUserQuery, CurrentUserQueryVariables>({
    query: CurrentUserQueryDocument,
    data: {
      currentUser: { ...cachedValue.currentUser, currentOutOfOfficeEvent },
    },
  });
}

// Workflow
// ========

export function updateCacheOnWorkflowNameUpdated(cache: ApolloCache<unknown>, workflowId: number, newName: string) {
  maybeUpdateWorkflowInCache(cache, workflowId, (existingWorkflow) => ({
    ...existingWorkflow,
    name: newName,
  }));
}

export function updateCacheOnWorkflowDescriptionUpdated(
  cache: ApolloCache<unknown>,
  workflowId: number,
  newDescription: string,
) {
  maybeUpdateWorkflowInCache(cache, workflowId, (existingWorkflow) => ({
    ...existingWorkflow,
    description: newDescription,
  }));
}

export function updateCacheOnWorkflowTagsUpdated(
  cache: ApolloCache<unknown>,
  workflowId: number,
  nextTags: TagFields[],
) {
  maybeUpdateWorkflowInCache(cache, workflowId, (existingWorkflow) => ({
    ...existingWorkflow,
    tags: nextTags,
  }));
}

export function updateCacheOnWorkflowFieldInstancesUpdated(
  cache: ApolloCache<unknown>,
  workflowId: number,
  nextFieldInstances: FieldInstanceFields[],
) {
  maybeUpdateWorkflowInCache(cache, workflowId, (existingWorkflow) => ({
    ...existingWorkflow,
    fieldInstances: nextFieldInstances,
  }));
}

// Workflow: Stages
// ================

export function updateCacheOnTenantAdded(cache: ApolloCache<unknown>, newTenantFromResponse: TenantFields) {
  maybeUpdateTenantsInCache(cache, (existingTenants) => [...existingTenants, newTenantFromResponse]);
}

export function updateCacheOnTenantDisplayNameUpdated(
  cache: ApolloCache<unknown>,
  newTenantFromResponse: TenantFields,
) {
  maybeUpdateTenantsInCache(cache, (existingTenants) =>
    existingTenants.map((tenant) => (tenant.id === newTenantFromResponse.id ? newTenantFromResponse : tenant)),
  );
}

export function updateCacheOnWorkflowStageAdded(
  cache: ApolloCache<unknown>,
  newStageFromResponse: WorkflowStageFields,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) => [...existingStages, newStageFromResponse]);
}

export function updateCacheOnWorkflowStageDeleted(cache: ApolloCache<unknown>, stageId: number, workflowId: number) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.filter((stage) => stage.id !== stageId),
  );
}

export function updateCacheOnWorkflowStageNameEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newName: string,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) => (stage.id !== stageId ? stage : { ...stage, name: newName })),
  );
}

export function updateCacheOnWorkflowStageDescriptionEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newDescription: string,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) => (stage.id !== stageId ? stage : { ...stage, description: newDescription })),
  );
}

export function updateCacheOnWorkflowStageStartAfterWorkflowStageIdsEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newStartAfterWorkflowStages: WorkflowStageFields[],
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) =>
      stage.id !== stageId ? stage : { ...stage, startAfterWorkflowStages: newStartAfterWorkflowStages },
    ),
  );
}

export function updateCacheOnWorkflowStageStartAtEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newStartAt: string | null,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) => (stage.id !== stageId ? stage : { ...stage, startAt: newStartAt })),
  );
}

export function updateCacheOnWorkflowStageStartingConditionsEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newStartingConditions: ConditionalExpressionGroupFields | null,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) =>
      stage.id !== stageId ? stage : { ...stage, startingConditions: newStartingConditions },
    ),
  );
}

export function updateCacheOnWorkflowStageStartOnWorkflowStartEdited(
  cache: ApolloCache<unknown>,
  stageId: number,
  newStartOnWorkflowStart: boolean,
  workflowId: number,
) {
  maybeUpdateWorkflowStagesInCache(cache, workflowId, (existingStages) =>
    existingStages.map((stage) =>
      stage.id !== stageId ? stage : { ...stage, startOnWorkflowStart: newStartOnWorkflowStart },
    ),
  );
}

export function updateCacheOnWorkflowOwnerEdited(
  cache: ApolloCache<unknown>,
  newCollaborations: WorkflowCollaborationFields[] | null,
  workflowId: number,
) {
  const getNewWorkflow = (existingWorkflow: WorkflowFields) => {
    if (newCollaborations == null) {
      return existingWorkflow;
    }

    return {
      ...existingWorkflow,
      collaborations: newCollaborations,
    };
  };
  maybeUpdateWorkflowInCache(cache, workflowId, getNewWorkflow);
}

export function updateCacheOnWorkflowCollaborationEdited(
  cache: ApolloCache<unknown>,
  newCollaboration: WorkflowCollaborationFields | null,
  workflowId: number,
) {
  const getNewWorkflow = (existingWorkflow: WorkflowFields) => {
    if (newCollaboration == null) {
      return existingWorkflow;
    }

    const indexToEdit = findPartyCollaborationIndexOnCollaborationUpdate(
      existingWorkflow.collaborations,
      newCollaboration.party.id,
    );
    if (indexToEdit < 0) {
      return existingWorkflow;
    }
    const newCollaborations = [...existingWorkflow.collaborations];
    newCollaborations[indexToEdit] = newCollaboration;
    return {
      ...existingWorkflow,
      collaborations: newCollaborations,
    };
  };
  maybeUpdateWorkflowInCache(cache, workflowId, getNewWorkflow);
}

export function updateCacheOnWorkflowCollaborationAdded(
  cache: ApolloCache<unknown>,
  newCollaboration: WorkflowCollaborationFields | null,
  workflowId: number,
) {
  maybeUpdateWorkflowInCache(cache, workflowId, (existingWorkflow: WorkflowFields) => {
    if (newCollaboration == null) {
      return existingWorkflow;
    }

    return {
      ...existingWorkflow,
      collaborations: [...existingWorkflow.collaborations, newCollaboration],
    };
  });
}

export function updateCacheOnWorkflowCollaborationDeleted(
  cache: ApolloCache<unknown>,
  partyId: number,
  workflowId: number,
) {
  const getNewWorkflow = (existingWorkflow: WorkflowFields) => {
    const indexToDelete = findPartyCollaborationIndexOnCollaborationUpdate(existingWorkflow.collaborations, partyId);
    if (indexToDelete < 0) {
      return existingWorkflow;
    }
    const newCollaborations = arrayRemoveAtIndex(existingWorkflow.collaborations, indexToDelete);
    return {
      ...existingWorkflow,
      collaborations: newCollaborations,
    };
  };
  maybeUpdateWorkflowInCache(cache, workflowId, getNewWorkflow);
}

// Workflow Template
// =================

export function updateCacheOnWorkflowTemplateUpdated(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  newWorkflowTemplate: WorkflowTemplateFields,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, () => newWorkflowTemplate);
}

export function updateCacheOnWorkflowTemplateNameUpdated(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  newName: string,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflowTemplate) => ({
    ...existingWorkflowTemplate,
    name: newName,
  }));
}

export function updateCacheOnWorkflowTemplateDescriptionUpdated(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  newDescription: string,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflowTemplate) => ({
    ...existingWorkflowTemplate,
    description: newDescription,
  }));
}

export function updateCacheOnWorkflowTemplateTagsUpdated(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  nextTags: TagFields[],
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflowTemplate) => ({
    ...existingWorkflowTemplate,
    tags: nextTags,
  }));
}

export function updateCacheOnWorkflowTemplateNameTemplateUpdated(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  nextNameTemplate: NameTemplateFields | undefined,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflowTemplate) => ({
    ...existingWorkflowTemplate,
    nameTemplate: nextNameTemplate,
  }));
}

// Workflow Template: Stages
// =========================

export function updateCacheOnWorkflowTemplateStageAdded(
  cache: ApolloCache<unknown>,
  newStageTemplateFromResponse: WorkflowStageTemplateFields,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) => [
    ...existingStageTemplates,
    newStageTemplateFromResponse,
  ]);
}

export function updateCacheOnWorkflowTemplateStageDeleted(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.filter((stageTemplate) => stageTemplate.id !== stageTemplateId),
  );
}

export function updateCacheOnWorkflowTemplateStageNameEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  newName: string,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId ? stageTemplate : { ...stageTemplate, name: newName },
    ),
  );
}

export function updateCacheOnWorkflowTemplateStageDescriptionEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  newDescription: string,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId ? stageTemplate : { ...stageTemplate, description: newDescription },
    ),
  );
}

export function updateCacheOnWorkflowTemplateStageStartAfterWorkflowStageTemplateIdsEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  workflowTemplateId: number,
  newStartAfterWorkflowStageTemplates: WorkflowStageTemplateFields[],
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId
        ? stageTemplate
        : { ...stageTemplate, startAfterWorkflowStageTemplates: newStartAfterWorkflowStageTemplates },
    ),
  );
}

export function updateCacheOnWorkflowTemplateStageStartAtEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  workflowTemplateId: number,
  newStartAt: string | null,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId ? stageTemplate : { ...stageTemplate, startAt: newStartAt },
    ),
  );
}

export function updateCacheOnWorkflowTemplateStageStartingConditionsEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  workflowTemplateId: number,
  newStartingConditions: ConditionalExpressionGroupFields | null,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId
        ? stageTemplate
        : { ...stageTemplate, startingConditions: newStartingConditions },
    ),
  );
}

export function updateCacheOnWorkflowTemplateStageStartOnWorkflowStartEdited(
  cache: ApolloCache<unknown>,
  stageTemplateId: number,
  workflowTemplateId: number,
  newStartOnWorkflowStart: boolean,
) {
  maybeUpdateWorkflowStageTemplatesInCache(cache, workflowTemplateId, (existingStageTemplates) =>
    existingStageTemplates.map((stageTemplate) =>
      stageTemplate.id !== stageTemplateId
        ? stageTemplate
        : { ...stageTemplate, startOnWorkflowStart: newStartOnWorkflowStart },
    ),
  );
}

export function updateCacheOnWorkflowTemplateCollaborationEdited(
  cache: ApolloCache<unknown>,
  newCollaboration: WorkflowTemplateCollaborationFields | null,
  workflowTemplateId: number,
) {
  const getNewWorkflowTemplate = (existingWorkflow: WorkflowTemplateFields) => {
    if (newCollaboration == null) {
      return existingWorkflow;
    }

    const indexToEdit = existingWorkflow.collaborations.findIndex(
      (collaboration) => collaboration.party.id === newCollaboration.party.id,
    );
    if (indexToEdit < 0) {
      return existingWorkflow;
    }
    const newCollaborations = [...existingWorkflow.collaborations];
    newCollaborations[indexToEdit] = newCollaboration;
    return {
      ...existingWorkflow,
      collaborations: newCollaborations,
    };
  };
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, getNewWorkflowTemplate);
}

export function updateCacheOnWorkflowTemplateCollaborationAdded(
  cache: ApolloCache<unknown>,
  newCollaboration: WorkflowTemplateCollaborationFields | null,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflow: WorkflowTemplateFields) => {
    if (newCollaboration == null) {
      return existingWorkflow;
    }

    return {
      ...existingWorkflow,
      collaborations: [...existingWorkflow.collaborations, newCollaboration],
    };
  });
}

export function updateCacheOnWorkflowTemplateCollaborationDeleted(
  cache: ApolloCache<unknown>,
  partyId: number,
  workflowTemplateId: number,
) {
  const getNewWorkflowTemplate = (existingWorkflow: WorkflowTemplateFields) => {
    const indexToDelete = existingWorkflow.collaborations.findIndex(
      (collaboration) => collaboration.party.id === partyId,
    );
    if (indexToDelete < 0) {
      return existingWorkflow;
    }
    const newCollaborations = arrayRemoveAtIndex(existingWorkflow.collaborations, indexToDelete);
    return {
      ...existingWorkflow,
      collaborations: newCollaborations,
    };
  };
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, getNewWorkflowTemplate);
}

export function updateCacheOnWorkflowTemplateWorkflowOwnerEdited(
  cache: ApolloCache<unknown>,
  workflowOwnerParty: PartyBaseFields | null,
  workflowTemplateId: number,
) {
  maybeUpdateWorkflowTemplateInCache(cache, workflowTemplateId, (existingWorkflow: WorkflowTemplateFields) => {
    return {
      ...existingWorkflow,
      workflowOwnerParty: workflowOwnerParty,
    };
  });
}

// TagType

export function updateNewTagTypesInCache(cache: ApolloCache<unknown>, data: CreateTagTypeMutation | undefined) {
  const query = TagTypesQueryDocument;
  const newTagType = data?.createTagType?.tagType;
  const cachedValue = cache.readQuery<TagTypesQuery>({ query });
  if (cachedValue == null) {
    return;
  } else if (newTagType != null) {
    cache.writeQuery({
      query,
      data: {
        tagTypes: [...cachedValue.tagTypes, newTagType],
      },
    });
  }
}

// Team

export function updateTeamInCache(cache: ApolloCache<unknown>, newOrUpdatedTeam: TeamFields) {
  const query = TeamsQueryDocument;
  const cachedValue = cache.readQuery<TeamsQuery>({ query });

  // Remove existing team with the same ID if it exists.
  const teamsWithoutUpdatedTeam = (cachedValue?.teams ?? EMPTY_ARRAY).filter((team) => team.id !== newOrUpdatedTeam.id);

  // (clewis): This code is intentionally proceeding even if there is no cached value yet! This is
  // necessary to ensure that other components that watch this query can be notified when this
  // cached query result changes. See: https://app.regrello.com/workflow/2422
  cache.writeQuery<TeamsQuery>({ query, data: { teams: [...teamsWithoutUpdatedTeam, newOrUpdatedTeam] } });
}

// User

export function updateCacheOnUserCreated(cache: ApolloCache<unknown>, newUser: UserFields) {
  updateCacheOnUsersCreated(cache, [newUser]);
}

export function updateCacheOnUsersCreated(cache: ApolloCache<unknown>, newUsers: UserFields[]) {
  const query = UserQueryDocument;

  const cachedValue = cache.readQuery<UsersQuery>({
    query,
  });

  // (clewis): This code is intentionally proceeding even if there is no cached value yet! This is
  // necessary to ensure that other components that watch this query can be notified when this
  // cached query result changes. See: https://app.regrello.com/workflow/2422
  cache.writeQuery<UsersQuery>({
    query,
    data: {
      users: [...(cachedValue?.users ?? EMPTY_ARRAY), ...newUsers],
    },
  });
}

export function evictUserFromCacheIfDeleteMutationSuccessful(
  cache: ApolloCache<unknown>,
  mutationResult: FetchResult<DeleteUserMutation, Record<string, unknown>, Record<string, unknown>>,
  userId: number,
) {
  if (mutationResult.data?.deleteUser?.success) {
    const typename: UserFields["__typename"] = "User";
    cache.evict({
      id: `${typename}:${userId}`,
      broadcast: true,
    });
  }
}

export function updateCacheOnAcknowledgePrivacyPolicy(cache: ApolloCache<unknown>) {
  const cachedValue = cache.readQuery<HasCurrentUserAcknowledgedPrivacyPolicyQuery>({
    query: HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument,
  });

  if (cachedValue == null) {
    return;
  }

  cache.writeQuery<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>(
    {
      query: HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument,
      data: {
        hasCurrentUserAcknowledgedPrivacyPolicy: true,
      },
    },
  );
}

// Private
// =======

function maybeUpdateTenantsInCache(
  cache: ApolloCache<unknown>,
  getNewTenants: (existingTenants: TenantFields[]) => TenantFields[],
) {
  const cachedValue = cache.readQuery<TenantsQuery>({
    query: TenantsQueryDocument,
  });

  if (cachedValue == null) {
    return;
  }

  cache.writeQuery<TenantsQuery, TenantsQueryVariables>({
    query: TenantsQueryDocument,
    data: {
      tenants: getNewTenants(cachedValue.tenants),
    },
  });
}

function maybeUpdateWorkflowInCache(
  cache: ApolloCache<unknown>,
  workflowId: number,
  getNewWorkflow: (existingWorkflow: WorkflowFields) => WorkflowFields,
) {
  const cachedValue = cache.readQuery<WorkflowQuery>({
    query: WorkflowQueryDocument,
    variables: {
      id: workflowId,
    },
  });

  if (cachedValue == null) {
    return;
  }

  cache.writeQuery<WorkflowQuery, WorkflowQueryVariables>({
    query: WorkflowQueryDocument,
    data: {
      workflows: cachedValue.workflows.map((workflow) => {
        return workflow.id !== workflowId
          ? workflow
          : {
              ...getNewWorkflow(workflow),
            };
      }),
    },
  });
}

function maybeUpdateWorkflowTemplateInCache(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  getNewWorkflowTemplate: (existingWorkflowTemplate: WorkflowTemplateFields) => WorkflowTemplateFields,
) {
  const cachedValue = cache.readQuery<WorkflowTemplatesQuery>({
    query: WorkflowTemplatesQueryDocument,
    variables: {
      id: workflowTemplateId,
    },
  });

  if (cachedValue == null) {
    return;
  }

  cache.writeQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>({
    query: WorkflowTemplatesQueryDocument,
    data: {
      workflowTemplates: cachedValue.workflowTemplates.map((workflowTemplate) => {
        return workflowTemplate.id !== workflowTemplateId
          ? workflowTemplate
          : {
              ...getNewWorkflowTemplate(workflowTemplate),
            };
      }),
    },
  });
}

function maybeUpdateWorkflowStagesInCache(
  cache: ApolloCache<unknown>,
  workflowId: number,
  getNewStages: (existingStages: WorkflowStageFields[]) => WorkflowStageFields[],
) {
  const cachedValue = cache.readQuery<WorkflowQuery>({
    query: WorkflowQueryDocument,
    variables: {
      id: workflowId,
    },
    // HACKHACK (clewis): For some reason I haven't diagnosed yet, this cached value returns null
    // because there is some data missing from the cached value. The stages are present though and
    // that's all we need to modify here, so we can safely set "returnPartialData" to true to ensure
    // that the cached value is returned.
    returnPartialData: true,
  });

  if (cachedValue == null) {
    return;
  }

  const incomingData = structuredClone(cachedValue);
  cache.writeQuery<WorkflowQuery, WorkflowQueryVariables>({
    query: WorkflowQueryDocument,
    variables: {
      id: workflowId,
    },
    data: {
      workflows: incomingData.workflows.map((workflow) => {
        return workflow.id !== workflowId
          ? workflow
          : {
              ...workflow,
              stages: getNewStages(workflow.stages),
            };
      }),
    },
  });
}

function maybeUpdateWorkflowStageTemplatesInCache(
  cache: ApolloCache<unknown>,
  workflowTemplateId: number,
  getNewStageTemplates: (existingStages: WorkflowStageTemplateFields[]) => WorkflowStageTemplateFields[],
) {
  const query = WorkflowTemplatesQueryDocument;

  const cachedValue = cache.readQuery<WorkflowTemplatesQuery>({
    query,
    variables: {
      id: workflowTemplateId,
    },
  });

  if (cachedValue == null) {
    return;
  }

  cache.writeQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>({
    query,
    data: {
      workflowTemplates: cachedValue.workflowTemplates.map((workflowTemplate) => {
        return workflowTemplate.id !== workflowTemplateId
          ? workflowTemplate
          : {
              ...workflowTemplate,
              stageTemplates: getNewStageTemplates(workflowTemplate.stageTemplates),
            };
      }),
    },
  });
}

function findPartyCollaborationIndexOnCollaborationUpdate(
  collaborations: WorkflowCollaborationFields[],
  partyId: number,
) {
  return collaborations.findIndex(
    (collaboration) =>
      // (akager) The party could have an owner collaboration in addition to a regular collaboration.
      // The only directly modifiable collaboration is the regular one so we ignore the owner collaboration.
      collaboration.party.id === partyId && collaboration.accessRole.name !== AccessRoleName.WORKFLOW_OWNER,
  );
}
