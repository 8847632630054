import { EMPTY_STRING, getEmailDomain } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloFullColorLogoMarkIcon,
  RegrelloIconStyler,
  RegrelloLinkV2,
  RegrelloTypography,
} from "@regrello/ui-core";
import {
  ContactSupportAt,
  CopyrightTitleCased,
  Email,
  ForgotYourPassword,
  LetsGetStarted,
  PrivacyPolicyTitleCased,
  ReceivedAnInvite,
  ReceivedAnInviteInstruction,
  ResendInvite,
  ReturnToLogin,
  ReturnToSupplierLogin,
  VerificationRequiredInstructionAuth0,
  Verify,
  VerifyIdentityInstruction,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { REGRELLO_PRIVACY_POLICY_URL, ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

export interface UnauthenticatedRequestForEmailPageFormFields {
  email: string;
}

const defaultValues: UnauthenticatedRequestForEmailPageFormFields = {
  email: EMPTY_STRING,
};

const REGRELLO_SUPPORT_EMAIL = "help@regrello.com";

export interface UnauthenticatedRequestForEmailPageProps {
  /**
   * Whether the user is requesting for a new invitatation or a password reset email. Clicking on
   * submit calls different APIs base on the mode. Different instructions are rendered based on the
   * selection.
   */
  mode: "lost_invite" | "password_reset";
}

export const UnauthenticatedRequestForEmailPage = React.memo(function UnauthenticatedRequestForEmailPageFn({
  mode,
}: UnauthenticatedRequestForEmailPageProps) {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierModeEnabled = supplierQueryValue != null;
  const { resendInvite, requestResetPassword, loading, authError: resendInviteError } = useRegrelloAuthV2();
  const { getQueryParam } = useRegrelloHistory();
  const email = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;

  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // (clewis): Our own defaultValues prop requires a default value for each field, whereas this
    // hook expects a partial. A full object is simply a superset of a partial, but the types are
    // still complaining that we're not providing a partial-typed object. Hence, we cast as any.
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: defaultValues as any,
  });

  const [shouldUseSso, setShouldUseSso] = useState<boolean>(false);

  useEffect(() => {
    const domain = getEmailDomain(email);
    const updateShouldUseSso = async () => {
      const result = await RegrelloRestApiService.isSso(domain);
      setShouldUseSso(result.status === 200 && result.json.isSso);
    };

    void updateShouldUseSso();
  }, [email]);

  const onResendInvite = useCallback(
    async (data: UnauthenticatedRequestForEmailPageFormFields) => {
      await resendInvite(data.email, isSupplierModeEnabled);
    },
    [resendInvite, isSupplierModeEnabled],
  );

  const onSendPasswordResetEmail = useCallback(
    async (data: UnauthenticatedRequestForEmailPageFormFields) => {
      await requestResetPassword(data.email);
    },
    [requestResetPassword],
  );

  const submit = useCallback(async () => {
    // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
    await form.handleSubmit(mode === "lost_invite" ? onResendInvite : onSendPasswordResetEmail)();
  }, [form, mode, onResendInvite, onSendPasswordResetEmail]);

  const signInLinkHref = {
    pathname: RoutePaths.LOGIN,
    search: isSupplierModeEnabled ? `${RouteQueryStringKeys.SUPPLIER}=1` : undefined,
  };

  return (
    <div
      className="flex justify-center w-full h-full"
      data-testid={DataTestIds.UNAUTHENTICATED_EMAIL_REQUEST_PAGE_ROOT}
    >
      <div className="flex flex-col w-150 px-12 pb-4">
        <div className="flex flex-col flex-auto justify-center">
          <div className="mb-13">
            <RegrelloIconStyler size="x-large">
              <RegrelloFullColorLogoMarkIcon />
            </RegrelloIconStyler>
          </div>
          <RegrelloTypography className="mb-10" variant="h1">
            {mode === "lost_invite" && isSupplierModeEnabled
              ? LetsGetStarted
              : mode === "lost_invite"
                ? ReceivedAnInvite
                : ForgotYourPassword}
          </RegrelloTypography>
          <RegrelloTypography className="mb-10" variant="body" weight="semi-bold">
            {mode === "lost_invite" && isSupplierModeEnabled
              ? VerifyIdentityInstruction
              : mode === "lost_invite"
                ? ReceivedAnInviteInstruction
                : VerificationRequiredInstructionAuth0}
          </RegrelloTypography>

          <div className="flex mb-10">
            <div className="w-1/2 pr-2">
              <RegrelloControlledFormFieldText
                controllerProps={{
                  control: form.control,
                  name: "email",
                  rules: {
                    ...ValidationRules.VALID_EMAIL,
                    ...ValidationRules.REQUIRED.required,
                  },
                }}
                dataTestId={DataTestIds.RESEND_INVITE_INPUT}
                isDefaultMarginsOmitted={true}
                placeholder={Email}
              />
              {resendInviteError != null && (
                <RegrelloTypography className="text-danger-textMuted">{resendInviteError}</RegrelloTypography>
              )}
            </div>

            <RegrelloButton
              className="h-min"
              dataTestId={DataTestIds.RESEND_INVITE_SUBMIT_BUTTON}
              disabled={shouldUseSso}
              intent="primary"
              loading={loading}
              onClick={submit}
            >
              {mode === "lost_invite" ? ResendInvite : Verify}
            </RegrelloButton>
          </div>

          <RegrelloLinkV2 className="mb-10" to={signInLinkHref}>
            {isSupplierModeEnabled ? ReturnToSupplierLogin : ReturnToLogin}
          </RegrelloLinkV2>

          <RegrelloTypography>
            {ContactSupportAt}
            <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
              {REGRELLO_SUPPORT_EMAIL}
            </RegrelloLinkV2>
          </RegrelloTypography>
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          {CopyrightTitleCased}
        </RegrelloTypography>
      </div>
    </div>
  );
});
