import { comparatorIgnoreCase } from "@regrello/core-utils";

export type FlatPartyUserType = "user" | "team" | "fieldInstance" | "baseUser" | "userWithRoles" | "role";
export type FlatPartyFieldInstanceSubtype = "role";

export interface FlatParty {
  id: number;
  deletedAt?: string;
  email: string;
  /**
   * A subtype of `FlatPartyUserType`, used for distinguishing between different types of field
   * instances when needed.
   */
  fieldInstanceSubtype?: FlatPartyFieldInstanceSubtype;
  fullName: string;
  isInactive: boolean;
  isInternal: boolean;
  isMuted: boolean;
  type: FlatPartyUserType;
  userType: "DEFAULT" | "SERVICE_ACCOUNT" | "SCIM_SERVICE_ACCOUNT";
}

function getPartyFullName(party: FlatParty): string {
  return party.fullName;
}

export function flatPartyListSortComparator(partyA: FlatParty, partyB: FlatParty) {
  const partyTypeSortableValue = {
    fieldInstance: 10,
    role: 20,
    team: 30,
    user: 40,
    baseUser: 40,
    userWithRoles: 40,
  };
  if (partyTypeSortableValue[partyA.type] === partyTypeSortableValue[partyB.type]) {
    // (clewis): Ignore case since it doesn't matter.
    return comparatorIgnoreCase(partyA, partyB, getPartyFullName);
  }
  return partyTypeSortableValue[partyA.type] - partyTypeSortableValue[partyB.type];
}
