/** An enumeration of all feature flag keys currently defined in Launch Darkly. */
// (clewis): If you delete a feature flag in Launch Darkly, please remember to delete it here!
export enum FeatureFlagKey {
  // Please keep alphabetized:
  AI_MONITOR_2024_05 = "ai-monitor-2024-05",
  APP_GENERATION_2023_12 = "app-generation-2023-12",
  AUTO_ADJUSTING_DUE_DATE_2023_12 = "auto-adjusting-due-date-2023-12",
  AUTOMATED_TASK_IMPROVEMENTS_2024_04 = "automated-task-improvements-2024-04",
  BLUEPRINT_TRANSFER_20204_02 = "blueprint-transfer-2024-02",
  DISABLE_OLD_APPROVALS_2023_09 = "disable-old-approvals-2023-09",
  FORM_GEN_2024_05 = "form-gen-2024-05",
  FRUIT_EMAIL_WEBFORM_REDESIGN_2024_04 = "fruit-email-redesign-2024-04",
  INTERNAL_DEV_ONLY_PAGES_2024_04 = "internal-dev-only-pages-2024-04",
  MONOLITHIC_SUBMIT_ACTION_ITEM_2023_12 = "monolithic-submit-action-item-2023-12",
  MULTIPLE_WORKSPACES_2023_12 = "multiple-workspaces-2023-12",
  NOTIFICATIONS_MILESTONE2_2023_08 = "notifications-milestone-2-2023-08",
  PERMANENT_DESIGN_DOCS_LINK = "permanent-design-docs-link",
  PERMISSIONS_V2_2024_01 = "permissions-v2-2024-01",
  SAVE_FORM_AS_PDF_2024_04 = "save-form-as-pdf-2024-04",
  SCIM_PERMISSIONS_2024_04 = "scim-permissions-2024-04",
  SCIM_M2_2023_09_DESCOPED = "scim-m2-2023-10-descoped",
  SESSION_MANAGEMENT_2023_04 = "session-management-2023-04",
  SHAREPOINT_FILE_NAMING_CONVENTION_2024_04 = "sharepoint-file-naming-convention-2024-04",
  SPECTRUM_2023_08 = "spectrum-2023-08",
  SPECTRUM_BUTTON_TO_UPDATE_BLUEPRINT_FIELD_VERSIONS_2024_05 = "spectrum-button-to-update-blueprint-field-versions-2024-05",
  SPECTRUM_TASK_INFORMATION_ORDER_TOGGLE_2024_03 = "spectrum-task-information-order-toggle-2024-03",
  STAGE_0_2024_03 = "stage-0-2024-03",
  TEAM_ADMIN_PERMISSIONS_2024_06 = "team-admin-perms-2024-06",
  TYPESENSE_SEARCH_BY_PUBSUB = "typesense-search-by-pubsub-2024-02",
  TYPESENSE_SEARCH_ENHANCEMENTS_2024_04 = "typesense-search-enhancements-2024-04",
  UPDATED_WORKFLOW_SUMMARY_2023_06 = "updated-workflow-summary-2023-06",
  WORKFLOW_CREATOR_NO_REVIEW_02_2024 = "workflow-creator-no-review-02-2024",
  WORKFLOW_SUMMARY_2023_03 = "workflow-summary-2023-03",
  WORKSPACE_CREATION_2022_05 = "workspace-creation-2022-05",
  WORKLFOW_OWNER_M2_2024_04 = "workflow-owner-m2-2024-04",
}
